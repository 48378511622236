import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

const AuthRedirect = ({ children }) => {
  const { user } = useContext(AuthContext); // Assuming user is null if not logged in

  if (user) {
    // User is signed in, redirect to home page
    return <Navigate to="/" />;
  }

  // User is not signed in, render the children components (e.g., sign-in or sign-up form)
  return children;
};

export default AuthRedirect;
