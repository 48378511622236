import React, { useState, useEffect, useRef } from 'react';
import { Popup, Icon } from 'semantic-ui-react';
import './PreviewDashInfoCard.css';
import { set } from 'date-fns';

interface Props {
    title: string;
    content: number
}

const PreviewDashInfoCard: React.FC<Props> = ({ title, content }) => {

    const titleRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const [displayTitle, setDisplayTitle] = useState<string>(title);
    const [popupContent, setPopupContent] = useState<string>('');

    useEffect(() => {

        switch (title.toLowerCase()) {
            case "expected_dofs":
                titleRef.current?.style.setProperty('font-size', '2em', 'important');
                contentRef.current?.style.setProperty('font-size', '3.5em', 'important');
                setDisplayTitle("Expected DOFS");
                setPopupContent("The number of degrees of freedom (DOFS) expected to be observed in the inversion. This is a measure of the number of independent data points that can be used to constrain the inversion.");

                break;
            case "observation_count":
                titleRef.current?.style.setProperty('font-size', '2em', 'important');
                contentRef.current?.style.setProperty('font-size', '3em', 'important');
                setDisplayTitle("Observation Count");
                setPopupContent("The number of TROPOMI observations that will be used to inform the inversion.");

                break;
            case "total_prior_emissions":
                titleRef.current?.style.setProperty('font-size', '2em', 'important');
                contentRef.current?.style.setProperty('font-size', '3.5em', 'important');
                setDisplayTitle("Prior Estimate");
                setPopupContent("The total prior estimate of emissions in the region of interest. This is the total amount of emissions that will be distributed across the region of interest.");

                break;
            case "approximate_cost_on_demand":
                titleRef.current?.style.setProperty('font-size', '2em', 'important');
                contentRef.current?.style.setProperty('font-size', '3em', 'important');
                setDisplayTitle("Estimated Cost (USD)");
                setPopupContent("The estimated cost of running the full inversion. We're working on improving this estimate.");

                break;
        }

    }, []);



    return (
        <>
            <div className="infoCard">
                <Popup className="darkPopup" content={popupContent} trigger={<Icon className="previewDashInfoIcon" name="info" size="small" color='grey' circular inverted />} />
                <div className="title" ref={titleRef}>{displayTitle}</div>
                {displayTitle === 'Estimated Cost (USD)' ?
                    <div className="content" ref={contentRef}>${content}</div> :
                    displayTitle === "Prior Estimate" ? <div className="content" ref={contentRef}>{(content - Math.floor(content)) !== 0 ? content.toFixed(3) : content} <span style={{ fontSize: "0.5em" }}>Tg/yr</span></div>
                        :

                        //check if content is a whole number or float, truncate if float
                        <div className="content" ref={contentRef}>{(content - Math.floor(content)) !== 0 ? content.toFixed(3) : content.toLocaleString()}</div>}
            </div>
        </>
    );
};

export default PreviewDashInfoCard;