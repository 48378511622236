import React, { useState, useEffect, useRef } from "react";
import "./HomePage.css";
import StandardButton from "../Buttons/standardButton";
import Plot from "react-plotly.js";

const HomePage = () => {
  // State for the bar data
  const [emissionData, setEmissionData] = useState({
    wetlandsPrior: 0,
    wetlandsPosterior: 0,
    gasPrior: 0,
    gasPosterior: 0,
    livestockPrior: 0,
    livestockPosterior: 0,
    landfillsPrior: 0,
    landfillsPosterior: 0,
  });

  // Final values for the bars when fully scrolled
  const targetEmissionData = {
    wetlandsPrior: 1,
    wetlandsPosterior: 1.1,
    gasPrior: 2,
    gasPosterior: 2.5,
    livestockPrior: 0.4,
    livestockPosterior: 0.35,
    landfillsPrior: 0.7,
    landfillsPosterior: 0.9,
  };

  const [scrollPercentagePlot, setScrollPercentagePlot] = useState(0);
  const [scrollPercentageCitations, setScrollPercentageCitations] = useState(0);

  // Calculate the maximum emission value
  const maxEmission = Math.max(
    targetEmissionData.wetlandsPrior,
    targetEmissionData.wetlandsPosterior,
    targetEmissionData.gasPrior,
    targetEmissionData.gasPosterior,
    targetEmissionData.livestockPrior,
    targetEmissionData.livestockPosterior,
    targetEmissionData.landfillsPrior,
    targetEmissionData.landfillsPosterior
  );
  // Floating tile scroll effect
  const fakeCitations = [
    { text: "Varon et al., 2022.", link: "https://doi.org/10.5194/gmd-15-5787-2022" },
    { text: "Estrada et al., 2024.", link: "https://doi.org/10.5194/egusphere-2024-2700" },
    { text: "Hancock et al., 2024.", link: "https://doi.org/10.5194/egusphere-2024-1763" },
    { text: "Nathan et al., 2024.", link: "https://doi.org/10.5194/acp-24-6845-2024" },
  ];

  // Daniel
  // Lucas
  // Sara
  // SRON

  // Set the x-axis max to 25% more than the max emission value
  const xAxisMax = maxEmission * 1.25;

  // Reference to the parallax plot element
  const plotRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      // Scroll handling for plot animation
      const triggerStartPlot = 0;
      const triggerEndPlot = window.innerHeight / 2.5;
      const currentScroll = window.scrollY;

      const scrollPlotPercentage = Math.min(
        Math.max((currentScroll - triggerStartPlot) / (triggerEndPlot - triggerStartPlot), 0),
        1
      );
      setScrollPercentagePlot(scrollPlotPercentage);

      // Update emission data based on scroll percentage
      setEmissionData({
        wetlandsPrior: scrollPlotPercentage * targetEmissionData.wetlandsPrior,
        wetlandsPosterior: scrollPlotPercentage * targetEmissionData.wetlandsPosterior,
        gasPrior: scrollPlotPercentage * targetEmissionData.gasPrior,
        gasPosterior: scrollPlotPercentage * targetEmissionData.gasPosterior,
        livestockPrior: scrollPlotPercentage * targetEmissionData.livestockPrior,
        livestockPosterior: scrollPlotPercentage * targetEmissionData.livestockPosterior,
        landfillsPrior: scrollPlotPercentage * targetEmissionData.landfillsPrior,
        landfillsPosterior: scrollPlotPercentage * targetEmissionData.landfillsPosterior,
      });

      // Scroll handling for citation tile animation
      const triggerStartCitations = 0;
      const triggerEndCitations = window.innerHeight * 2;
      const scrollCitationsPercentage = Math.min(
        Math.max((currentScroll - triggerStartCitations) / (triggerEndCitations - triggerStartCitations), 0),
        1
      );
      setScrollPercentageCitations(scrollCitationsPercentage);
    };


    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="pageWrapper" style={{ paddingLeft: "10vw", paddingRight: "10vw" }}>
      <div style={{ display: "flex", justifyContent: "space-between", margin: "auto 4vw" }}>
        <img src="/pngs/SentinelSat.png" alt="Sentinel Satellite" style={{ maxWidth: "24vw", height: "auto", width: "100%", objectFit: "contain", marginLeft: "5vw", paddingBottom: "5vh" }} />
        <div className="homePageBlock floatRight" style={{ textAlign: "right" }}>
          <h1 className="textUp">Monitoring Methane Emissions, <span style={{ color: "var(--dark-yellow)" }}>Simplified</span></h1>
          <br />
          <p style={{ fontSize: "1.3rem" }}>
            Integral Earth is a user-friendly web interface for quantifying <strong>total methane emissions</strong> with satellite data. Users can map emissions for <span style={{ color: "var(--dark-yellow)" }}>any region of interest at up to 25-km resolution</span> using public satellite data and transparent, research-grade algorithms, <span style={{ color: "var(--dark-yellow)" }}>with no learning curve</span>.
          </p>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a href="/signin">
              <StandardButton text="Get started" color="darkyellow" clickFunction={(e: any) => console.log(e)} />
            </a>
          </div>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "space-between", margin: "auto 4vw" }}>
        <div className="homePageBlock floatLeft" style={{ textAlign: "left" }}>
          <h1 className="textUp">Transparent <span style={{ color: "var(--teal-blue)" }}>Research-Grade</span> Algorithms</h1>
          <br />
          <p style={{ fontSize: "1.3rem" }}>
            Integral Earth is built on the open-source <a href="https://integratedmethaneinversion.github.io/" style={{ color: "var(--teal-blue)", textDecoration: "underline" }}>Integrated Methane Inversion</a> (IMI) software tool. The IMI applies mature and published inverse methodologies continuously developed at Harvard University's Atmospheric Chemistry Modeling Group (ACMG) to infer methane emissions. <span style={{ color: "var(--teal-blue)" }}>Integral Earth is a web-based wrapper for the IMI</span> developed and managed by the IMI Team <span style={{ color: "var(--teal-blue)" }}>to make emission estimates accessible to stakeholders</span>.
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a href="https://integratedmethaneinversion.github.io" target="#">
              <StandardButton text="Read more" color="var(--teal-blue)" clickFunction={(e: any) => console.log(e)} />
            </a>
          </div>
        </div>

        {/* Replace the Daniel Jacob image with the Plotly parallax chart */}
        <div ref={plotRef} style={{ width: "30%", height: "auto", marginLeft: "2rem" }}>
          <Plot
            data={[
              {
                y: ["Wetlands", "Livestock", "Landfills", "Gas"],  // Y-axis categories
                x: [emissionData.wetlandsPrior, emissionData.livestockPrior, emissionData.landfillsPrior, emissionData.gasPrior],  // Prior emissions
                name: "Prior",
                type: "bar",
                orientation: "h",
                marker: { color: "rgb(124, 124, 124)" },
                hovertemplate: "Category: %{y}<br>Prior Emissions: %{x:.2f} Tg/yr<extra></extra>"
              },
              {
                y: ["Wetlands", "Livestock", "Landfills", "Gas"],  // Y-axis categories
                x: [emissionData.wetlandsPosterior, emissionData.livestockPosterior, emissionData.landfillsPosterior, emissionData.gasPosterior],  // Posterior emissions
                name: "Posterior",
                type: "bar",
                orientation: "h",
                marker: { color: "#62cceb" },
                hovertemplate: "Category: %{y}<br>Posterior Emissions: %{x:.2f} Tg/yr<extra></extra>"
              }
            ]}
            layout={{
              title: "Sectoral Emissions",
              barmode: "group",
              // yaxis: { title: "Categories" },
              xaxis: {
                title: "Emissions (Tg/yr)",
                range: [0, xAxisMax]  // Fixed x-axis range
              },
              paper_bgcolor: "rgba(0, 0, 0, 0)",
              plot_bgcolor: "rgba(0, 0, 0, 0)",
              font: { color: "white" },
              width: 500,
              height: 400,
              margin: { t: 100, b: 100, l: 100, r: 100 },
              legend: {
                x: 0,
                y: 1.35,
                xanchor: "center",
                yanchor: "top",
                orientation: "v"
              },
              bargap: 0.4,
            }}
          />
        </div>
      </div>

      <div className="homePageBlock floatRight" style={{ textAlign: "right" }}>
        <h1 className="textUp">Support</h1>
        <br />
        <p style={{ fontSize: "1.3rem" }}>
          Integral Earth is supported by the Harvard Grid Accelerator Program through March 2025. We are actively seeking funding to continue development and maintenance of the platform. If you are interested in supporting our mission, please reach out to us at <a href="mailto:integral.earth.team@gmail.com" style={{ color: "var(--dark-yellow)", textDecoration: "underline" }}>integral.earth.team@gmail.com</a>.
        </p>
      </div>
      {/* Logo display */}
      <div className="homePageBlock floatLeft" style={{ marginLeft: "5%" }}>
        <div className="homePageLogoContainer tileShadow" >
          <div className="centerHorizontally">

            <img src="/logos/seas_logo.png" alt="Integral Earth Logo" id="homePageLogo" style={{ width: "auto" }} />
          </div>
          <div className="centerHorizontally" style={{ gap: "16%" }}>
            <img src="/logos/grid_logo.png" alt="Integral Earth Logo" id="homePageLogo" />
            <img src="/logos/ACMG_logo.png" alt="Integral Earth Logo" id="homePageLogo" style={{ transform: "scale(1.2)" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;