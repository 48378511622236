import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Button, Search, Dropdown, Loader, Icon, Label, Popup } from "semantic-ui-react";
import _ from "lodash";
import JobTile from "./JobTile";
import BoundsModalAdmin from "./BoundsModalAdmin";
import "./AdminPortal.css";

const AdminPortal = () => {
  const { user, checkToken } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [usernameFilter, setUsernameFilter] = useState(""); // New state for username filter
  const [userIdFilter, setUserIdFilter] = useState(""); // New state for user ID filter
  const [openBoundsModals, setOpenBoundsModals] = useState({});

  useEffect(() => {
    checkToken();

    const fetchJobs = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`/api/return_all_jobs/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        const data = await response.json();
        setJobs(data.jobs);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
      setIsLoading(false);
    };

    fetchJobs();
  }, []);

  // Function to handle opening a bounds modal for a specific job
  const handleOpenBoundsModal = (jobId) => {
    setOpenBoundsModals((prevState) => ({
      ...prevState,
      [jobId]: true,
    }));
  };

  const handleCloseBoundsModal = (jobId) => {
    setOpenBoundsModals((prevState) => ({
      ...prevState,
      [jobId]: false,
    }));
  };

  useEffect(() => {
    if (jobs) {
      setFilteredJobs(
        jobs.filter((job) => {
          const matchesStatus = selectedStatus === "all" || job.status === selectedStatus;
          const matchesUsername = usernameFilter === "" || job.config_obj?.user?.username?.toLowerCase().includes(usernameFilter.toLowerCase());
          const matchesUserId = userIdFilter === "" || job.config_obj?.user?.id === userIdFilter;

          return matchesStatus && matchesUsername && matchesUserId;
        })
      );
    }
  }, [jobs, selectedStatus]);

  // Create a dropdown options array for user IDs with username // user.id format
  const userIdOptions = [
    { key: "", text: "All Users", value: "" }, // Option for no filter
    ...[...new Set(jobs.map((job) => job.config_obj?.user?.id))] // Get unique user IDs
      .filter((id) => id) // Filter out undefined or null IDs
      .map((id) => {
        // Find the first job with the current user ID to extract the username
        const job = jobs.find((job) => job.config_obj?.user?.id === id);
        const username = job?.config_obj?.user?.username || "Unknown User"; // Fallback for missing username
        return {
          key: id,
          text: `${username} // ${id}`, // Format: username // user.id
          value: id,
        };
      }),
  ];

  return (
    <div className="pageWrapper" style={{ marginLeft: "7vw" }}>
      <div className="centerHorizontally" style={{ float: "left", gap: "40px" }}>
        <div>
          <Popup content="Filter by status" trigger={<Icon name="road" size="large" circular inverted color="grey" />} />
          {/* Status Dropdown */}
          <Dropdown
            className="darkDropdownShallow"
            style={{ zIndex: "10 !important" }}
            placeholder="Filter by status"
            options={[
              { key: "all", text: "All", value: "all" },
              { key: "submitted", text: "Submitted", value: "SUBMITTED" },
              { key: "running", text: "Running", value: "RUNNING" },
              { key: "completed", text: "Completed", value: "COMPLETED" },
              { key: "error", text: "Error", value: "ERROR" },
            ]}
            selection
            onChange={(e, { value }) => setSelectedStatus(value)}
            value={selectedStatus}
          />
        </div>

        {/* Username Search Input
        <Search
          placeholder="Search by Username"
          onSearchChange={_.debounce((e, { value }) => setUsernameFilter(value), 300)}
          showNoResults={false}
          style={{ marginLeft: "10px" }}
        /> */}

        {/* User ID Dropdown */}
        <div>
          <Popup content="Filter by user" trigger={<Icon name="user" size="large" circular inverted color="grey" />} />

          <Dropdown
            className="darkDropdownShallow"
            placeholder="Filter by User ID"
            options={userIdOptions}
            selection
            onChange={(e, { value }) => setUserIdFilter(value)}
            value={userIdFilter}
          />
        </div>
        <div className="centerHorizontally" style={{ gap: "30px" }}>
          <div className="centerHorizontally centerVertical">
            <Icon name="eye" circular inverted color="grey" />
            <p>Preview</p>
          </div>
          <div className="centerHorizontally">
            <div className="centerHorizontally centerVertical">
              <Icon name="globe" circular inverted color="grey" />
              <p>Full Inversion</p>
            </div>
          </div>
        </div>
      </div>

      <div className="tileWrapperAdmin" style={{ marginTop: "3rem" }}>
        {isLoading ? <Loader active inline="centered" /> : null}
        {filteredJobs.length === 0 && !isLoading && <p>No jobs found</p>}
        {filteredJobs.map((job) => (
          <React.Fragment key={job.id}>
            <JobTile
              job={job}
              timeRemaining={job.time_remaining}
              viewBounds={() => handleOpenBoundsModal(job.id)}
              cancelRun={() => console.log("cancelled!")}
              config_obj={job.config_obj}
            />
            <BoundsModalAdmin open={openBoundsModals[job.id] || false} onClose={() => handleCloseBoundsModal(job.id)} shapefileID={job.config_obj?.shapefileFK} />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default AdminPortal;
