import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { AuthContext } from "../contexts/AuthContext";

const API_URL = `/api/`;

const register = async (username, organization, email, password) => {
  return axios
    .post(API_URL + "register/", {
      username,
      organization,
      email,
      password,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        // Handle specific error messages from the backend
        return { errors: error.response.data };
        console.log(error.response.data.email);
      } else {
        console.log(error.message); // Generic error message
      }
    });
};

const login = async (email, password) => {
  try {
    // Send a POST request to the login API endpoint
    const response = await axios.post(API_URL + "login/", {
      email: email,
      password: password,
    });

    // Check if the response contains an error key (coming from the backend)
    if (response.data.error) {
      // console.log("Error from auth services:", response.data.error);
      throw new Error(response.data.error); // Throw the error message received from the backend
    }

    // If no error, proceed with setting the user and storing tokens
    if (response.data.user) {
      const user = response.data.user;
      const accessToken = response.data.access; // Assuming you are sending tokens with the response
      const refreshToken = response.data.refresh;

      // Store tokens and user info in localStorage
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("user", JSON.stringify(user));

      // Set the user in your state
      return user;
    }

    return response.data;
  } catch (error) {
    // Handle the error by throwing it so that the calling function can catch it
    throw new Error(error.message || "An error occurred during login.");
  }
};

const logout = async () => {
  try {
    const currentUser = getCurrentUser();
    if (currentUser && currentUser.user_id) {
      // Await the fetch call to ensure it completes before proceeding
      const response = await fetch(API_URL + `change_active/?id=${currentUser.user_id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        // Log the issue if the response is not successful
        console.error("Failed to change user status:", response.statusText);
      }
    }
  } catch (error) {
    console.error("There was an error changing the user status:", error);
  } finally {
    // Ensure tokens and user data are removed even if the fetch fails
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
  }
};

const getCurrentUser = () => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    return jwtDecode(token);
  }
  return null;
};


export default {
  register,
  login,
  logout,
  getCurrentUser,
};
