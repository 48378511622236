import React, { useRef, useEffect, useState } from "react";
import { Button, Input, Loader, Form, Radio } from "semantic-ui-react";
import "./testingPage.css";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw";
import * as shapefile from "shapefile";
import { set } from "date-fns";
import { intersect } from "@turf/intersect";
import area from "@turf/area";
import * as turf from "@turf/turf";
// import oceans from './oceans.geojson'

// const oceans = require('./oceans.geojson')

function TestingPage() {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);
  const [geojson, setGeojson] = useState(null);
  const [fetchID, setFetchID] = useState(1);
  const [loading, setLoading] = useState(false);
  const [fetchSelect, setFetchSelect] = useState("shapefile");
  const [country, setCountry] = useState("");
  const [createMsg, setCreateMsg] = useState("");
  const [uploadMsg, setUploadMsg] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [oceanBoundaries, setOceanBoundaries] = useState(null);
  const [featureInfo, setFeatureInfo] = useState({});

  // let testConfigData = {
  //     "userID": 1,
  //     "RunName": "TestRun_2023_June",
  //     "IsAWS": true,
  //     "UseSlurm": false,
  //     "SafeMode": true,
  //     "S3Upload": true,
  //     "StartDate": "20230601",
  //     "EndDate": "20230630",
  //     "SpinupMonths": 2,
  //     "BlendedTROPOMI": true,
  //     "IsRegional": false,
  //     "RegionID": "",
  //     "LonMin": -120,
  //     "LonMax": -100,
  //     "LatMin": 30,
  //     "LatMax": 50,
  //     "KalmanMode": true,
  //     "UpdateFreqDays": 14,
  //     "NudgeFactor": 0.2,
  //     "CreateAutomaticRectilinearStateVectorFile": false,
  //     "NBufferClusters": 10,
  //     "BufferDeg": 7,
  //     "LandThreshold": 0.3,
  //     "OffshoreEmisThreshold": 0.1,
  //     "OptimizeBCs": true,
  //     "OptimizeOH": true,
  //     "PointSourceDatasets": ["SRON", "EDGAR"],
  //     "ReducedDimensionStateVector": true,
  //     "DynamicKFClustering": true,
  //     "ClusteringMethod": "hierachical",
  //     "NumberOfElements": 60,
  //     "ForcedNativeResolutionElements": [[35.2, -118], [40.7, -74]],
  //     "StateVectorFile": "/path/to/custom/StateVector.nc",
  //     "ShapeFile": "resources/shapefiles/custom_shape.shp",
  //     "PriorError": 0.4,
  //     "PriorErrorBCs": 15.0,
  //     "PriorErrorOH": 0.6,
  //     "ObsError": 10,
  //     "Gamma": 0.8,
  //     "PrecomputedJacobian": true,
  //     "Res": "0.5x0.625",
  //     "Met": "MERRA2",
  //     "SetupTemplateRundir": false,
  //     "SetupSpinupRun": false,
  //     "SetupJacobianRuns": false,
  //     "SetupInversion": false,
  //     "SetupPosteriorRun": false,
  //     "RunSetup": false,
  //     "DoSpinup": true,
  //     "DoJacobian": true,
  //     "DoInversion": true,
  //     "DoPosterior": true,
  //     "DoPreview": false,
  //     "DOFSThreshold": 50,
  //     "SimulationCPUs": 64,
  //     "SimulationMemory": 64000,
  //     "JacobianCPUs": 2,
  //     "JacobianMemory": 4000,
  //     "RequestedTime": "1-12:00",
  //     "SchedulerPartition": "custom_partition",
  //     "MaxSimultaneousRuns": 10,
  //     "PerturbValue": 2.0,
  //     "PerturbValueOH": 2.0,
  //     "PerturbValueBCs": 20.0,
  //     "UseEmisSF": true,
  //     "UseOHSF": true,
  //     "HourlyCH4": false,
  //     "PLANEFLIGHT": true,
  //     "GOSAT": true,
  //     "TCCON": true,
  //     "AIRS": true,
  //     "OutputPath": "/custom/output/path",
  //     "DataPath": "/custom/data/path",
  //     "DataPathTROPOMI": "/custom/tropomi/data/path",
  //     "CondaEnv": "custom_env",
  //     "GEOSChemEnv": "custom/geos_chem/env.txt",
  //     "RestartDownload": true,
  //     "RestartFilePrefix": "/custom/restart/prefix",
  //     "RestartFilePreviewPrefix": "/custom/restart/preview/prefix",
  //     "BCpath": "/custom/bc/path",
  //     "BCversion": "custom_version",
  //     "PreviewDryRun": true,
  //     "SpinupDryrun": true,
  //     "ProductionDryRun": true,
  //     "PosteriorDryRun": true,
  //     "BCdryrun": true
  // }

  const names = ["Wigglesworth Snugglebottom", "Fluffernutter Fizzlebottom", "Snorkeldink Curdlesnoot", "Bumblebee Cabbagepatch", "Rinkydink Stinkypants"];

  // Function to check if a shapefile contains only polygons
  async function isPolygonShapefile(file) {
    try {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      await new Promise((resolve) => {
        reader.onloadend = resolve;
      });

      const arrayBuffer = reader.result;
      const shp = await shapefile.read(arrayBuffer);

      for (let feature of shp.features) {
        if (feature.geometry.type !== "Polygon" && feature.geometry.type !== "MultiPolygon") {
          return false;
        }
      }

      return true;
    } catch (error) {
      console.error("Error reading shapefile:", error);
      return false;
    }
  }

  const name = names[Math.floor(Math.random() * names.length)];
  const address = "123 Main St";
  const email = name.split(" ")[0].toLowerCase() + "@" + name.split(" ")[1].toLowerCase() + ".com";
  const coordinates = [
    [0.019569, 51.31173],
    [0.019569, 51.76614],
    [1.189613, 51.76614],
    [1.189613, 51.31173],
    [0.019569, 51.31173],
  ];

  useEffect(() => {
    const map = L.map(mapRef.current).setView([42.356, -71.06423], 8);
    mapInstanceRef.current = map;

    L.tileLayer("https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.{ext}?api_key=e06c3b94-e393-4722-bd75-8257a760dcfb", {
      minZoom: 0,
      maxZoom: 20,
      attribution:
        '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      ext: "png",
    }).addTo(map);

    const drawnItems = new L.FeatureGroup();
    map.addLayer(drawnItems);

    const drawControl = new L.Control.Draw({
      edit: {
        featureGroup: drawnItems,
        poly: {
          allowIntersection: false,
        },
      },
      draw: {
        polygon: {
          allowIntersection: false,
          showArea: true,
        },
      },
    });
    map.addControl(drawControl);

    // Event handler for when a new feature is created
    map.on(L.Draw.Event.CREATED, (event) => {
      const layer = event.layer;
      drawnItems.addLayer(layer);
      const userShape = layer.toGeoJSON();

      // console.log("Feature bounds:", userShape);
      // console.log("Ocean Bounds: ", oceanBoundaries.features[0])
      // console.log("Do they intersect?: ", intersect(userShape, oceanBoundaries.features[0]))
      updateFeatureInfo(drawnItems);

      layer.bindPopup((area(userShape) / 1000000).toFixed(2) + " square km");
      console.log("Drawn layers: ", drawnItems.getLayers());
      for (const layer of drawnItems.getLayers()) {
        console.log("Layer geos: ", layer.toGeoJSON());
      }

      if (drawnItems.getLayers().length >= 2) {
        console.log("HELLO???: ", drawnItems.getLayers()[0].toGeoJSON().geometry);
        console.log("HELLO???: ", drawnItems.getLayers()[1].toGeoJSON().geometry);
        // console.log("COMPARING!!", intersect(drawnItems.getLayers()[0].toGeoJSON().geometry, drawnItems.getLayers()[1].toGeoJSON().geometry))
      }

      // Check if userShape intersects with any feature in oceanBoundaries
      // let intersects = false;

      // if (oceanBoundaries && oceanBoundaries.features) {
      //   for (const oceanFeature of oceanBoundaries.features) {
      //     console.log("Checking intersection with ocean feature:", oceanFeature);
      //     // const intersection = intersect(userShape, oceanFeature);
      //     // if (intersection) {
      //     //   intersects = true;
      //     //   break; // Exit loop if an intersection is found
      //     // }
      //     }
      //     } else {
      //   console.error("Invalid oceanBoundaries GeoJSON:", oceanBoundaries);
      //   }

      // if (intersects) {
      //   console.log("Feature intersects with ocean boundaries");
      // } else {
      //   console.log("Feature does not intersect with ocean boundaries");
      // }
    });

    // Event handler for when a feature is edited
    map.on(L.Draw.Event.EDITED, (event) => {
      const layers = event.layers;
      layers.eachLayer((layer) => {
        console.log("Feature edited:", layer.toGeoJSON());
        updateFeatureInfo(drawnItems);
      });
    });

    // Event handler for when a feature is deleted
    map.on(L.Draw.Event.DELETED, (event) => {
      const layers = event.layers;
      layers.eachLayer((layer) => {
        console.log("Feature deleted:", layer.toGeoJSON());
        updateFeatureInfo(drawnItems);
      });
    });

    return () => {
      map.remove();
    };
  }, []);

  const updateFeatureInfo = (featureGroup) => {
    const features = featureGroup.getLayers();
    const featureData = [];

    features.forEach((feature) => {
      featureData.push(feature.toGeoJSON());
    });
  };

  //ADD LAYER TO MAP WHEN GEOJSON IS ADDED TO STATE
  useEffect(() => {
    const map = mapInstanceRef.current;

    console.log(map.eachLayer((layer) => console.log(layer)));

    if (map && geojson) {
      const newLayer = L.geoJSON(geojson).addTo(map);

      if (newLayer.getBounds().isValid()) {
        const newBounds = newLayer.getBounds();
        map.fitBounds(newBounds);
      } else {
        console.log("Bounds not valid for the new layer");
      }
    }
  }, [geojson]);

  // ADD OCEAN BOUNDARIES LAYER TO MAP
  useEffect(() => {
    console.log("Running useEffect to add oceans");
    const map = mapInstanceRef.current;

    const newLayer = L.geoJSON(oceanBoundaries).addTo(map);

    // if (map && oceanBoundaries) {
    //   const newLayer = L.geoJSON(oceanBoundaries).addTo(map);

    //   if (newLayer.current.getBounds().isValid()) {
    //     const newBounds = newLayer.getBounds();
    //     map.fitBounds(newBounds);
    //   } else {
    //     console.log("Bounds not valid for the ocean layer");
    //   }
    // }

    // console.log(newLayer.toGeoJSON().features[0].geometry.coordinates)
  }, [oceanBoundaries]);

  const handleFileUpload = (event) => {
    // const file = event.target.files[0];
    // const file = oceans
    // const reader = new FileReader();
    // reader.onload = (e) => {
    //   const content = file;
    //   setGeojson(JSON.parse(content));
    // };
    // reader.readAsText(file);
  };

  const handleAddOceans = () => {
    const map = mapInstanceRef.current;

    // console.log(typeof oceans)
    // console.log(oceans)

    fetch("/rewound-oceans-geojson.json")
      .then((response) => response.json())
      .then((data) => {
        setOceanBoundaries(data);
        // console.log(oceanBoundaries)
      })
      .catch((error) => console.error("Error fetching GeoJSON:", error));

    // if (map) {
    //   const oceanLayer = L.geoJSON(oceans).addTo(map);

    //   if (oceanLayer.getBounds().isValid()) {
    //     const oceanBounds = oceanLayer.getBounds();
    //     map.fitBounds(oceanBounds);
    //   } else {
    //     console.log("Bounds not valid for the ocean layer");
    //   }
    // }
  };

  const handleUpdate = () => {
    let table = "job";
    let id = 6;
    let payload = {
      pred_cost: 1000000.0,
      status: "RUNNING",
    };

    fetch(`/api/update/?table=${table}&id=${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data here
        console.log(data);
      })
      .catch((error) => {
        // Handle any errors here
        console.error(error);
      });
  };

  const handleDelete = () => {
    let table = "job";
    let id = 6;

    fetch(`/api/delete/?table=${table}&id=${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data here
        console.log(data);
      })
      .catch((error) => {
        // Handle any errors here
        console.error(error);
      });
  };

  const handleFetch = () => {
    setLoading(true);

    let table = fetchSelect;
    let field = "id";
    let val = fetchID ? fetchID : 1;

    fetch(`/api/fetch/?table=${table}&field=${field}&val=${val}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.shapefile) {
          setGeojson(JSON.parse(data.shapefile));
        }

        // console.log(typeof geojson);
        // console.log(JSON.parse(data.user)[0].fields.email);
        setLoading(false);
      })
      .catch((error) => {
        // Handle any errors here
        console.error(error);
      });
  };

  const handleCreate = () => {
    let table = "user";
    let payload = { name, email, address, coordinates };

    fetch(`/api/create/?table=${table}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data here
        console.log(data);
      })
      .catch((error) => {
        // Handle any errors here
        console.error(error);
      });
  };

  const handleShpFile = async (event) => {
    const files = event.target.files;
    const formData = new FormData();

    // Check if all required files are selected
    if (files.length === 4) {
      const shpFile = Array.from(files).find((file) => file.name.endsWith(".shp"));

      // Check if shapefile only contains polygons
      try {
        const isPolygon = await isPolygonShapefile(shpFile);
        if (!isPolygon) {
          console.error("Shapefile must contain only polygons or multipolygons");
          setCreateMsg("❌ Shapefile must contain only polygons or multipolygons.");
          event.target.value = "";
          return;
        } else {
          console.log("Shapefile contains only polygons or multipolygons, champ.");
        }
      } catch (error) {
        console.error("Error checking shapefile geometry:", error);
        return;
      }

      const shxFile = Array.from(files).find((file) => file.name.endsWith(".shx"));
      const dbfFile = Array.from(files).find((file) => file.name.endsWith(".dbf"));
      const prjFile = Array.from(files).find((file) => file.name.endsWith(".prj"));

      if (shpFile && shxFile && dbfFile && prjFile) {
        formData.append("shpFile", shpFile);
        formData.append("shxFile", shxFile);
        formData.append("dbfFile", dbfFile);
        formData.append("prjFile", prjFile);

        fetch(`/api/create_shp/?method=upload`, {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            // Handle the response data here
            console.log(data);
            setUploadMsg(`✅ Shapefile uploaded with ID: ${data.recent_shp}.`);
            event.target.value = "";
          })
          .catch((error) => {
            // Handle any errors here
            console.error(error);
          });
      } else {
        console.error("Please select all required files: .shp, .shx, and .dbf");
      }
    } else {
      console.error("Please select all required files: .shp, .shx, and .dbf");
    }
  };

  function listActiveLayers() {
    const layers = [];
    const map = mapInstanceRef.current; // Access the current value of the ref

    if (map) {
      // Check if the map instance exists
      map.eachLayer(function (layer) {
        // Check if the layer is a marker, polygon, polyline, or other shape
        if (layer instanceof L.Marker || layer instanceof L.Polygon || layer instanceof L.Polyline || layer instanceof L.Circle) {
          layers.push(layer.toGeoJSON());
        }
      });
    }

    if (layers.length > 1) {
      // Ensure that you are passing geometries to the turf.intersect function
      const geometry1 = layers[0];
      const geometry2 = layers[1];

      console.log("Geometry 1: ", geometry1);
      console.log("Geometry 2: ", geometry2);

      // Validate geometries
      console.log("Am I running?");
      const isValidGeometry1 = turf.booleanValid(geometry1);
      const isValidGeometry2 = turf.booleanValid(geometry2);

      console.log("Is geometry 1 valid?", isValidGeometry1);
      console.log("Is geometry 2 valid?", isValidGeometry2);

      // Check if both geometries exist and are valid
      if (geometry1 && geometry2) {
        let intersection = turf.intersect(turf.featureCollection(geometry1, geometry2));
        console.log("Intersection test: ", intersection ? "Shapes intersect" : "Shapes do not intersect");
      } else {
        console.error("Invalid geometries for intersection.");
      }
    }
  }

  const handleFetchChange = (e, { value }) => {
    setFetchSelect(value);
  };

  const submitCountryGeo = () => {
    fetch(`/api/create_shp/?alpha3=${country}&method=list`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setGeojson(JSON.parse(data.shapefile));
        setCreateMsg(`✅ Country Geojson for ${country} created with ID: ${data.id}.`);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const s3_test = () => {
    fetch(`/api/s3_fetch_test/?object_key=sample-output%2FTest_Permian_default_normal%2Fpreview_run%2Fpreview_observation_density.png`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        const base64image = data.image_data;
        const url = `data:image/png;base64,${base64image}`;

        setImageURL(url);
      });
  };

  const fetchOcean = () => {
    fetch(`/api/fetch_oceans/`, {
      method: "GET",
    }).then((response) =>
      response
        .json()
        .then((data) => {
          console.log(data.oceans);
          setOceanBoundaries(data.oceans);
        })
        .catch((error) => {
          console.error(error);
        })
    );
  };

  return (
    <div
      className="pageWrapper"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: "90%",
          width: "25%",
          border: "solid pink 1px",
          borderRadius: "5px",
          padding: "10px",
          overflow: "scroll",
          position: "relative",
        }}
      >
        <h2 style={{ textAlign: "center" }}>Test Buttons</h2>
        <hr />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button color="violet" style={{ margin: "15px", width: "50%" }} onClick={handleUpdate}>
            Update
          </Button>
          <Button color="grey" style={{ margin: "15px", width: "50%" }} onClick={handleDelete}>
            Delete
          </Button>
          <div
            style={{
              border: "2px solid white",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              padding: "5px",
              color: "white",
            }}
          >
            <Button color="purple" style={{ margin: "15px", width: "50%" }} onClick={handleFetch}>
              Fetch
            </Button>
            <Input
              value={fetchID}
              onChange={(e) => {
                setFetchID(e.target.value);
              }}
              style={{ width: "50%" }}
              placeholder="ID value"
            ></Input>
            <Form>
              <Form.Field>
                <Radio label="Shapefile" name="radioGroup" value="shapefile" checked={fetchSelect === "shapefile"} onChange={handleFetchChange} />
              </Form.Field>
              <Form.Field>
                <Radio label="User" name="radioGroup" value="user" checked={fetchSelect === "user"} onChange={handleFetchChange} />
              </Form.Field>
              <Form.Field>
                <Radio label="Config" name="radioGroup" value="config" checked={fetchSelect === "config"} onChange={handleFetchChange} />
              </Form.Field>
            </Form>
            {loading && <Loader active inline content="🤞" />}
          </div>
          <Button color="green" style={{ margin: "15px", width: "50%" }} onClick={handleCreate}>
            Create
          </Button>

          <input type="file" accept=".shp,.shx,.dbf,.prj" onChange={handleShpFile} multiple />
          {uploadMsg && <p style={{ color: "rgb(39, 169, 39)" }}>{uploadMsg}</p>}

          <Button color="blue" style={{ margin: "15px 0 0 0", width: "70%", padding: "15px" }} onClick={submitCountryGeo}>
            Create Country Geojson
          </Button>
          <Input value={country} onChange={(e) => setCountry(e.target.value)} style={{ padding: "0px 0 15px 0" }} placeholder="Country code (e.g. USA)" />
          {createMsg && <p style={{ color: "rgb(39, 169, 39)" }}>{createMsg}</p>}
          <Button color="yellow" onClick={() => navigator.clipboard.writeText(JSON.stringify(geojson))}>
            Copy Active Geojson
          </Button>
          <Button onClick={s3_test}>Fetch S3</Button>
          <Button onClick={() => console.log(listActiveLayers())}>List Layers</Button>
          <Button onClick={handleAddOceans}>Add Oceans</Button>
          <input type="file" onChange={handleFileUpload} />
          {imageURL && (
            <img
              src={imageURL}
              alt=""
              style={{
                height: "auto",
                width: "80%",
                border: "solid green 2px",
              }}
            />
          )}
        </div>
      </div>
      <div
        style={{
          height: "90%",
          width: "75%",
          border: "solid pink 1px",
          borderRadius: "5px",
        }}
      >
        <div ref={mapRef} style={{ height: "300px", width: "400px" }}></div>
      </div>
    </div>
  );
}

export default TestingPage;
