import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Loader } from "semantic-ui-react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";

const BoundsModalAdmin = ({ shapefileID, open, onClose }) => {
  const [geoJsonData, setGeoJsonData] = useState(null);
  const mapRef = useRef(null);

  useEffect(() => {
    if (shapefileID) {
      fetch(`/api/fetch/?table=shapefile&field=id&val=${shapefileID}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          if (data.shapefile) {
            setGeoJsonData(JSON.parse(data.shapefile));
          }
        })
        .catch((error) => {
          console.error("Error fetching GeoJSON data:", error);
        });
    }
  }, [shapefileID]);

  const fitBoundsToData = (e) => {
    const layer = e.target;
    if (mapRef.current) {
      mapRef.current.fitBounds(layer.getBounds());
    }
  };

  const clearGeojson = () => {
    setGeoJsonData(null);
  };

  return (
    <Modal
      onClose={() => {
        onClose();
        clearGeojson();
      }}
      open={open}
    >
      <Modal.Header>Requested Bounds</Modal.Header>
      <Modal.Content>
        {geoJsonData ? (
          <MapContainer center={[0, 0]} zoom={2} style={{ height: "400px", width: "100%" }} ref={mapRef}>
            <TileLayer
              url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=e06c3b94-e393-4722-bd75-8257a760dcfb"
              attribution='&copy; <a href="https://www.stadiamaps.com/">Stadia Maps</a> contributors &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> contributors &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <GeoJSON
              data={geoJsonData}
              onEachFeature={(feature, layer) => {
                layer.on({
                  add: fitBoundsToData,
                });
              }}
            />
          </MapContainer>
        ) : (
          <div>
            <Loader active inline="centered" />
            <p>Loading...</p>
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default BoundsModalAdmin;
