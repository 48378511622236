import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { Form, Button, ButtonGroup, ButtonOr, ButtonContent, Icon, Input, Popup, Grid, GridRow, GridColumn, Message, Modal } from 'semantic-ui-react';
import SemanticDatepicker from "react-semantic-ui-datepickers";
import './InversionForm.css';
import InputField from './FormComponents/InputField.tsx';
import sampleFormVars from './templateFormVars.js';
import BoundsModal from "../BoundsModal.jsx";
import InversionReviewModal from './InversionReviewModal.jsx';
import AdvancedSettingsModal from './AdvancedSettingsModal.jsx';
import BoundsReviewModal from './BoundsReviewModal.jsx';
import ConfirmationTile from './FormComponents/ConfirmationTile.jsx';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';

import { valid as gjvValid, valid } from 'geojson-validation'

// VARIABLES TAKEN FROM DEV ON 8/5/2024
interface FormState {
    RunName: string;
    isAWS: boolean;
    UseSlurm: boolean;
    SafeMode: boolean;
    S3Upload: boolean;
    StartDate: string;
    EndDate: string;
    SpinupMonths: number;
    BlendedTROPOMI: boolean;
    UseWaterObs: boolean;
    isRegional: boolean;
    RegionID: string;
    LonMin: number;
    LonMax: number;
    LatMin: number;
    LatMax: number;
    KalmanMode: boolean;
    UpdateFreqDays: number;
    NudgeFactor: number;
    MakePeriodsCSV: boolean;
    CustomPeriodsCSV: string;
    CreateAutomaticRectilinearStateVectorFile: boolean;
    nBufferClusters: number;
    BufferDeg: number;
    LandThreshold: number;
    OffshoreEmisThreshold: number;
    OptimizeBCs: boolean;
    OptimizeOH: boolean;
    PointSourceDatasets: string[];
    ReducedDimensionStateVector: boolean;
    DynamicKFClustering: boolean;
    ClusteringMethod: string;
    NumberOfElements: number;
    ForcedNativeResolutionElements: number[][];
    EmissionRateFilter: number;
    PlumeCountFilter: number;
    GroupByCountry: boolean;
    StateVectorFile: string;
    ShapeFile: string;
    LognormalErrors: boolean;
    PriorError: number;
    PriorErrorBCs: number;
    PriorErrorBufferElements: number;
    PriorErrorOH: number;
    ObsError: number;
    Gamma: number;
    PrecomputedJacobian: boolean;
    Res: string;
    Met: string;
    RunSetup: boolean;
    SetupTemplateRundir: boolean;
    SetupSpinupRun: boolean;
    SetupJacobianRuns: boolean;
    SetupInversion: boolean;
    SetupPosteriorRun: boolean;
    DoHemcoPriorEmis: boolean;
    DoSpinup: boolean;
    DoJacobian: boolean;
    ReDoJacobian: boolean;
    DoInversion: boolean;
    DoPosterior: boolean;
    DoPreview: boolean;
    DOFSThreshold: number;
    RequestedCPUs: number;
    RequestedMemory: number;
    RequestedTime: string;
    SchedulerPartition: string;
    MaxSimultaneousRuns: number;
    NumJacobianTracers: number;
    PerturbValue: number;
    PerturbValueOH: number;
    PerturbValueBCs: number;
    HourlyCH4: boolean;
    PLANEFLIGHT: boolean;
    GOSAT: boolean;
    TCCON: boolean;
    AIRS: boolean;
    OutputPath: string;
    DataPath: string;
    CondaFile: string;
    CondaEnv: string;
    RestartDownload: boolean;
    RestartFilePrefix: string;
    BCpath: string;
    BCversion: string;
    HemcoPriorEmisDryRun: boolean;
    SpinupDryrun: boolean;
    ProductionDryRun: boolean;
    PosteriorDryRun: boolean;
    BCdryrun: boolean;
}

interface User {
    id: number;
    email: string;
    username: string;
    name?: string;
    is_admin: string;
}


const InversionForm = () => {

    const { user } = useContext(AuthContext) as { user: User };

    const [formState, setFormState] = useState<FormState>({
        // GENERAL
        RunName: "",
        isAWS: true,
        UseSlurm: true,
        SafeMode: true,
        S3Upload: false,
        StartDate: "",
        EndDate: "",
        SpinupMonths: 1,
        BlendedTROPOMI: true,
        UseWaterObs: false,

        // REGION OF INTEREST
        isRegional: true,
        RegionID: "NA",
        LonMin: -105,
        LonMax: -103,
        LatMin: 31,
        LatMax: 33,

        ///////////////////////////// Kalman Filter Options
        KalmanMode: false,
        UpdateFreqDays: 7,
        NudgeFactor: 0.1,
        MakePeriodsCSV: true,
        CustomPeriodsCSV: "/path/to/periods.csv",

        ///////////////////////////// State Vector
        CreateAutomaticRectilinearStateVectorFile: false,
        nBufferClusters: 8,
        BufferDeg: 5,
        LandThreshold: 0.25,
        OffshoreEmisThreshold: 0,
        OptimizeBCs: true,
        OptimizeOH: false,
        PointSourceDatasets: ["SRON"],

        ///////////////////////////// Clustering Options
        ReducedDimensionStateVector: false,
        DynamicKFClustering: false,
        ClusteringMethod: "kmeans",
        NumberOfElements: 45,
        ForcedNativeResolutionElements: [],
        EmissionRateFilter: 2500,
        PlumeCountFilter: 50,
        GroupByCountry: false,
        StateVectorFile: "/home/ubuntu/integrated_methane_inversion/resources/statevectors/StateVector.nc",

        ///////////////////////////// Inversion
        ShapeFile: "/home/ubuntu/integrated_methane_inversion/resources/shapefiles/PermianBasin_Extent_201712.shp",
        LognormalErrors: false,
        PriorError: 0.5,
        PriorErrorBCs: 10.0,
        PriorErrorBufferElements: 0.5,
        PriorErrorOH: 0.1,
        ObsError: 15,
        Gamma: 1.0,
        PrecomputedJacobian: false,
        Res: "0.25x0.3125",
        Met: "GEOSFP",
        RunSetup: true,
        SetupTemplateRundir: true,
        SetupSpinupRun: false,
        SetupJacobianRuns: false,
        SetupInversion: false,
        SetupPosteriorRun: false,
        DoHemcoPriorEmis: true,
        DoSpinup: false,
        DoJacobian: false,
        ReDoJacobian: false,
        DoInversion: false,
        DoPosterior: false,
        DoPreview: true,
        DOFSThreshold: 0,

        ///////////////////////////// SLURM Resource Allocation
        RequestedCPUs: 32,
        RequestedMemory: 32000,
        RequestedTime: "0-24:00",
        SchedulerPartition: "debug",
        MaxSimultaneousRuns: -1,

        ///////////////////////////// Advanced GEOS-Chem Configuration
        NumJacobianTracers: 5,
        PerturbValue: 1.0,
        PerturbValueOH: 1.1,
        PerturbValueBCs: 10.0,
        HourlyCH4: true,
        PLANEFLIGHT: false,
        GOSAT: false,
        TCCON: false,
        AIRS: false,
        OutputPath: "/home/ubuntu/imi_output_dir",
        DataPath: "/home/ubuntu/ExtData",
        CondaFile: "/home/ubuntu/.bashrc",
        CondaEnv: "imi_env",
        RestartDownload: true,
        RestartFilePrefix: "/home/ubuntu/ExtData/BoundaryConditions/v2024-06/GEOSChem.BoundaryConditions.",
        BCpath: "/home/ubuntu/ExtData/BoundaryConditions",
        BCversion: "v2024-06",
        HemcoPriorEmisDryRun: true,
        SpinupDryrun: true,
        ProductionDryRun: true,
        PosteriorDryRun: true,
        BCdryrun: true,
    });
    const [inversionMode, setInversionMode] = useState("preview");
    const [errorMessage, setErrorMessage] = useState('');
    const [geojsonSubmit, setGeojsonSubmit] = useState(null);
    const [geojsonAreaKM, setGeojsonAreaKM] = useState(null);
    const [estPixels, setEstPixels] = useState(null);
    const [imiRegion, setImiRegion] = useState<[string] | null>([""]);
    const [outOfRegion, setOutOfRegion] = useState(false);
    const [validGeojson, setValidGeojson] = useState(false);
    const [modalOpened, setModalOpened] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submittedJob, setSubmittedJob] = useState<Number | null>(null);

    const [boundsReviewModalOpen, setBoundsReviewModalOpen] = useState(false);
    const [reviewModalOpen, setReviewModalOpen] = useState(false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Set setup modules based on inversion mode // INACTICE SINCE WE FORCE PREVIEW MODE
    // useEffect(() => {
    //     console.log("Inversion Mode: ", inversionMode);
    //     if (inversionMode === "preview") {
    //         console.log("Setting preview mode");
    //         setFormState((prevState) => ({
    //             ...prevState,
    //             RunSetup: true,
    //             SetupTemplateRundir: true,
    //             SetupSpinupRun: false,
    //             SetupJacobianRuns: false,
    //             SetupInversion: false,
    //             SetupPosteriorRun: false,
    //             DoHemcoPriorEmis: true,
    //             DoSpinup: false,
    //             DoJacobian: false,
    //             ReDoJacobian: false,
    //             DoInversion: false,
    //             DoPosterior: false,
    //             DoPreview: true,
    //         }));
    //     } else if (inversionMode === "full") {
    //         console.log("Setting full inversion mode");
    //         setFormState((prevState) => ({
    //             ...prevState,
    //             RunSetup: true,
    //             SetupTemplateRundir: true,
    //             SetupSpinupRun: true,
    //             SetupJacobianRuns: true,
    //             SetupInversion: true,
    //             SetupPosteriorRun: true,
    //             DoHemcoPriorEmis: true,
    //             DoSpinup: true,
    //             DoJacobian: true,
    //             ReDoJacobian: true,
    //             DoInversion: true,
    //             DoPosterior: true,
    //             DoPreview: true,
    //         }));
    //     }
    //     // console.log(formState);
    // }, [inversionMode]);

    const resetForm = () => {
        setFormState(sampleFormVars)
    }

    // Validate GeoJSON on bounds submission
    useEffect(() => {
        console.log("Validating GeoJSON", gjvValid(geojsonSubmit));
        setValidGeojson(gjvValid(geojsonSubmit));
    }, [geojsonSubmit]);

    // Fetch IMI regions based on bounds
    useEffect(() => {
        if (!geojsonSubmit) return;

        fetch(`/api/return_imi_regions_route/?resolution=${formState.Res}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
            body: JSON.stringify(geojsonSubmit),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Regions:", data);
                if (data.regions.length === 0) {
                    setOutOfRegion(true);
                } else {
                    setImiRegion(data.regions);

                    setOutOfRegion(false);
                }
            });
    }, [geojsonSubmit]);

    // Change Met field based on Res field
    useEffect(() => {
        if (formState.Res === "0.25x0.3125") {
            setFormState((prevState) => ({
                ...prevState,
                Met: "GEOSFP",
            }));
        } else {
            setFormState((prevState) => ({
                ...prevState,
                Met: "MERRA2",
            }));
        }
    }, [formState.Res]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | any, data?: any) => {
        if (data) {
            // Handling for Dropdown component
            const { name, value } = data;
            setFormState((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else {
            const { name, value } = e.target;
            if (formState[name] === true || formState[name] === false) {
                setFormState({ ...formState, [name]: !formState[name] });
            } else {
                setFormState({ ...formState, [name]: value });
            }
        }
    };

    function createDateFromYYYYMMDD(dateString: string) {
        const year = parseInt(dateString.substring(0, 4), 10);
        const month = parseInt(dateString.substring(4, 6), 10) - 1; // Months are zero-indexed
        const day = parseInt(dateString.substring(6, 8), 10);

        return new Date(year, month, day);
    }

    const formatDateToYYYYMMDD = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}${month}${day}`;
    };

    const minDate = new Date(2018, 0, 1); // January 1, 2018
    const maxDate = new Date(2024, 4, 28); // May 28, 2024

    const handleDateChange = (e: any, { name, value }) => {
        if (value) {
            // Check if the value is within the min and max dates
            let boundedDate = value;
            if (boundedDate < minDate) {
                boundedDate = minDate;
            } else if (boundedDate > maxDate) {
                boundedDate = maxDate;
            }

            const formattedDate = formatDateToYYYYMMDD(boundedDate);

            // Update the form state with the bounded date
            setFormState((prevState) => ({
                ...prevState,
                [name]: formattedDate,
            }));

            // Use the new bounded value for validation
            if (name === 'EndDate' && formState.StartDate) {
                if (formattedDate <= formState.StartDate) {
                    setErrorMessage('End date must be later than the start date.');
                } else {
                    setErrorMessage(''); // Clear error if dates are valid
                }
            }

            // Optional: Validation for Start Date
            if (name === 'StartDate' && formState.EndDate) {
                if (formState.EndDate <= formattedDate) {
                    setErrorMessage('Start date must be before the end date.');
                } else {
                    setErrorMessage(''); // Clear error if dates are valid
                }
            }
        }
    };

    const handleSubmitForm = async () => {

        try {
            setLoading(true);
            setError(null);

            let shapefileCreateID = 0;
            let configCreateID = 0;
            let jobCreateID = 0;

            // Step 1: Fetch shapefile creation
            const shapefileResponse = await fetch(`/api/create_shp/?method=drawn&user_id=${user.id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
                body: JSON.stringify(geojsonSubmit),
            });

            const shapefileData = await shapefileResponse.json();

            console.log('Shapefile creation response: ', shapefileData);

            // Ensure data is in expected format
            if (shapefileData.error) {
                throw new Error('Failed to create shapefile');
            }
            shapefileCreateID = shapefileData.recent_shp;

            // Step 2: Fetch config creation with shapefile ID
            const configResponse = await fetch(`/api/create/?table=config`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
                body: JSON.stringify({ ...formState, shapefileID: shapefileCreateID, roiArea: geojsonAreaKM }),
            });

            const configData = await configResponse.json();

            if (configData.error) {
                throw new Error('Failed to create config');
            }
            configCreateID = configData.recent_config;

            // Step 3: Fetch job creation with config ID
            const jobResponse = await fetch(`/api/create/?table=job`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
                body: JSON.stringify({ configID: configCreateID, status: 'SUBMITTED' }),
            });

            const jobData = await jobResponse.json();

            if (jobData.error) {
                throw new Error('Failed to create job');
            }
            jobCreateID = jobData.recent_job;

            console.log('Shapefile creation success: ', shapefileCreateID);
            console.log('Config creation success: ', configCreateID);
            console.log('Job creation success: ', jobCreateID);

            const emailResponse = await fetch(`/api/change_job_status_route/?jobID=${jobCreateID}&new_status=SUBMITTED`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            });

            const emailData = await emailResponse.json();

            if (emailData.error) {
                throw new Error('Failed to send email');
            }

            setSubmittedJob(jobCreateID);

            // Handle success (e.g., show a message, navigate to another page, etc.)
        } catch (error) {
            console.error('Error during form submission:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (submittedJob) {
            setSubmitted(true);
        }
    }, [submittedJob]);

    const validateForm = () => {
        //  Validate form fields, return false if invalid

        if (formState.RunName === '') {
            setErrorMessage('Run Name is required');
            return false;
        } else if (formState.StartDate === '' || formState.EndDate === '') {
            setErrorMessage('Start and End Dates are required');
            return false;
        } else if (formState.StartDate >= formState.EndDate) {
            setErrorMessage('End date must be later than the start date.');
            return false;
        } else if (!validGeojson) {
            setErrorMessage('Invalid GeoJSON bounds');
            return false;
        } else {
            setErrorMessage('');
            return true;
        }
    }

    const check_user_jobs = () => {
        let response: any = {}

        fetch(`/api/check_user_jobs/?user_id=${user.id}`, {
            method: "GET",
        }).then((response) => response.json())
            .then((data) => {
                response = data;
            }).catch((error) => {
                console.error('Error:', error);
            })

        if (response.error) {
            console.log("Error checking user jobs")
            return false
        } else if ((response.full_run_count <= 5 || response.preview_run_count <= 10) || user.is_admin === "True") {
            return true
        } else {
            return false
        }

    }

    return (
        <div className="pageWrapper">
            {submitted ?
                (<ConfirmationTile jobID={submittedJob} setSubmitted={setSubmitted} />)
                :
                <div className="form-container-inversion">
                    <div className="form-side-panel">
                        <div className="form-content">
                            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                <h2>Submit New Inversion</h2>
                                <Grid>
                                    <GridRow style={{ marginBottom: "-10px" }}>
                                        <GridColumn width={13}>
                                            <div>
                                                <label>
                                                    Run Name
                                                    <span>
                                                        <Popup
                                                            className="darkPopup"
                                                            position="top center"
                                                            content="This is your run name. It should be unique and descriptive."
                                                            trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                                                        />
                                                    </span>
                                                </label>

                                                <input className="darkFluidInput full" type="text" name="RunName" placeholder="Enter run name..." value={formState.RunName} onChange={handleInputChange} style={{ width: "100% !important" }} />
                                            </div>

                                        </GridColumn>
                                    </GridRow>
                                    <GridRow style={{ marginBottom: "-10px" }} columns={2}>
                                        <GridColumn width={6}>

                                            <SemanticDatepicker
                                                value={formState.StartDate ? createDateFromYYYYMMDD(formState.StartDate) : null}
                                                name="StartDate"
                                                className="darkCalendarInput"
                                                label="Start Date"
                                                format="YYYY-MM-DD"
                                                minDate={new Date(2018, 0, 1)}  // January 1, 2018
                                                maxDate={new Date(2024, 4, 28)} // May 28, 2024
                                                onChange={handleDateChange}
                                                required
                                            />
                                        </GridColumn>
                                        <GridColumn width={7}>
                                            <div style={{ float: "right" }}>

                                                <SemanticDatepicker
                                                    value={formState.EndDate ? createDateFromYYYYMMDD(formState.EndDate) : null}
                                                    name="EndDate"
                                                    className="darkCalendarInput"
                                                    label="End Date"
                                                    format="YYYY-MM-DD"
                                                    minDate={new Date(2018, 0, 1)}  // January 1, 2018
                                                    maxDate={new Date(2024, 4, 30)} // May 30, 2024
                                                    onChange={handleDateChange}
                                                    required
                                                />
                                            </div>
                                        </GridColumn>
                                    </GridRow>
                                    <GridRow style={{ marginBottom: "-10px" }}>
                                        <GridColumn>

                                            {errorMessage && <Message negative compact>{errorMessage}</Message>}
                                        </GridColumn>
                                    </GridRow>

                                    <Grid.Row columns={2} >
                                        <Grid.Column width={6}>
                                            <BoundsModal
                                                globalGeojson={geojsonSubmit}
                                                setModalOpened={setModalOpened}
                                                setEstPixels={setEstPixels}
                                                setGeojsonSubmit={setGeojsonSubmit}
                                                setGeojsonAreaKM={setGeojsonAreaKM}
                                                setFormState={setFormState}
                                                formState={formState}
                                                triggerButton={
                                                    validGeojson ? (
                                                        <Button style={{ backgroundColor: "rgb(21, 129, 21)", color: "white", position: "relative" }} className="roundButton" >
                                                            <span>
                                                                <Icon name="check" style={{ marginRight: "5px" }} />
                                                            </span>
                                                            Select region
                                                            <Popup content="View selected bounds" position="top center" trigger={
                                                                <span id="viewMapButton" onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    setBoundsReviewModalOpen(true)
                                                                }} ><Icon name="map" /></span>
                                                            } />

                                                        </Button>
                                                    ) : (
                                                        <Button style={{ backgroundColor: "var(--dark-yellow)", color: "white", width: "100%" }} className="roundButton" >
                                                            <span>
                                                                <Icon name="map outline" style={{ marginRight: "5px" }} />
                                                            </span>
                                                            Select region
                                                        </Button>
                                                    )
                                                }
                                            />
                                            <BoundsReviewModal geojsonSubmit={geojsonSubmit} open={boundsReviewModalOpen} onClose={() => setBoundsReviewModalOpen(false)} />

                                        </Grid.Column>

                                        <Grid.Column width={7} >
                                            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                                <Button.Group style={{ alignSelf: "flex-end", width: "85%" }}>
                                                    <Popup
                                                        className="darkPopup"
                                                        content={<div>
                                                            <p>Run a preview to check for errors before running the full inversion process.</p>
                                                            <p><strong>This step is required currently to ensure a successful run.</strong></p>
                                                            <p>The preview will include estimations and visualizations of the IMI's performance given your selected configuration.</p>
                                                        </div>}
                                                        position="top center"
                                                        trigger={
                                                            <Button
                                                                onClick={() => setInversionMode("preview")}
                                                                className={inversionMode === "preview" ? "buttonHighlighted" : ""}
                                                                style={{
                                                                    opacity: inversionMode === "preview" ? 1 : 0.7,
                                                                    color: inversionMode === "preview" ? "inherit" : "white",
                                                                    backgroundColor: inversionMode === "preview" ? "inherit" : "rgb(99,99,99)",
                                                                    fontSize: "0.9rem",
                                                                    height: "38px",
                                                                    padding: "0.7rem",
                                                                    width: "30%"
                                                                }}
                                                            >
                                                                Preview
                                                            </Button>
                                                        }
                                                    />
                                                    <Popup
                                                        className="darkPopup"
                                                        content={<div>
                                                            <p>Full run will include all steps: setup, spinup, jacobian, inversion, and posterior run.</p>
                                                            <p><strong>This step will be available <span style={{ textDecoration: "underline" }}>after</span> the preview run.</strong></p>
                                                        </div>}
                                                        position="top center"
                                                        trigger={
                                                            <Button
                                                                // onClick={() => setInversionMode("full")}
                                                                className={inversionMode === "full" ? "buttonHighlighted" : ""}
                                                                style={{
                                                                    opacity: inversionMode === "full" ? 1 : 0.5,
                                                                    color: inversionMode === "full" ? "inherit" : "white",
                                                                    backgroundColor: inversionMode === "full" ? "inherit" : "rgb(99,99,99)",
                                                                    fontSize: "0.9rem",
                                                                    height: "38px",
                                                                    padding: "0.7rem",
                                                                    width: "30%"

                                                                }}

                                                            >
                                                                Full Run
                                                            </Button>
                                                        }
                                                    />
                                                </Button.Group>
                                            </div>
                                        </Grid.Column>

                                    </Grid.Row>



                                    <Grid.Row>
                                        <Grid.Column width={13}>

                                            <AdvancedSettingsModal handleInputChange={handleInputChange} formState={formState} setFormState={setFormState} estPixels={estPixels} setEstPixels={setEstPixels} geojsonArea={geojsonAreaKM} triggerButton={<Button type='button' className="transparentButton" style={{ marginTop: "20px" }} >Advanced Settings<span><Icon name="plus" style={{ marginLeft: "5px" }} /></span></Button>} />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                            </div>
                            <div className="form-buttons" >
                                <Button animated="fade" onClick={resetForm}>
                                    <ButtonContent visible>Reset</ButtonContent>
                                    <ButtonContent hidden>
                                        <Icon name='repeat' />
                                    </ButtonContent>
                                </Button>
                                {/* Fix this */}
                                {(errorMessage !== '' || !validGeojson || !formState.StartDate || !formState.EndDate) ? <Popup content="Please correct errors in the form" position="top center" trigger={
                                    <Button animated onClick={handleSubmitForm} style={{ backgroundColor: "var(--dark-yellow)", color: "white" }} disabled={errorMessage !== '' || !validGeojson || !formState.StartDate || !formState.EndDate}>
                                        <ButtonContent visible>Review and Submit</ButtonContent>
                                        <ButtonContent hidden>
                                            <Icon name='arrow right' />
                                        </ButtonContent>
                                    </Button>
                                } /> :
                                    <>
                                        <InversionReviewModal formState={formState} run_type={inversionMode} geojson={geojsonSubmit} geojson_area={geojsonAreaKM} submit_form={handleSubmitForm} check_job_count={check_user_jobs} open={reviewModalOpen} imiRegion={imiRegion} outOfRegion={outOfRegion} onClose={() => setReviewModalOpen(false)} />
                                        <Button animated onClick={() => setReviewModalOpen(true)} style={{ backgroundColor: "var(--dark-yellow)", color: "white" }} disabled={errorMessage !== '' || !validGeojson || !formState.StartDate || !formState.EndDate}>
                                            <ButtonContent visible>Review and Submit</ButtonContent>
                                            <ButtonContent hidden>
                                                <Icon name='arrow right' />
                                            </ButtonContent>
                                        </Button>
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="form-image-panel">
                        <div className="logo">
                            <img src="/logos/logo_light_large.png" alt="ie logo" />
                        </div>
                    </div>
                </div>
            }
        </div>


    );
}

export default InversionForm;