import React, { useContext, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

const ProtectedRoute = ({ children, requireAdmin = false }) => {
  const { user, checkToken, logout } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    // Check if the token is expired; if so, log out the user
    if (!checkToken()) {
      logout(); // Log out only if token is expired
    }
  }, [logout]);

  if (!user) {
    // Redirect to login page with current location
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  if (requireAdmin && user.is_admin == "False") {
    // Redirect to home or forbidden page if user is not an admin
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
