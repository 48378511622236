import React, { useState, useContext } from "react";
import { Button, Modal, Form, TextArea, Input } from "semantic-ui-react";
import { AuthContext } from "../../contexts/AuthContext";

const ContactModal = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    subject: "",
    body: "",
  });

  const { user } = useContext(AuthContext);

  const handleChange = (e, { name, value }) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    fetch("/api/send_support_email/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify({ ...formData, user_id: user.id }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      });

    console.log(formData);
    // onClose(); // Close modal after submission
  };

  return (
    <Modal onClose={onClose} open={open}>
      <Modal.Header>Contact Integral Earth Development Team</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input label="Subject" placeholder="Enter the subject of your message" name="subject" value={formData.subject} onChange={handleChange} />
          <Form.Field control={TextArea} label="Body" placeholder="Enter your message here" name="body" value={formData.body} onChange={handleChange} />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="yellow" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ContactModal;
