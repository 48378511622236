import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";
import { MapContainer, TileLayer, GeoJSON, useMap } from "react-leaflet";
import L from "leaflet";

// Component to handle map bounds fitting
const FitBounds = ({ geojsonSubmit }) => {
  const map = useMap();

  useEffect(() => {
    if (geojsonSubmit) {
      const bounds = L.geoJSON(geojsonSubmit).getBounds();
      map.fitBounds(bounds);
    }
  }, [geojsonSubmit, map]);

  return null;
};

const GeoJsonModal = ({ geojsonSubmit, open, onClose }) => {
  return (
    <>
      <Modal open={open} onClose={onClose} size="large">
        <Modal.Header>Selected Bounds</Modal.Header>
        <Modal.Content>
          <div style={{ height: "400px" }}>
            <MapContainer style={{ height: "100%", width: "100%" }} center={[51.505, -0.09]} zoom={5} scrollWheelZoom={true}>
              <TileLayer
                url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=e06c3b94-e393-4722-bd75-8257a760dcfb"
                attribution='&copy; <a href="https://www.stadiamaps.com/">Stadia Maps</a> contributors &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> contributors &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              {geojsonSubmit && (
                <>
                  <GeoJSON
                    data={geojsonSubmit}
                    style={{
                      color: "var(--dark-yellow)", // Polygon border color
                      fillColor: "var(--dark-yellow)", // Polygon fill color
                      fillOpacity: 0.3, // Fill opacity
                      weight: 3, // Border thickness
                    }}
                  />
                  <FitBounds geojsonSubmit={geojsonSubmit} />
                </>
              )}
            </MapContainer>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={onClose}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default GeoJsonModal;
