import React, { useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import "./ResourcesPage.css";

import ContactModal from "./ContactModal";

const ResourcesPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="pageWrapper" style={{ textAlign: "center" }}>
      <h1 style={{ textAlign: "center" }}>Resources</h1>
      <hr style={{ width: "50%" }} />
      <div className="resourcesWrapper">
        <a href="https://imi.readthedocs.io/en/latest/index.html" style={{ color: "inherit" }} target="#">
          <div className="resourceTile">
            <Icon name="mail" className="resourceIcon" circular inverted color="grey" style={{ cursor: "pointer" }} />
            <h2>Documentation</h2>
            <p>Find the latest documentation for the IMI here.</p>
          </div>
        </a>
        {/* TODO remove link to pdf and replace with actual page */}
        <a href="https://imi.readthedocs.io/en/latest/getting-started/faqs.html" style={{ color: "inherit" }} target="#">
          <div className="resourceTile">
            <Icon name="question circle" className="resourceIcon" circular inverted color="grey" style={{ cursor: "pointer" }} />
            <h2>FAQ</h2>
            <p>Get answers to frequently asked questions about Integral Earth and IMI configuration.</p>
          </div>
        </a>
        <div className="resourceTile" onClick={handleModalOpen} style={{ cursor: "pointer" }}>
          <Icon name="mail" className="resourceIcon" circular inverted color="grey" />
          <h2>Contact</h2>
          <p>Reach out to the Integral Earth team for support or to suggest new features.</p>
        </div>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdC43MYM7SZfouy1XnLo40Cf2BcAKzFm4eXZ99BDehVxJp9sA/viewform?usp=sf_link" style={{ color: "inherit" }} target="#">
          <div className="resourceTile">
            <Icon name="flask" className="resourceIcon" circular inverted color="grey" style={{ cursor: "pointer" }} />
            <h2 style={{ marginBottom: "0.2rem" }}>Submit Feedback</h2>
            <p style={{ marginBottom: 0 }}>
              If you have encounter errors or have suggestions for additional features, <strong>please</strong> submit feedback via this Google Form.
            </p>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdC43MYM7SZfouy1XnLo40Cf2BcAKzFm4eXZ99BDehVxJp9sA/viewform?usp=sf_link" target="#">
              <Button color="grey" style={{ border: "solid white 1px" }}>
                Submit Feedback
              </Button>
            </a>
          </div>
        </a>
      </div>

      {/* Render ContactModal */}
      <ContactModal open={isModalOpen} onClose={handleModalClose} />
    </div>
  );
};

export default ResourcesPage;
