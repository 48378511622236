import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, ButtonContent, Icon, Popup, Modal, Loader, Input, Checkbox, Message } from "semantic-ui-react";
import "./JobTile.css";
import { set } from "date-fns";

const JobTile = ({ job, viewBounds, cancelRun, username = "Unknown User", config_obj }) => {
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [configReviewed, setConfigReviewed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [runSubmittedSuccessfully, setRunSubmittedSuccessfully] = useState(false);
  const [runSubmitError, setRunSubmitError] = useState(false);
  const [runDeleteError, setRunDeleteError] = useState(false);
  const [runDeleteSuccess, setRunDeleteSuccess] = useState(false);

  // Editing state variables
  const [isEditing, setIsEditing] = useState({});
  const [hasEdited, setHasEdited] = useState(false);
  const [editedConfig, setEditedConfig] = useState(config_obj || {});

  const handleEditClick = (key) => {
    setHasEdited(true);
    setIsEditing((prevState) => ({
      ...prevState,
      [key]: true,
    }));
  };

  const handleInputChange = (key, value) => {
    setEditedConfig((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleToggleChange = (key, value) => {
    setEditedConfig((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSave = () => {
    console.log("Saving edited config:", editedConfig);
    fetch(`/api/update/?table=config&id=${config_obj.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify(editedConfig),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Updated config:", data);
        setIsConfigModalOpen(false);
      })
      .catch((error) => console.error("Error updating config:", error));
  };

  const handleConfigReview = () => {
    setConfigReviewed(true);
    setIsConfigModalOpen(true);
  };

  const fetchErrorLog = async () => {
    // Fetch error log
  };

  const cancelRunRoute = async () => {
    setIsLoading(true);

    try {
      fetch(`/api/cancel_execution/?jobID=${job.id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
    } catch (error) {
      console.error("Error cancelling run:", error);
    }
  };

  const submitRunPreview = async () => {
    setIsLoading(true);

    try {
      fetch(`/api/submit_run_master/?configID=${config_obj.id}&jobID=${job.id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Submit run response:", data);
          if (data.success_message) {
            console.log("Run submitted successfully");
            setRunSubmittedSuccessfully(true);
            setIsReviewModalOpen(false);
            window.location.reload();
          } else {
            console.log("Run submit error!");
            setRunSubmittedSuccessfully(false);
            setRunSubmitError(true);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error submitting run:", error);
        });
    } catch (error) {
      console.error("Error submitting run:", error);
    }
  };

  const submitRunFull = async () => {
    setIsLoading(true);
    console.log("Submitting run full...");
    // return;

    try {
      fetch(`/api/submit_full_run_from_preview/?configID=${config_obj.id}&jobID=${job.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Submit run response:", data);
          if (data.success_message) {
            console.log("Run submitted successfully");
            setRunSubmittedSuccessfully(true);
            setIsReviewModalOpen(false);
            setIsLoading(false);
            window.location.reload();
          } else {
            console.log("Run submit error!");
            setRunSubmittedSuccessfully(false);
            setRunSubmitError(true);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error submitting run:", error);
          setRunSubmitError(true);
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error submitting run:", error);
      setRunSubmitError(true);
      setIsLoading(false);
    }
  };

  const deleteRun = async () => {
    setIsLoading(true);

    fetch(`/api/delete/?table=job&id=${job.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json(); // Parse the success response as JSON
        } else {
          return response.json().then((errorData) => {
            throw new Error(errorData.error); // Throw an error with the error message
          });
        }
      })
      .then((data) => {
        setIsLoading(false);
        console.log(data.message); // Log success message
        setRunDeleteSuccess(true);
        setIsDeleteModalOpen(false);
        window.location.reload();
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error deleting job:", error.message);
        setRunDeleteError(error.message || "An error occurred while deleting the job. Please try again.");
        setRunDeleteSuccess(false);
      });
  };

  return (
    <div className="job-tile-container">
      {job && job.run_stage === "FULL" && (
        <a href={`/dashboardPreview/${job.id}`} style={{ color: "inherit", zIndex: "0" }}>
          <div className="job-tile-background" style={{ zIndex: "0" }}>
            <Popup
              className="darkPopup"
              content="View preview dashboard"
              trigger={<Icon name="eye" color="white" size="large" style={{ transform: "scale(1.5)", opacity: "0.5" }} />}
            />
          </div>
        </a>
      )}
      <div className={job.status === "ERROR" ? "info-tile error-glow" : "info-tile green-glow"}>
        <div id="typeIcon">{job.config_obj.DoInversion ? <Icon name="globe" color="grey" /> : <Icon name="eye" color="grey" />}</div>
        <div className="tile-header">{job.config_obj.RunName}</div>
        <p style={{ marginBottom: "0.5rem", fontSize: "0.95rem" }}>
          User: {config_obj.user.username} // {config_obj.user.id}
        </p>
        <div className="tile-config-id">
          <p>
            Config ID: {job.config_obj.id} // Job ID: {job.id}
          </p>
        </div>
        {username !== "Unknown User" && <div className="tile-user">User: {job.config_obj.user}</div>}
        <div className="tile-status">
          Status:{" "}
          <span className={["STARTED", "RUNNING", "COMPLETED", "SUBMITTED"].includes(job.status) ? "status-running" : "status-error"}>
            {job.status}
            <span style={{ marginLeft: "5px" }}>
              <Icon name="circle" color={["STARTED", "RUNNING", "COMPLETED", "SUBMITTED"].includes(job.status) ? "green" : "red"} size="small" />
            </span>
          </span>
        </div>
        <div className="tile-details">
          <p>
            ROI Area: <span className="roi-area">{Math.round(job.config_obj.roiArea)}km²</span>
          </p>
        </div>
        <div className="tile-actions">
          {/* <button className="view-bounds-btn" onClick={viewBounds}>
          <Icon name="map" />
        </button> */}
          <Popup
            position="top center"
            className="darkPopup"
            content="View region of interest"
            trigger={<Icon name="map" onClick={viewBounds} circular color="grey" inverted style={{ cursor: "pointer" }} />}
          />
          <Popup
            position="top center"
            className="darkPopup"
            content="View configuration settings"
            trigger={<Icon name="settings" onClick={handleConfigReview} circular color="grey" inverted style={{ cursor: "pointer" }} />}
          />

          <Popup
            position="top center"
            className="darkPopup"
            content="Delete Job"
            trigger={<Icon name="trash alternate" onClick={() => setIsDeleteModalOpen(true)} circular color="grey" inverted style={{ cursor: "pointer" }} />}
          />

          {job.status === "RUNNING" && (
            <Popup
              content="Cancel Run"
              position="top center"
              trigger={<Icon name="cancel" circular color="red" inverted style={{ cursor: "pointer" }} onClick={cancelRunRoute} />}
            />
          )}

          {job.status === "ERROR" && (
            <button className="error-btn" onClick={fetchErrorLog}>
              Fetch Error Log
            </button>
          )}

          {job.status === "COMPLETED" && (
            // check config object to determine which dashboard to link to
            <Link to={`/${!config_obj.DoInversion ? "dashboardPreview" : "dashboardFull"}/${job.id}`}>
              <button className="view-data-btn">View Data</button>
            </Link>
          )}

          {/* Set button as disabled if config has not been reviewed */}
          {job.status === "SUBMITTED" && !configReviewed && (
            <Popup
              content="Please review the configuration settings before approving the run."
              trigger={
                <div>
                  <Button disabled style={{ pointerEvents: "none", opacity: 0.5 }}>
                    Approve + Start
                  </Button>
                </div>
              }
            />
          )}

          {job.status === "SUBMITTED" && configReviewed && (
            <button className="approve-run-btn" onClick={() => setIsReviewModalOpen(true)}>
              Approve + Start
            </button>
          )}
        </div>
        {/* CONFIG MODAL */}
        <Modal open={isConfigModalOpen} onClose={() => setIsConfigModalOpen(false)} size="small">
          <Modal.Header>Configuration Details</Modal.Header>
          <Modal.Content scrolling>
            {config_obj ? (
              <div>
                <p>
                  User: {config_obj.user.username} // {config_obj.user.id}
                </p>
                {Object.entries(editedConfig).map(
                  ([key, value]) =>
                    key !== "user" && (
                      <p key={key}>
                        <strong>{key}:</strong>{" "}
                        {isEditing[key] ? (
                          typeof value === "boolean" ? (
                            <Checkbox checked={value} onChange={(e, data) => handleInputChange(key, data.checked)} />
                          ) : (
                            <Input value={value} onChange={(e) => handleInputChange(key, e.target.value)} autoFocus />
                          )
                        ) : (
                          <span style={{ color: "var(--dark-yellow)" }}>{typeof value === "boolean" ? value.toString() : value}</span>
                        )}
                        <Icon name="pencil" style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => handleEditClick(key)} />
                      </p>
                    )
                )}
              </div>
            ) : (
              <p>Loading...</p>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setIsConfigModalOpen(false)}>Close</Button>
            <Button color="green" onClick={handleSave} disabled={!hasEdited}>
              Save
            </Button>
          </Modal.Actions>
        </Modal>

        {/* REVIEW MODAL */}
        <Modal open={isReviewModalOpen} onClose={() => setIsReviewModalOpen(false)} size="small">
          <Modal.Header>Run Submit Confirmation</Modal.Header>
          <Modal.Content>
            {!isLoading ? <p>Are you sure you want to submit this run?</p> : <Loader active inline />}

            {runSubmitError && <Message negative>There was an error submitting the run. Please try again.</Message>}
          </Modal.Content>
          {!isLoading && (
            <Modal.Actions style={{ textAlign: "left" }}>
              <Button onClick={job.run_stage === "PREVIEW" ? submitRunPreview : submitRunFull} animated="fade" color="green">
                <ButtonContent visible>Submit</ButtonContent>
                <ButtonContent hidden>{isLoading ? <Loader active inline size="tiny" /> : <Icon name="check" />}</ButtonContent>
              </Button>
              <Button onClick={() => setIsReviewModalOpen(false)} animated="fade" color="red">
                <ButtonContent visible>Cancel</ButtonContent>
                <ButtonContent hidden>
                  <Icon name="close" />
                </ButtonContent>
              </Button>
              {/* <Button onClick={() => setIsReviewModalOpen(false)}>Test Close</Button> */}
            </Modal.Actions>
          )}
        </Modal>

        {/* Delete Review Modal */}
        <Modal open={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)} size="small">
          <Modal.Header>Delete Job Confirmation</Modal.Header>
          <Modal.Content>
            {!isLoading ? (
              <>
                <p>Are you sure you want to delete this job?</p>
                <p>
                  <strong>Note:</strong> This will only delete the Job object, the associated Config and Shapefile objects will remain.
                </p>
              </>
            ) : (
              <Loader active inline />
            )}

            {runDeleteError && <Message negative>There was an error deleting the job. Please try again.</Message>}
          </Modal.Content>
          {!isLoading && (
            <Modal.Actions style={{ textAlign: "left" }}>
              <Button onClick={deleteRun} animated="fade" color="red">
                <ButtonContent visible>Delete</ButtonContent>
                <ButtonContent hidden>{isLoading ? <Loader active inline size="tiny" /> : <Icon name="trash alternate" />}</ButtonContent>
              </Button>
              <Button onClick={() => setIsReviewModalOpen(false)} animated="fade" color="grey">
                <ButtonContent visible>Cancel</ButtonContent>
                <ButtonContent hidden>
                  <Icon name="close" />
                </ButtonContent>
              </Button>
              {/* <Button onClick={() => setIsReviewModalOpen(false)}>Test Close</Button> */}
            </Modal.Actions>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default JobTile;
