import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, ButtonContent, ButtonOr, Header, List, Form, Input, Grid, Dimmer, Loader, Icon, Dropdown, Message, Popup as SemanticPopup } from "semantic-ui-react";
import { MapContainer, TileLayer, Rectangle, useMap, GeoJSON, Popup } from "react-leaflet";
import L from "leaflet"; // Import Leaflet directly for utility functions
import "leaflet/dist/leaflet.css"; // Import Leaflet CSS
import "./BoundsModal.css";
import shp from "shpjs";
import * as shapefile from "shapefile";
import JSZip from "jszip";
import { set } from "date-fns";
import area from "@turf/area";

import InteractiveBounds from "./interactiveBounds";

const BoundsModal = ({ triggerButton, setFormState, formState, setGeojsonSubmit, setGeojsonAreaKM, setEstPixels, setModalOpened, globalGeojson }) => {
  //GENERAL
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [showMap, setShowMap] = useState(false);
  const inputField = useRef(null);
  const [geoJSONBounds, setGeoJSONBounds] = useState(null);
  const [validShapeInput, setValidShapeInput] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [warning, setWarning] = useState(false);

  let pixelArea = formState.Res === "0.25x0.3125" ? 25 * 30 : 55 * 60;

  //INTERACTIVE
  const [geojsonInteractive, setGeojsonInteractive] = useState(null);
  const [interactiveAreaKM, setInteractiveAreaKM] = useState(0.0);

  //LATLONG
  const [bounds, setBounds] = useState({ latMin: "", latMax: "", lonMin: "", lonMax: "" });
  const [geojsonLatLong, setGeojsonLatLong] = useState(null);
  const [latLongPolyAreaKM, setLatLongPolyAreaKM] = useState(0);

  //COUNTRY DROPDOWN
  const countryDropdownRef = useRef(null);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countryGeojson, setCountryGeojson] = useState(null);
  const [countryAreaKM, setCountryAreaKM] = useState(0);

  //SHAPEFILE
  const [shapefileGeojson, setShapefileGeojson] = useState(null);
  const [shapefileAreaKM, setShapefileAreaKM] = useState(0);

  // IMI REGIONS
  const [imiRegions, setImiRegions] = useState([]);
  const [filteredRegions, setFilteredRegions] = useState([]);
  const [viewIMIRegions, setViewIMIRegions] = useState(false);

  // List of countries that are not available via the geopandas natural earth dataset
  const unavailableCountries = [
    "ABW",
    "AIA",
    "ALA",
    "AND",
    "ASM",
    "ATG",
    "BES",
    "BHR",
    "BLM",
    "BMU",
    "BRB",
    "BVT",
    "CCK",
    "COK",
    "COM",
    "CPV",
    "CUW",
    "CXR",
    "CYM",
    "DMA",
    "FRO",
    "FSM",
    "GGY",
    "GIB",
    "GLP",
    "GRD",
    "GUF",
    "GUM",
    "HKG",
    "HMD",
    "IMN",
    "IOT",
    "JEY",
    "KIR",
    "KNA",
    "LCA",
    "LIE",
    "MAC",
    "MAF",
    "MCO",
    "MDV",
    "MHL",
    "MLT",
    "MNP",
    "MSR",
    "MTQ",
    "MUS",
    "MYT",
    "NFK",
    "NIU",
    "NRU",
    "PCN",
    "PLW",
    "PYF",
    "REU",
    "SGP",
    "SGS",
    "SHN",
    "SJM",
    "SMR",
    "SPM",
    "STP",
    "SXM",
    "SYC",
    "TCA",
    "TKL",
    "TON",
    "TUV",
    "UMI",
    "VAT",
    "VCT",
    "VGB",
    "VIR",
    "WLF",
    "WSM",
  ];

  useEffect(() => {
    setModalOpened(true);
  }, [open]);

  const handleBoundsChange = (event) => {
    const { name, value } = event.target;

    const numValue = parseFloat(value);
    if (!isNaN(numValue) && (numValue < -180 || numValue > 180)) {
      setWarning(true);
    } else {
      setWarning(false);
    }
    // Allow empty string (user might be in the process of typing)
    if (value === "" || value === "-" || value === "." || value === "-.") {
      setBounds({ ...bounds, [name]: value });
    } else if (!isNaN(value)) {
      // Update state only if the value is a valid number
      setBounds({ ...bounds, [name]: parseFloat(value) });
    }
  };

  // Fetch IMI regions
  useEffect(() => {
    fetch("/api/return_imi_regions_geo/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setImiRegions(data);

        // Filter the regions based on selected resolution
        const filtered = {
          ...data,
          features: data.features.filter((feature) => feature.properties.Resolution.replace(/ /g, "") === formState.Res),
        };
        setFilteredRegions(filtered); // Store filtered regions in state
      })
      .catch((error) => {
        console.error("Error fetching IMI regions:", error);
      });
  }, [formState.Res]); // Re-run when selectedResolution changes

  // Function to handle each GeoJSON feature and bind a popup with the Resolution property
  const onEachFeature = (feature, layer) => {
    if (feature.properties && feature.properties.Resolution) {
      const popupContent = `
        <strong>Region:</strong> ${feature.properties.regionName}<br/>
        <strong>Resolution:</strong> ${feature.properties.Resolution}
      `;
      layer.bindPopup(popupContent);
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        //TODO: Change this for a more permanent solution
        const response = await fetch("https://gist.githubusercontent.com/bensquire/1ba2037079b69e38bb0d6aea4c4a0229/raw");
        const data = await response.json();

        // Filter out countries that are not available in the geopandas natural earth dataset
        const availableCountries = data.filter((country) => !unavailableCountries.includes(country.code));
        setCountries(availableCountries);
      } catch (error) {
        console.error("Error fetching the Gist JSON:", error);
      }
    };

    fetchCountries();
  }, []);

  // setup object to hold Dropdown country data
  const countryOptions = countries.map((country) => ({
    key: country.code,
    text: country.name,
    value: country.code,
  }));

  const handleCountryChange = (e, { value }) => {
    setSelectedCountry(value);
    console.log(value);

    if (countryDropdownRef.current) {
      countryDropdownRef.current.close();
    }

    handleCountryVisualize(value);
  };

  const handleCountryVisualize = (country = selectedCountry) => {
    fetch(`/api/get_country_geojson/?alpha3=${country}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setCountryGeojson(data);
        setCountryAreaKM(area(data) / 1e6);

        const sampleLayer = L.geoJSON(data);
        setGeoJSONBounds(sampleLayer.getBounds());
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
    // setShowMap(true);
  };

  // Custom hook to fit bounds
  const FitBounds = ({ bounds }) => {
    const map = useMap();

    useEffect(() => {
      if (bounds) {
        map.fitBounds(bounds);
      }
    }, [bounds, map]);

    return null;
  };

  const convertShapefile = async () => {
    setIsLoading(true);
    const formData = new FormData();
    const files = inputField.current.inputRef.current.files;

    // Check if all required files are selected
    if (files.length === 4) {
      const shpFile = Array.from(files).find((file) => file.name.endsWith(".shp"));
      const shxFile = Array.from(files).find((file) => file.name.endsWith(".shx"));
      const dbfFile = Array.from(files).find((file) => file.name.endsWith(".dbf"));
      const prjFile = Array.from(files).find((file) => file.name.endsWith(".prj"));

      formData.append("shpFile", shpFile);
      formData.append("shxFile", shxFile);
      formData.append("dbfFile", dbfFile);
      formData.append("prjFile", prjFile);

      // convert shapefile to geojson in views.py, set geojson state and bounds state for FitBounds custom hook
      fetch(`/api/convert_shapefile_to_geojson/`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          setShapefileGeojson(data);
          setShapefileAreaKM(area(data) / 1e6);

          const sampleLayer = L.geoJSON(data);
          setGeoJSONBounds(sampleLayer.getBounds());
          setIsLoading(false);
          return data;
        })
        .catch((error) => {
          // Handle any errors here
          console.error(error);
        });
      return;
    }
  };

  async function isPolygonShapefile(file) {
    try {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      await new Promise((resolve) => {
        reader.onloadend = resolve;
      });

      const arrayBuffer = reader.result;
      const shp = await shapefile.read(arrayBuffer);

      for (let feature of shp.features) {
        if (feature.geometry.type !== "Polygon" && feature.geometry.type !== "MultiPolygon") {
          return false;
        }
      }

      return true;
    } catch (error) {
      console.error("Error reading shapefile:", error);
      return false;
    }
  }

  const handleVisualize = () => {
    // Show the map when visualize is clicked
    setShowMap(true);
  };

  const setGeojsonLatLongDynamic = (e = null, type, callback = null) => {
    if (type === "drawn") {
      const layer = e.layer;
      const latLngs = layer.getLatLngs()[0];
      const coordinates = latLngs.map((latLng) => [latLng.lng, latLng.lat]);

      const geojson = layer.toGeoJSON();
      setGeojsonLatLong(geojson);
    } else if (type === "latlong") {
      if (bounds.latMin && bounds.latMax && bounds.lonMin && bounds.lonMax) {
        const geojson = {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [bounds.lonMin, bounds.latMin], // Bottom-left corner
                [bounds.lonMax, bounds.latMin], // Bottom-right corner
                [bounds.lonMax, bounds.latMax], // Top-right corner
                [bounds.lonMin, bounds.latMax], // Top-left corner
                [bounds.lonMin, bounds.latMin], // Closing the polygon by returning to the start
              ],
            ],
          },
          properties: {},
        };

        setGeojsonLatLong(geojson, callback);
      }
    }
  };

  useEffect(() => {
    if (geojsonLatLong) {
      setShowMap(true);
    }
  }, [geojsonLatLong]);

  useEffect(() => {
    if (bounds.latMin && bounds.latMax && bounds.lonMin && bounds.lonMax) {
      const geojson = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [bounds.lonMin, bounds.latMin], // Bottom-left corner
              [bounds.lonMax, bounds.latMin], // Bottom-right corner
              [bounds.lonMax, bounds.latMax], // Top-right corner
              [bounds.lonMin, bounds.latMax], // Top-left corner
              [bounds.lonMin, bounds.latMin], // Closing the polygon by returning to the start
            ],
          ],
        },
        properties: {},
      };
      setFormState({ ...formState, LatMin: bounds.latMin, LatMax: bounds.latMax, LonMin: bounds.lonMin, LonMax: bounds.lonMax });
      setGeojsonLatLong(geojson);

      const areaPolygon = area(geojson) / 1e6;
      setLatLongPolyAreaKM(areaPolygon);
    }
  }, [bounds]);

  const handleLatLongSubmit = () => {
    setGeojsonSubmit(geojsonLatLong);
    setGeojsonAreaKM(latLongPolyAreaKM);
    setEstPixels(Math.floor(latLongPolyAreaKM / pixelArea));
    setOpen(false);
  };

  const handleCountrySubmit = () => {
    setGeojsonSubmit(countryGeojson);
    setGeojsonAreaKM(area(countryGeojson) / 1e6);
    console.log("Shapefile area:", countryAreaKM);
    console.log("Pixel area:", pixelArea);
    console.log("Est pixels: ", countryAreaKM / pixelArea);
    setEstPixels(Math.ceil(countryAreaKM / pixelArea));
    setOpen(false);
  };

  const handleShpFileSubmit = () => {
    setGeojsonSubmit(shapefileGeojson);
    setGeojsonAreaKM(area(shapefileGeojson) / 1e6);
    setEstPixels(Math.ceil(shapefileAreaKM / pixelArea));
    setOpen(false);
  };

  useEffect(() => {
    setOpen(false);
  }, [geojsonInteractive]);

  const renderMap = () => {
    // Check if the map should be rendered
    if (!showMap) return null;

    // Check if bounds are valid numbers
    const { latMin, latMax, lonMin, lonMax } = bounds;
    const validBounds = [latMin, latMax, lonMin, lonMax].every((coord) => coord !== null && !isNaN(coord));

    if (validBounds) {
      const boundsCoordinates = [
        [latMin, lonMin],
        [latMax, lonMax],
      ];

      const numberOfPixels = Math.floor(latLongPolyAreaKM / pixelArea); // Number of pixels that fit in the area

      return (
        <MapContainer center={[(latMin + latMax) / 2, (lonMin + lonMax) / 2]} zoom={5} style={{ height: "400px", width: "100%", borderRadius: "10px", border: "solid white 2px" }}>
          <TileLayer
            url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=e06c3b94-e393-4722-bd75-8257a760dcfb"
            attribution='&copy; <a href="https://www.stadiamaps.com/">Stadia Maps</a> contributors &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> contributors &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Rectangle bounds={boundsCoordinates} pathOptions={{ color: "var(--dark-yellow)" }}>
            <Popup>
              <div>
                <strong>Selected Area:</strong>
                <br />
                <p>{latLongPolyAreaKM.toFixed(2)} square kilometers</p>
                <strong>Number of 25x25 km pixels:</strong>
                <br />
                <p>{numberOfPixels}</p>
              </div>
            </Popup>
          </Rectangle>
          {filteredRegions && viewIMIRegions && (
            <GeoJSON
              data={filteredRegions}
              style={{
                color: "var(--dark-yellow)", // Polygon border color
                fillColor: "var(--dark-yellow)", // Polygon fill color
                fillOpacity: 0.05, // Fill opacity
                weight: 1, // Border thickness
                zIndex: 1,
              }}
              onEachFeature={onEachFeature}
            />
          )}
        </MapContainer>
      );
    }

    return null;
  };

  const validateFiles = (e) => {
    if (e.target.files.length >= 3) {
      setValidShapeInput(true);
      return;
    }
  };

  const validLatLong = () => {
    return !(
      [bounds.latMin, bounds.latMax, bounds.lonMin, bounds.lonMax].includes("") ||
      bounds.latMin > 90 ||
      bounds.latMin < -90 ||
      bounds.latMax > 90 ||
      bounds.latMax < -90 ||
      bounds.lonMin > 180 ||
      bounds.lonMin < -180 ||
      bounds.lonMax > 180 ||
      bounds.lonMax < -180
    );
  };

  useEffect(() => {
    if (geojsonInteractive) {
      console.log("Setting geojsonInteractive", geojsonInteractive);
      setGeojsonSubmit(geojsonInteractive);
      setGeojsonAreaKM(interactiveAreaKM);
      setOpen(false);
    }
  }, [geojsonInteractive]);

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <List selection>
            <List.Item onClick={() => setStep(3)}>
              <div className="boundsModalItem">
                <strong>Submit Region of Interest Interactively</strong>
              </div>
            </List.Item>
            <List.Item onClick={() => setStep(2)}>
              <div className="boundsModalItem">
                <strong>Submit Region of Interest Using Latitude/Longitude</strong>
              </div>
            </List.Item>
            <List.Item onClick={() => setStep(5)}>
              <div className="boundsModalItem">
                <strong>Choose from Country List</strong>
              </div>
            </List.Item>
            <List.Item onClick={() => setStep(4)}>
              <div className="boundsModalItem">
                <strong>Upload Shapefile</strong>
              </div>
            </List.Item>
          </List>
        );
      case 2:
        return (
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column width={4}>
                <Form>
                  <Form.Field>
                    <label>Latitude Min</label>
                    <input type="number" className="darkFluidInput" name="latMin" onChange={(e) => handleBoundsChange(e)} value={bounds.latMin} />
                    {/* <Input placeholder="Enter Latitude Min" name="latMin" onChange={(e) => handleBoundsChange(e)} type="number" value={bounds.latMin} style = {{backgroundColor: "grey !important", color: "white"}}/> */}
                  </Form.Field>

                  <Form.Field>
                    <label>Latitude Max</label>
                    <input type="number" className="darkFluidInput" name="latMax" onChange={(e) => handleBoundsChange(e)} value={bounds.latMax} />
                    {/* <Input placeholder="Enter Latitude Max" name="latMax" onChange={(e) => handleBoundsChange(e)} type="number" value={bounds.latMax} /> */}
                  </Form.Field>
                  <Form.Field>
                    <label>Longitude Min</label>
                    <input type="number" className="darkFluidInput" name="lonMin" onChange={(e) => handleBoundsChange(e)} value={bounds.lonMin} />
                    {/* <Input placeholder="Enter Longitude Min" name="lonMin" onChange={(e) => handleBoundsChange(e)} type="number" value={bounds.lonMin} /> */}
                  </Form.Field>
                  <Form.Field>
                    <label>Longitude Max</label>
                    <input type="number" className="darkFluidInput" name="lonMax" onChange={(e) => handleBoundsChange(e)} value={bounds.lonMax} />
                    {/* <Input placeholder="Enter Longitude Max" name="lonMax" onChange={(e) => handleBoundsChange(e)} type="number" value={bounds.lonMax} /> */}
                  </Form.Field>
                  <div style={{ display: "flex", flexDirection: "column", gap: "1.1rem" }}>
                    {/* <Button onClick={handleVisualize} color="yellow" disabled={!validLatLong()}>
                      Visualize
                    </Button> */}
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button animated onClick={() => setStep(1)}>
                        <ButtonContent visible>Back</ButtonContent>
                        <ButtonContent hidden>
                          <Icon name="arrow left" />
                        </ButtonContent>
                      </Button>
                      <Button onClick={handleLatLongSubmit} color="blue" style={{ marginLeft: "1.3rem" }} disabled={!validLatLong() || latLongPolyAreaKM < 10000}>
                        Submit
                      </Button>
                    </div>
                    <SemanticPopup
                      content="View IMI meteorological regions. ROIs submitted outside of these regions will use global meteological fields, which will decrease run efficiency."
                      className="darkPopup"
                      trigger={
                        <Icon
                          id="viewRegions"
                          style={{
                            transform: "scale(1.5)",
                            marginLeft: "1.5rem",
                            backgroundColor: viewIMIRegions ? "var(--dark-yellow) !important" : "var(--neutral-grey) !important",
                          }}
                          name="map"
                          circular
                          inverted
                          color="grey"
                          onClick={() => setViewIMIRegions(!viewIMIRegions)}
                        />
                      }
                    />
                    {bounds.latMin > 90 || bounds.latMin < -90 || bounds.latMax > 90 || bounds.latMax < -90 ? <Message error>Latitude must be between -90 and 90</Message> : null}
                    {bounds.lonMin > 180 || bounds.lonMin < -180 || bounds.lonMax > 180 || bounds.lonMax < -180 ? (
                      <Message error>Longitude must be between -180 and 180</Message>
                    ) : null}
                  </div>
                </Form>
              </Grid.Column>
              <Grid.Column width={12}>{renderMap()}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={6}>
                {validLatLong() && latLongPolyAreaKM > 0 ? (
                  <div style={{ marginLeft: "1.5rem" }}>
                    <strong>Selected Area (km²)</strong>
                    <br />
                    <p style={{ color: "var(--dark-yellow)" }}>{latLongPolyAreaKM.toFixed(0)}</p>
                    <strong>Approx. # of {formState.Res === "0.25x0.3125" ? "25km²" : "55km²"} Emission Grid Cells in Inversion</strong>
                    <br />
                    <p style={{ color: "var(--dark-yellow)" }}>{Math.floor(latLongPolyAreaKM / pixelArea)}</p>
                  </div>
                ) : null}
              </Grid.Column>
              <Grid.Column>
                {latLongPolyAreaKM < 10000 && latLongPolyAreaKM !== 0 && (
                  <Message warning style={{ float: "right" }}>
                    <Message.Header>Warning</Message.Header>
                    <p>Area is less than 10,000 km². Please ensure that the area is large enough for inversion.</p>
                  </Message>
                )}
                {latLongPolyAreaKM > 3000000 && latLongPolyAreaKM !== 0 && (
                  <Message warning style={{ float: "right" }}>
                    <Message.Header>Warning</Message.Header>
                    <p>Area is more than 3,000,000 km². Please decrease region of interest size.</p>
                  </Message>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        );
      case 3:
        return (
          <div>
            <InteractiveBounds
              backButton={() => setStep(1)}
              submitButton={(e) => console.log(e)}
              setEstPixels={setEstPixels}
              setArea={setInteractiveAreaKM}
              setGeo={setGeojsonInteractive}
              globalGeojson={globalGeojson}
              resolution={formState.Res}
            />
          </div>
        );
      case 4:
        return (
          <Form>
            <Form.Field>
              <label>Upload Shapefile (.shp, .shx, .dbf, .prj)</label>
              <Input type="file" ref={inputField} accept=".shp,.shx,.dbf,.prj,.zip" multiple onChange={validateFiles} />
            </Form.Field>
            {/* {isLoading && <Loader>...Loading</Loader>} */}
            {shapefileGeojson && (
              <>
                <h3>Preview</h3>
                <div>
                  <Icon name="warning sign" color="yellow" />
                  Multipolygons will be merged on submission
                </div>
                <MapContainer style={{ height: "500px", width: "100%" }} center={[0, 0]} zoom={7}>
                  <TileLayer
                    url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=e06c3b94-e393-4722-bd75-8257a760dcfb"
                    attribution='&copy; <a href="https://www.stadiamaps.com/">Stadia Maps</a> contributors &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> contributors &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  {shapefileGeojson && (
                    <GeoJSON
                      data={shapefileGeojson}
                      style={{
                        color: "var(--dark-yellow)", // Polygon border color
                        fillColor: "var(--dark-yellow)", // Polygon fill color
                        fillOpacity: 0.3, // Fill opacity
                        weight: 3, // Border thickness
                      }}
                    />
                  )}
                  {geoJSONBounds && <FitBounds bounds={geoJSONBounds} />}
                  {filteredRegions && viewIMIRegions && (
                    <GeoJSON
                      data={filteredRegions}
                      style={{
                        color: "var(--dark-yellow)", // Polygon border color
                        fillColor: "var(--dark-yellow)", // Polygon fill color
                        fillOpacity: 0.05, // Fill opacity
                        weight: 1, // Border thickness
                        zIndex: 1,
                      }}
                      onEachFeature={onEachFeature}
                    />
                  )}
                </MapContainer>
              </>
            )}
            <div>
              {shapefileAreaKM > 0 && (
                <Message color="black">
                  <Message.Header>Area</Message.Header>
                  <p>{shapefileAreaKM && shapefileAreaKM.toFixed(2)} km²</p>
                  <Message.Header>Approx. # of {formState.Res === "0.25x0.3125" ? "25km²" : "55km²"} Emission Grid Cells in Inversion</Message.Header>
                  <p>{shapefileAreaKM && Math.ceil(shapefileAreaKM / pixelArea)}</p>
                  {shapefileAreaKM < 10000 && shapefileAreaKM !== 0 && (
                    <h3 style={{ backgroundColor: "grey", padding: "10px" }}>
                      <Icon name="warning sign" color="yellow" /> Area is less than 10,000 km². Please ensure that the area is large enough for inversion.
                    </h3>
                  )}
                </Message>
              )}
              {shapefileAreaKM < 10000 && shapefileAreaKM !== 0 && (
                <Message warning>
                  <Message.Header>Warning</Message.Header>
                  <p>Area is less than 10,000 km². Please ensure that the area is large enough for inversion.</p>
                </Message>
              )}
            </div>

            <div style={{ display: "flex", gap: "10px", marginTop: "1.4rem" }}>
              <Button animated onClick={() => setStep(1)}>
                <ButtonContent visible>Back</ButtonContent>
                <ButtonContent hidden>
                  <Icon name="arrow left" />
                </ButtonContent>
              </Button>
              <Button onClick={convertShapefile} color="yellow" disabled={!validShapeInput}>
                Visualize
              </Button>
              <Button animated onClick={handleShpFileSubmit} color="blue" disabled={!shapefileGeojson}>
                <ButtonContent visible>Submit</ButtonContent>
                <ButtonContent hidden>
                  <Icon name="check" />
                </ButtonContent>
              </Button>
              <SemanticPopup
                content="View IMI meteorological regions. ROIs submitted outside of these regions will use global meteological fields, which will decrease run efficiency."
                className="darkPopup"
                trigger={
                  <Icon
                    id="viewRegions"
                    style={{ transform: "scale(1.5)", marginLeft: "1.5rem", backgroundColor: viewIMIRegions ? "var(--dark-yellow) !important" : "var(--neutral-grey) !important" }}
                    name="map"
                    circular
                    inverted
                    color="grey"
                    onClick={() => setViewIMIRegions(!viewIMIRegions)}
                  />
                }
              />
            </div>
          </Form>
        );
      case 5:
        return (
          <>
            <Dropdown
              ref={countryDropdownRef}
              placeholder="Select a Country"
              fluid
              search
              selection
              options={countryOptions}
              onChange={handleCountryChange}
              loading={countries.length === 0}
              value={selectedCountry}
              style={{ zIndex: 1000 }}
            />

            <div style={{ display: "flex", gap: "10px", margin: "1.3rem auto 1.3rem auto" }}>
              <Button animated onClick={() => setStep(1)}>
                <ButtonContent visible>Back</ButtonContent>
                <ButtonContent hidden>
                  <Icon name="arrow left" />
                </ButtonContent>
              </Button>
              <Button animated onClick={handleCountrySubmit} color="blue">
                <ButtonContent visible>Submit</ButtonContent>
                <ButtonContent hidden>
                  <Icon name="check" />
                </ButtonContent>
              </Button>
              <SemanticPopup
                content="View IMI meteorological regions. ROIs submitted outside of these regions will use global meteological fields, which will decrease run efficiency."
                className="darkPopup"
                trigger={
                  <Icon
                    id="viewRegions"
                    style={{ transform: "scale(1.5)", marginLeft: "1.5rem", backgroundColor: viewIMIRegions ? "var(--dark-yellow) !important" : "var(--neutral-grey) !important" }}
                    name="map"
                    circular
                    inverted
                    color="grey"
                    onClick={() => setViewIMIRegions(!viewIMIRegions)}
                  />
                }
              />
            </div>
            {countryAreaKM > 0 && (
              <div style={{ display: "flex", justifyContent: "left", gap: "30px", margin: "1.5rem, 1.5rem, 20px, 0" }}>
                <div>
                  <strong>Selected Area (km²)</strong>
                  <p style={{ color: "var(--dark-yellow)" }}>{countryAreaKM.toFixed(0)}</p>
                </div>
                <div>
                  <strong>Approx. # of {formState.Res === "0.25x0.3125" ? "25km²" : "55km²"} Emission Grid Cells in Inversion</strong>
                  <p style={{ color: "var(--dark-yellow)" }}>{Math.floor(countryAreaKM / pixelArea)}</p>
                </div>
              </div>
            )}
            {countryGeojson && (
              <MapContainer
                key={`${selectedCountry}-${countryGeojson ? "countryGEO" : "null"}`}
                style={{ height: "500px", width: "100%", borderRadius: "10px", border: "solid white 2px", marginTop: "20px" }}
                center={[0, 0]}
                zoom={7}
              >
                <TileLayer
                  url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=e06c3b94-e393-4722-bd75-8257a760dcfb"
                  attribution='&copy; <a href="https://www.stadiamaps.com/">Stadia Maps</a> contributors &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> contributors &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <GeoJSON
                  key={JSON.stringify(countryGeojson)}
                  data={countryGeojson}
                  style={{
                    color: "var(--dark-yellow)", // Polygon border color
                    fillColor: "var(--dark-yellow)", // Polygon fill color
                    fillOpacity: 0.3, // Fill opacity
                    weight: 3, // Border thickness
                  }}
                />
                {geoJSONBounds && <FitBounds key={selectedCountry + "2"} bounds={geoJSONBounds} />}
                {filteredRegions && viewIMIRegions && (
                  <GeoJSON
                    data={filteredRegions}
                    style={{
                      color: "var(--dark-yellow)", // Polygon border color
                      fillColor: "var(--dark-yellow)", // Polygon fill color
                      fillOpacity: 0.05, // Fill opacity
                      weight: 1, // Border thickness
                      zIndex: 1,
                    }}
                    onEachFeature={onEachFeature}
                  />
                )}
              </MapContainer>
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {React.cloneElement(triggerButton, { onClick: () => setOpen(true) })}
      <Modal open={open} onClose={() => setOpen(false)}>
        {step === 1 && <Header>Select a Method</Header>}
        {step === 2 && <Header>Submit Region of Interest Using Lat/Long</Header>}
        {step === 3 && <Header>Submit Region of Interest Interactively</Header>}
        {step === 4 && <Header>Upload Shapefile</Header>}
        {step === 5 && <Header>Choose from Country List</Header>}

        <Modal.Content>{renderContent()}</Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default BoundsModal;
