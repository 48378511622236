import React, { createContext, useState, useEffect } from "react";
import authService from "../services/authService";
import { jwtDecode } from "jwt-decode";


export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(authService.getCurrentUser());

  const register = async (username, organization, email, password) => {
    const response = await authService.register(username, organization, email, password);
    // console.log(response);
    return response;
  };

  const login = async (email, password) => {
    try {
      const user = await authService.login(email, password);
      if (user) {
        // slight delay for local storage to set from login fx
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setUser(authService.getCurrentUser());
        return true;
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  const logout = async () => {
    await authService.logout();
    setUser(null);
  };

  useEffect(() => {
    const fetchUser = () => {
      const currentUser = authService.getCurrentUser();
      if (currentUser) {
        setUser(currentUser);
      }
    };

    fetchUser();
  }, []);

  function formatDate(dateStr) {
    // Convert the string to a Date object

    if (dateStr === undefined) {
      return dateStr;
    }

    const year = dateStr.slice(0, 4);
    const month = dateStr.slice(4, 6) - 1; // Subtract 1 because months are 0-indexed
    const day = dateStr.slice(6, 8);

    const date = new Date(year, month, day);

    // Format the date into M, D, YYYY
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }


const checkToken = () => {
  const token = localStorage.getItem("accessToken");



  if (!token) {
    return false; // No token, treat as not logged in
  }
  
  try {
    let { exp } = jwtDecode(token);

    // This line will help test for expired tokens
    // exp = Math.floor(Date.now() / 1000) - 60;

    if (Date.now() >= exp * 1000) {
      // Token has expired, return false
      return false;
    }
    return true; // Token is still valid
  } catch (error) {
    console.error("Invalid token", error); // Logout in case of a malformed token
    return false;
  }
};

  return <AuthContext.Provider value={{ user, register, login, logout, formatDate, checkToken }}>{children}</AuthContext.Provider>;
};
