import React from "react";
import { Icon, Popup, Modal, ModalContent, ModalHeader, Image, Button } from "semantic-ui-react";
import "./ConfirmationTile.css";

const ConfirmationTile = ({ jobID, setSubmitted }) => {
  return (
    <Modal open={true} className="confirmationModal">
      <ModalContent>
        <img src="logos/logo_light_large.png" alt="Integral Earth Logo" style={{ height: "80px", width: "auto", opacity: "0.8" }} />
        <h2 style={{ marginTop: "3rem" }}>Thank you for your run submission!</h2>
        <h4 style={{ color: "rgb(200,200,200)", marginTop: "0.8rem" }}>We have emailed confirmation to your email on file.</h4>
        <h4>
          Job ID: <span style={{ color: "var(--dark-yellow)" }}>#{jobID}</span>
        </h4>
        <hr />
        <div style={{ padding: "15px", backgroundColor: "grey", borderRadius: "15px", marginTop: "1.5rem" }}>
          <h2 style={{ textAlign: "center", marginBottom: "0.6rem" }}>What's next?</h2>
          <hr style={{ width: "50%" }} />
          <p style={{ textAlign: "center", fontSize: "1.4rem" }}>
            Our team will review your inversion request and domain. After verifying appropriate configuration, they will run the IMI. Check your run display page for updates about
            job status. The Integral Earth team will email you upon successful completion of a run.
          </p>
          <div style={{ marginTop: "1.5rem", display: "flex", justifyContent: "center" }}>
            <Button onClick={() => setSubmitted(false)}>
              <Icon name="home" />
              <a href="/" style={{ color: "inherit" }}>
                Home
              </a>
            </Button>
            <Button onClick={() => setSubmitted(false)}>
              <Icon name="search" />
              <a href="/runs" style={{ color: "inherit" }}>
                View runs
              </a>
            </Button>
            {/* <Button
              onClick={() => {
                fetch(`/api/change_job_status_route/?jobID=${jobID}&new_status=SUBMITTED`, {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                  },
                })
                  .then((response) => response.json())
                  .then((data) => {
                    console.log(data);
                  });
              }}
            >
              Send fake email
            </Button> */}
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationTile;
