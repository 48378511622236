import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <a href="/">
        <img src="/logos/satellite_icon.png" className="footer-logo" alt="" />
      </a>

      <div>
        <p>
          © 2024 Integral Earth. <span style={{ color: "var(--dark-yellow)" }}>All rights reserved.</span>
        </p>
      </div>
      <span className="footer-divider"></span>
      <div className="footer-resources">
        <a href="/resources" style = {{color: "white"}}>
          <p>Resources</p>
        </a>

        <a href="/resources" style = {{color: "white"}}>
          <p>Contact</p>
        </a>

        <a href="/resources" style = {{color: "white"}}>
          <p>FAQ</p>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
