import React, { useState, useEffect } from "react";
import { Icon, Popup, Modal, Button } from "semantic-ui-react";
import "./RunTile.css";
import { Link, Navigate } from "react-router-dom";

const RunTile = ({ runName, jobID, job_obj, status, roiArea, timeRemaining, viewBounds, cancelRun, username = "Unknown User", config_obj }) => {
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);

  return (
    <div className="tile-container">
      {job_obj && job_obj.run_stage === "FULL" && (
        <a href={`/dashboardPreview/${jobID}`} style={{ color: "inherit" }}>
          <div className="tile-background">
            <Popup
              className="darkPopup"
              content="View preview dashboard"
              trigger={<Icon name="eye" color="white" size="large" style={{ transform: "scale(1.5)", opacity: "0.5" }} />}
            />
          </div>
        </a>
      )}
      <div className="info-tile">
        <div id="typeIcon">{config_obj.DoInversion ? <Icon name="globe" color="grey" /> : <Icon name="eye" color="grey" />}</div>
        <div className="tile-header">{runName}</div>
        {username !== "Unknown User" && <div className="tile-user">User: {username}</div>}
        <div className="tile-status">
          Status:{" "}
          <span className={["RUNNING", "STARTED", "COMPLETED", "SUBMITTED"].includes(status) ? "status-running" : "status-error"}>
            {status}
            <span style={{ marginLeft: "5px" }}>
              <Icon name="circle" color={["RUNNING", "STARTED", "COMPLETED", "SUBMITTED"].includes(status) ? "green" : "red"} size="small" />
            </span>
          </span>
        </div>
        <div className="tile-details">
          <p>
            ROI Area: <span className="roi-area">{Number(roiArea).toFixed(2)}km²</span>
          </p>
        </div>
        <div className="tile-actions">
          <Popup
            position="top center"
            content="View region of interest"
            trigger={<Icon name="map" onClick={viewBounds} circular color="grey" inverted style={{ cursor: "pointer" }} />}
          />
          <Popup
            position="top center"
            content="View configuration settings"
            trigger={<Icon name="settings" circular color="grey" inverted style={{ cursor: "pointer" }} onClick={() => setIsConfigModalOpen(true)} />}
          />
          {status === "RUNNING" && (
            <>
              {/* <button className="cancel-run-btn" onClick={cancelRun}>
              Cancel Run
            </button> */}
              <Popup
                className="darkPopup"
                content="Cancel run processing"
                trigger={<Icon name="close" color="red" circular inverted style={{ cursor: "pointer", opacity: "0.8" }} onClick={cancelRun} />}
              />
            </>
          )}
          {status === "COMPLETED" && (
            // check config object to determine which dashboard to link to
            <Link to={`/${!config_obj.DoInversion ? "dashboardPreview" : "dashboardFull"}/${jobID}`}>
              <button className="view-data-btn">View Data</button>
            </Link>
          )}
        </div>
        <Modal open={isConfigModalOpen} onClose={() => setIsConfigModalOpen(false)} size="small">
          <Modal.Header>Configuration Details</Modal.Header>
          <Modal.Content scrolling>
            {config_obj ? (
              <div>
                {Object.entries(config_obj).map(([key, value]) => (
                  <p key={key}>
                    <strong>{key}:</strong>{" "}
                    {typeof value === "boolean" ? (
                      <span style={{ color: "var(--dark-yellow)" }}>{value.toString()}</span>
                    ) : (
                      <span style={{ color: "var(--dark-yellow)" }}>{value}</span>
                    )}
                  </p>
                ))}
              </div>
            ) : (
              <p>Loading...</p>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setIsConfigModalOpen(false)}>Close</Button>
          </Modal.Actions>
        </Modal>
      </div>
    </div>
  );
};

export default RunTile;
