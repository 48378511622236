const sampleFormVars = {
  // GENERAL
  RunName: "",
  isAWS: true,
  UseSlurm: true,
  SafeMode: true,
  S3Upload: false,
  StartDate: "20180501",
  EndDate: "20180508",
  SpinupMonths: 1,
  BlendedTROPOMI: false,
  UseWaterObs: false,

  // REGION OF INTEREST
  isRegional: true,
  RegionID: "NA",
  LonMin: -105,
  LonMax: -103,
  LatMin: 31,
  LatMax: 33,

  ///////////////////////////// Kalman Filter Options
  KalmanMode: false,
  UpdateFreqDays: 7,
  NudgeFactor: 0.1,
  MakePeriodsCSV: true,
  CustomPeriodsCSV: "/path/to/periods.csv",

  ///////////////////////////// State Vector
  CreateAutomaticRectilinearStateVectorFile: true,
  nBufferClusters: 8,
  BufferDeg: 5,
  LandThreshold: 0.25,
  OffshoreEmisThreshold: 0,
  OptimizeBCs: true,
  OptimizeOH: false,
  PointSourceDatasets: ["SRON"],

  ///////////////////////////// Clustering Options
  ReducedDimensionStateVector: false,
  DynamicKFClustering: false,
  ClusteringMethod: "kmeans",
  NumberOfElements: 45,
  ForcedNativeResolutionElements: [[31.5, -104]],
  EmissionRateFilter: 2500,
  PlumeCountFilter: 50,
  GroupByCountry: false,
  StateVectorFile: "/home/ubuntu/integrated_methane_inversion/resources/statevectors/StateVector.nc",

  ///////////////////////////// Inversion
  ShapeFile: "/home/ubuntu/integrated_methane_inversion/resources/shapefiles/PermianBasin_Extent_201712.shp",
  LognormalErrors: false,
  PriorError: 0.5,
  PriorErrorBCs: 10.0,
  PriorErrorBufferElements: 0.5,
  PriorErrorOH: 0.1,
  ObsError: 15,
  Gamma: 1.0,
  PrecomputedJacobian: false,
  Res: "0.25x0.3125",
  Met: "GEOSFP",
  RunSetup: true,
  SetupTemplateRundir: true,
  SetupSpinupRun: false,
  SetupJacobianRuns: false,
  SetupInversion: false,
  SetupPosteriorRun: false,
  DoHemcoPriorEmis: true,
  DoSpinup: false,
  DoJacobian: false,
  ReDoJacobian: false,
  DoInversion: false,
  DoPosterior: false,
  DoPreview: true,
  DOFSThreshold: 0,

  ///////////////////////////// SLURM Resource Allocation
  RequestedCPUs: 32,
  RequestedMemory: 32000,
  RequestedTime: "0-24:00",
  SchedulerPartition: "debug",
  MaxSimultaneousRuns: -1,

  ///////////////////////////// Advanced GEOS-Chem Configuration
  NumJacobianTracers: 5,
  PerturbValue: 1.0,
  PerturbValueOH: 1.1,
  PerturbValueBCs: 10.0,
  HourlyCH4: true,
  PLANEFLIGHT: false,
  GOSAT: false,
  TCCON: false,
  AIRS: false,
  OutputPath: "/home/ubuntu/imi_output_dir",
  DataPath: "/home/ubuntu/ExtData",
  CondaFile: "/home/ubuntu/.bashrc",
  CondaEnv: "imi_env",
  RestartDownload: true,
  RestartFilePrefix: "/home/ubuntu/ExtData/BoundaryConditions/v2024-06/GEOSChem.BoundaryConditions.",
  BCpath: "/home/ubuntu/ExtData/BoundaryConditions",
  BCversion: "v2024-06",
  HemcoPriorEmisDryRun: true,
  SpinupDryrun: true,
  ProductionDryRun: true,
  PosteriorDryRun: true,
  BCdryrun: true,
};

export default sampleFormVars;
