import React, { useEffect, useState, useRef, useContext } from "react";
// @ts-ignore
import { AuthContext } from "../../contexts/AuthContext";
import StandardButton from "../Buttons/standardButton.jsx";
import { Link, redirect } from "react-router-dom";
import { Grid, GridRow, GridColumn, Popup, Icon, Message } from "semantic-ui-react";
import "./navBar.css";
// @ts-ignore
import LogoBrackets from "../../icons/logo_light_large.png";

interface Props {
  loggedIn: boolean;
  toggleSignIn: any;
  activePage: string;
}

const NavBar = ({ loggedIn, toggleSignIn, activePage }: Props) => {
  interface User {
    id: number;
    email: string;
    username: string;
    name?: string;
    is_admin: string;
  }

  const { user } = useContext(AuthContext) as { user: User | null };
  const { logout } = useContext(AuthContext) as { logout: () => void };

  const [isScrolledToTop, setIsScrolledToTop] = useState(true);
  const navRef = useRef<HTMLDivElement>(null);

  // Check scroll position
  useEffect(() => {
    const handleScroll = () => {
      const position: number = window.scrollY;
      setIsScrolledToTop(position === 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // useEffect(() => {
  //   if (user) {
  //     console.log(user);
  //     // window.location.reload();
  //   }
  // }, [user]);

  // Change style of navbar when scrolled to top
  useEffect(() => {
    if (navRef.current === null) return;
    if (!isScrolledToTop) {
      navRef.current.style.borderBottom = "solid 1px white";
    } else {
      navRef.current.style.borderBottom = "solid 1px transparent";
    }
  }, [isScrolledToTop]); // Style when isScrolledToTop changes

  const changeActiveLink = (thisLink: HTMLAnchorElement | null) => {
    // If no link is passed, remove all active links
    if (!thisLink) {
      const links = document.querySelectorAll(".navLink");
      links.forEach((link) => {
        link.classList.remove("activeLink");
      });
    } else {
      const links = document.querySelectorAll(".navLink");
      links.forEach((link) => {
        link.classList.remove("activeLink");
      });
      thisLink.classList.add("activeLink");
    }
  };

  useEffect(() => {
    const links = document.querySelectorAll(".navLink");
    links.forEach((link) => {
      if (link.textContent === activePage) {
        link.classList.add("activeLink");
      }
    });
  }, [activePage]);

  const handleLogout = async () => {
    await logout();
    redirect("/");
  };

  return (
    <div ref={navRef} className="navBar">
      <div className="navBarLogo">
        <Link to="/">
          {/* INCREASE */}
          <img src={LogoBrackets} alt="" style={{ height: "75px", width: "auto", paddingLeft: "2vw" }} />
        </Link>
      </div>
      {user && user.is_admin === "False" && (

        <Message className="infoMessage" positive style={{ fontSize: "0.9rem" }}>
          <Message.Header>Please pardon our Beta appearance!</Message.Header>
          Report any issues to our <a href="https://docs.google.com/forms/d/e/1FAIpQLSdC43MYM7SZfouy1XnLo40Cf2BcAKzFm4eXZ99BDehVxJp9sA/viewform?usp=sf_link">support team.</a>
        </Message>
      )}

      {user ? (
        <div className="navBarLinks">
          {/* Flashy version with brackets on hover */}
          {/* <Link className="navLink" to="/">
          <span id="bracket">&#123;</span>&#32;Home&#32;<span id="bracket">&#125;</span>
        </Link> */}

          <Link className="navLink" to="/" onClick={(e) => changeActiveLink(e.currentTarget)}>
            Home
          </Link>
          {user && user.is_admin === "False" && (
            <>
              <Link className="navLink" to="/about" onClick={(e) => changeActiveLink(e.currentTarget)}>
                About
              </Link>
            </>)
          }
          <Link className="navLink" to="/resources" onClick={(e) => changeActiveLink(e.currentTarget)}>
            Resources
          </Link>

          {user && user.is_admin === "True" && (
            <Link className="navLink" to="/admin" onClick={(e) => changeActiveLink(e.currentTarget)}>
              View User Runs
            </Link>
          )}
          <Link className="navLink" to="/runs" onClick={(e) => changeActiveLink(e.currentTarget)}>
            My Data
          </Link>
          <Link to="/submitinversion">
            <StandardButton text={"Submit Inversion +"} clickFunction={(e) => changeActiveLink(null)} color={"darkyellow"} />
          </Link>
          <Popup
            content={
              <span>
                <div>Signed in as:</div>
                <div style={{ fontWeight: "bold", marginBottom: "0.5vw" }}>{user.username}</div>
                <a href="/accountSettings">
                  <div className="userInfoDropdown">
                    <Icon name="setting" />
                    Account Settings
                  </div>
                </a>
                <div className="userInfoDropdown" onClick={handleLogout}>
                  <Icon name="sign out" />
                  Sign out
                </div>
              </span>
            }
            trigger={
              <div className="userIcon">
                {/* If the user exists and has a username, put their initials in the icon */}
                {user.username && user.username.includes(" ")
                  ? user.username.split(" ")[0][0] + user.username.split(" ")[user.username.split(" ").length - 1][0]
                  : user.username && user.username[0]}
              </div>
            }
            position="bottom center"
            hoverable

          />
          {/* <Link to="/add">
            <StandardButton clickFunction={logout} text="Sign out" color="darkyellow" />
          </Link> */}
        </div>
      ) : (
        <div className="navBarLinks">
          {/* Flashy version with brackets on hover */}
          {/* <Link className="navLink" to="/">
          <span id="bracket">&#123;</span>&#32;Home&#32;<span id="bracket">&#125;</span>
        </Link> */}

          <Link className="navLink" to="/">
            Home
          </Link>
          <Link className="navLink" to="/about">
            About
          </Link>
          <Link className="navLink" to="/pricing">
            Pricing
          </Link>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1vw", marginLeft: "1vw" }}>
            {/* Include state to tell signin component which side to start on */}
            <Link to="/signin" state={{ buttonOriginSignIn: true }}>
              <StandardButton clickFunction={(e: any) => null} text="Sign in" color="darkyellow" />
            </Link>
            <Link to="/signin" state={{ buttonOriginSignIn: false }}>
              <StandardButton clickFunction={(e: any) => null} text="Sign up" color="none" />
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBar;
