import React from 'react';

interface CheckboxProps {
    name: string;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxInteractive: React.FC<CheckboxProps> = ({ name, checked, onChange }) => {
    return (
        <div className="styled-checkbox">
            <input
                id={name}
                type="checkbox"
                className="promoted-input-checkbox"
                checked={checked}
                name={name}
                onChange={onChange}
            />
            <svg><use xlinkHref="#checkmark-28" /></svg>
            <label htmlFor={name}></label>
            <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
                <symbol id="checkmark-28" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeMiterlimit="10" fill="none" d="M22.9 3.7l-15.2 16.6-6.6-7.1"></path>
                </symbol>
            </svg>
        </div>
    );
};

export default CheckboxInteractive;