import React, { useContext, useEffect, useState } from "react";
import "./RunDisplay.css";
import { Icon, Modal, Button, Loader, Popup } from "semantic-ui-react";
import RunTile from "./RunTile";
import { AuthContext } from "../../contexts/AuthContext";
import BoundsModalRunDisplay from "./BoundsModalRunDisplay";
import { open } from "shapefile";

interface RunDisplayProps {
  // Add your props here
}

const RunDisplay: React.FC<RunDisplayProps> = () => {
  const [jobs, setJobs] = useState([]);
  const [communityJobs, setCommunityJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedShapefileID, setSelectedShapefileID] = useState("");
  const [openBoundsModal, setOpenBoundsModal] = useState(false);

  const [personalRunDisplay, setPersonalRunDisplay] = useState(true);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    setIsLoading(true);
    fetch(`/api/get_jobs_from_user/?id=${user.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setJobs(data.jobs);
      });
    setIsLoading(false);
  }, []);

  useEffect(() => {

    fetch('/api/return_all_jobs/?completed=true', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }).then((response) => response.json())
      .then((data) => {
        console.log(data);
        setCommunityJobs(data.jobs);
      })

  }, [personalRunDisplay]);

  // const handleOpenBoundsModal = (jobId) => {
  //   setOpenBoundsModals((prevState) => ({
  //     ...prevState,
  //     [jobId]: true,
  //   }));
  // };

  // const handleCloseBoundsModal = (jobId) => {
  //   setOpenBoundsModals((prevState) => ({
  //     ...prevState,
  //     [jobId]: false,
  //   }));
  // };

  const handleCloseBoundsModal = () => {
    setOpenBoundsModal(false);
    setSelectedShapefileID(""); // Clear the shapefile ID when closing the modal
  };

  // Handle opening the modal with the correct shapefile ID
  const handleViewBounds = (shapefileID: string) => {
    console.log("Viewing bounds for shapefile ID:", shapefileID);
    setSelectedShapefileID(shapefileID);
    setOpenBoundsModal(true);
  };


  return (
    <div className="pageWrapper">
      <h1 style={{ textAlign: "center", fontWeight: "300" }}>{personalRunDisplay ? "Submitted Runs" : "Community Runs"}</h1>
      <div className="centerHorizontally" style={{ gap: "1.5rem" }}>
        <Popup className="darkPopup" content={personalRunDisplay ? "See what the community's been working on!" : "View my runs"} position="bottom center" trigger={<Button id="communityButton" circular icon={personalRunDisplay ? "globe" : "folder"} inverted size="big" onClick={() => setPersonalRunDisplay(!personalRunDisplay)} />} />
        <Popup className="darkPopup" content="View sample dashboard of the Permian Basin" position="bottom center" trigger={<Button id="communityButton" circular icon="chart line" inverted size="big" href="/dashboardFull/PermianBasin" />} />
      </div>
      <hr style={{ width: "50%" }} />
      <div className="centerHorizontally" style={{ gap: "30px" }}>
        <div className="centerHorizontally centerVertical">

          <Icon name="eye" circular inverted color="grey" />
          <p>Preview</p>
        </div>
        <div className="centerHorizontally">
          <div className="centerHorizontally centerVertical">

            <Icon name="globe" circular inverted color="grey" />
            <p>Full Inversion</p>
          </div>
        </div>
      </div>

      {personalRunDisplay ? (

        <div className="tileWrapper">
          {isLoading && <Loader active inline="centered" />}
          {jobs ?
            <>
              {jobs.map((job: any) => (
                <RunTile
                  runName={job.config_obj.RunName}
                  roiArea={job.config_obj.roiArea}
                  jobID={job.id}
                  timeRemaining={23}
                  status={job.status}
                  viewBounds={() => handleViewBounds(job.config_obj.shapefileFK)}
                  cancelRun={() => console.log("cancelled!")}
                  key={job.id}
                  job_obj={job}
                  config_obj={job.config_obj}
                />
              ))}
              {jobs && jobs.length < 3 && (
                <a href="/submitinversion" style={{ color: "inherit" }}>

                  <div className="tilePlaceholder">
                    <Icon name="plus" size="huge" />
                    <p>Submit a new run</p>
                  </div>
                </a>
              )}
            </>
            :
            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <div>
                <Icon name="folder open outline" size="huge" />
                <p>No runs to display</p>
              </div>
            </div>
          }

        </div>
      ) : (
        <div className="tileWrapper">
          {isLoading && <Loader active inline="centered" />}
          {communityJobs.length > 0 ?
            <>
              {communityJobs.map((job: any) => (
                <RunTile
                  runName={job.config_obj.RunName}
                  roiArea={job.config_obj.roiArea}
                  jobID={job.id}
                  timeRemaining={23}
                  status={job.status}
                  viewBounds={() => handleViewBounds(job.config_obj.shapefileFK)}
                  cancelRun={() => console.log("cancelled!")}
                  key={job.id}
                  job_obj={job}
                  config_obj={job.config_obj}
                />
              ))}

            </>
            :
            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <div>
                <Icon name="folder open outline" size="huge" />
                <p>No runs to display</p>
              </div>
            </div>
          }

        </div>
      )}
      <BoundsModalRunDisplay open={openBoundsModal} onClose={handleCloseBoundsModal} shapefileID={selectedShapefileID} />

    </div>
  );
};

export default RunDisplay;
