import React from 'react';
import { Message, Container, Icon } from 'semantic-ui-react';

const MobileCheck = ({ children }) => {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const checkMobile = () => {
      const mobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(
        navigator.userAgent
      ) || window.innerWidth <= 768;
      setIsMobile(mobile);
    };

    // Check initially
    checkMobile();

    // Add resize listener
    window.addEventListener('resize', checkMobile);

    // Cleanup
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  if (isMobile) {
    return (
      <Container style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Message negative icon>
          <Icon name='mobile' />
          <Message.Content>
            <Message.Header>Mobile Access Restricted</Message.Header>
            <p>This application is currently optimized for desktop viewing only. 
               Please access the application from a desktop or laptop computer for the best experience.</p>
          </Message.Content>
        </Message>
      </Container>
    );
  }

  return children;
};

export default MobileCheck;