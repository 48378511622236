import React from "react";
import { Container, Message, Icon } from "semantic-ui-react";

const NotFound = () => {
  return (
    <div className="pageWrapper">

    <Container textAlign="center" style={{ padding: "50px" }}>
      <Message icon color="red">
        <Icon name="warning circle" />
        <Message.Content>
          <Message.Header>Page Not Found</Message.Header>
          Sorry, the page you are looking for does not exist or has been moved.
        </Message.Content>
      </Message>
      <p style={{ marginTop: "20px", color: "white" }}>
        You can go back to the <a href="/" style = {{color: "inherit", textDecoration: "underline"}}>Home Page</a> or check the navigation menu.
      </p>
    </Container>
    </div>
  );
};

export default NotFound;