import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { isEmail } from "multiform-validator";
import { passwordStrength } from "check-password-strength";
import "./SignIn-Up.css";
import { Input, Label, Grid, GridRow, GridColumn, Loader, Icon, Popup, Message, Button, Progress, Segment, Modal } from "semantic-ui-react";
import StandardButton from "../Buttons/standardButton";
import { useNavigate } from "react-router-dom";

//@ts-ignore
import { AuthContext } from "../../contexts/AuthContext";
import { set } from "date-fns";

interface SignInProps {
  // Add any props you need for the SignIn component
}

const SignInUp: React.FC<SignInProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [buttonOriginSignIn, setButtonOriginSignIn] = useState(state ? state.buttonOriginSignIn : null);

  const { register, user, login, logout } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSignup, setIsLoadingSignup] = useState(false);
  const [signinWarning, setSigninWarning] = useState("");

  // State variables for Sign In
  const [emailLogin, setEmailLogin] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");

  const [isSignUp, setIsSignUp] = useState(false);
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
  const [otpSendSuccess, setOtpSendSuccess] = useState(false);
  const [otpErrors, setOtpErrors] = useState("");

  const [betaCode, setBetaCode] = useState("");
  const [betaCodeError, setBetaCodeError] = useState(false);

  function getCookie(name: string) {
    let cookieValue: any = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  function deleteCookie(name: string) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  // Function to set a new cookie
  function setCookie(name, value, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)); // Set the expiration time in milliseconds
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  }

  // Simple function to generate a 6-digit OTP
  const generateOTP = () => {
    return Math.floor(100000 + Math.random() * 900000).toString(); // Generates a random 6-digit number
  };

  // Add this function to handle the OTP generation and sending
  const handleForgotPassword = async () => {
    setIsLoading(true);

    // Generate a 6-digit numeric OTP
    const otp = generateOTP();

    // Save OTP in session storage
    sessionStorage.setItem("otp", otp);

    try {
      // Send a fetch request to send the OTP to the user's email
      const response: any = await fetch("/api/send_forgot_password_email/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: emailLogin, otp }), // Send email and OTP
      });

      if (response.ok) {
        console.log("OTP sent successfully");
        setIsLoading(false)
        setOtpSendSuccess(true)
        // Redirect to the OTP verification form or notify the user
      } else {
        console.log("Failed to send OTP");
        setIsLoading(false)
        setOtpErrors("User does not exist or an error occurred. Please try again.")
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false)
    }
  };

  useEffect(() => {
    setIsSignUp(!buttonOriginSignIn);
  }, [buttonOriginSignIn]);

  useEffect(() => {
    if (isSignUp) {
      // Reset sign-up fields when switching to sign-up
      setFirstName("");
      setLastName("");
      setOrganization("");
      setEmailSignUp("");
      setPasswordSignUp("");
      setRepeatPassword("");
      setErrors([]);
    } else {
      // Reset sign-in fields when switching to sign-in
      setEmailLogin("");
      setPasswordLogin("");
      setSigninWarning("");
    }
  }, [isSignUp]);

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {

    setIsSignUp(event.target.checked);
  };

  const handleLogin = async () => {
    try {
      setIsLoading(true);
      let response = await login(emailLogin, passwordLogin);

      // Check if the response indicates a failed login
      if (!response) {
        throw new Error("Incorrect email or password.");
      }

      // If the response is true (successful login), navigate to the home page
      if (response) {
        // Wait for state to update before navigating
        setTimeout(() => {
          setIsLoading(false);
          return navigate("/");
        }, 1000)
      }
    } catch (error) {
      setIsLoading(false);

      // // Remove the [' '] from the string if they exist
      // if (error.message.startsWith("[") && error.message.endsWith("]")) {
      //   error.message = error.message.slice(1, -1);  // Remove the first and last character
      // }

      // // Further clean the message by removing any quotation marks
      // error.message = error.message.replace(/['"]+/g, '');

      // Set the warning message for the user
      setSigninWarning(error.message);
    }
  };

  //   State variables for Sign Up
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organization, setOrganization] = useState("");
  const [emailSignUp, setEmailSignUp] = useState("");
  const [passwordSignUp, setPasswordSignUp] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordStrengthSignUp, setPasswordStrengthSignUp] = useState('');
  const [errors, setErrors] = useState(['First name is required', 'Last name is required', 'Password is required', 'Passwords do not match']);
  const [validateFail, setValidateFail] = useState(false);

  const [emailError, setEmailError] = useState(false);

  const handleSignUp = async () => {

    // Check for beta access
    const betaResponse = await fetch(`/api/beta_access_check/?beta_key=${betaCode}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const betaData = await betaResponse.json();

    if (betaData.error) {
      console.log("Beta code is incorrect");
      setBetaCode("");
      setBetaCodeError(true);
    } else {
      console.log("Beta code is correct");
      setBetaCodeError(false);

      // Proceed with email check and registration only if beta code is correct
      let userName = firstName + " " + lastName;
      const emailResponse = await fetch(`/api/check_email/?email=${emailSignUp}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const emailData = await emailResponse.json();

      if (emailData.error) {
        console.log("Email already exists");
        setEmailError(true);
      } else {
        console.log("Email does not exist yet. Good to go.");

        try {
          setIsLoading(true);
          let response = await register(userName, organization, emailSignUp, passwordSignUp);
          setIsLoading(false);

          // If the response is successful, log in the user
          if (response && !response.errors) {
            console.log("Sign up successful");
            let loginResponse = await login(emailSignUp, passwordSignUp);

            // If the login response is successful, navigate to the home page
            if (loginResponse && !loginResponse.errors) {
              console.log("Login successful");
              return navigate("/");
            } else {
              console.log(loginResponse);
              setEmailError(true);
            }
          } else {
            console.log(response);
            setEmailError(true);
          }
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      }
    }
  };

  // validation method to work on later, using jank method for now
  useEffect(() => {
    if (!firstName || !lastName || !passwordSignUp || !repeatPassword || !emailSignUp) {
      setErrors([...errors, "All fields are required"]);
    }
    if (emailSignUp && !isEmail(emailSignUp)) {
      setErrors([...errors, "Invalid email"]);
    }
    if (!firstName) {
      if (!errors.includes("First name is required")) {
        setErrors([...errors, "First name is required"]);
      }
    } else {
      setErrors(errors.filter((error) => error !== "First name is required"));
    }
    if (!lastName) {
      if (!errors.includes("Last name is required")) {
        setErrors([...errors, "Last name is required"]);
      }
    } else {
      setErrors(errors.filter((error) => error !== "Last name is required"));
    }
    if (!passwordSignUp) {
      if (!errors.includes("Password is required")) {
        setErrors([...errors, "Password is required"]);
      }
    } else {
      setErrors(errors.filter((error) => error !== "Password is required"));
    }
    if (passwordSignUp && passwordSignUp !== repeatPassword) {
      if (!errors.includes("Passwords do not match")) {
        setErrors([...errors, "Passwords do not match"]);
      }
    }
    if (passwordSignUp === repeatPassword) {
      setErrors(errors.filter((error) => error !== "Passwords do not match"));
    }

  }, [emailSignUp, firstName, lastName, passwordSignUp]);

  const validateForm = () => {
    let badEmail = !isEmail(emailSignUp);
    if (firstName === "" || lastName === "" || emailSignUp === "" || passwordSignUp === "" || (passwordSignUp !== repeatPassword) || badEmail) {
      setValidateFail(true);
      return true;
    } else {
      setValidateFail(false);
      return false;
    }
  }

  useEffect(() => {
    if (passwordSignUp) {
      const strength = passwordStrength(passwordSignUp);
      setPasswordStrengthSignUp(strength.value);
    }
  }, [passwordSignUp, repeatPassword])

  return (
    <div className="pageWrapper">
      <div className="centerHorizontally">

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
            width: !isSignUp ? "80%" : "100%",
            transition: "all 300ms ease-in-out"
          }}
        >
          <div className="formWrapperSignIn tileShadow">
            <div className="leftDisplaySignIn">
              <img src="/pngs/SentinelSat.png" alt="Satellite Image" className="satellite" />
              <div style={{ padding: "10%", paddingRight: "20%", zIndex: "50" }}>
                {!isSignUp ?
                  <>

                    <h1
                      style={{
                        fontWeight: "300",
                        marginTop: "50%",
                        fontSize: "3rem",
                        textAlign: "center",
                      }}
                    >
                      Welcome back!
                    </h1>
                    <p style={{ textAlign: "center", fontSize: "1.1rem" }}>
                      Sign in to get started quantifying emissions.
                    </p>
                  </>
                  :
                  <>
                    <h1
                      style={{
                        fontWeight: "300",
                        marginTop: "50%",
                        fontSize: "3rem",
                        textAlign: "center",
                      }}
                    >
                      Get started!
                    </h1>
                    <p style={{ textAlign: "center", fontSize: "1.1rem" }}>
                      Sign up to get started quantifying emissions.
                    </p>
                  </>

                }
              </div>
            </div>
            <div className="rightDisplaySignIn">
              <div className="btn-container" style={{ marginTop: "2rem" }}>
                <label className="switch btn-color-mode-switch">
                  <input
                    id="color_mode"
                    name="color_mode"
                    type="checkbox"
                    checked={isSignUp}  // This is bound to your state
                    onChange={handleToggle}  // Use the function to handle the toggle
                  />
                  <label
                    className="btn-color-mode-switch-inner"
                    data-off="Sign in"
                    data-on="Sign up"
                    htmlFor="color_mode">
                  </label>
                </label>
              </div>
              {!isSignUp ? (
                <>
                  <div className="signInContent" >
                    <div className="inputLabel" style={{ marginTop: "1.5rem" }}>
                      <label htmlFor="">Email address</label>
                      <Input className="darkModeInput wideInput" icon="mail outline" iconPosition="left" value={emailLogin} onChange={(e: any) => setEmailLogin(e.target.value)} />
                    </div>

                    <div className="inputLabel" style={{ marginTop: "1.5rem" }}>
                      <label htmlFor="">Password</label>
                      <Input
                        className="darkModeInput wideInput"
                        type="password"
                        icon="lock"
                        iconPosition="left"
                        value={passwordLogin}
                        onChange={(e: any) => setPasswordLogin(e.target.value)}
                      />
                    </div>
                    <div style={{ display: "flex", justifyContent: "right", width: "100%" }}>

                      <p style={{ marginTop: "0.5rem", marginBottom: "1.2rem", fontSize: "0.8rem" }}>
                        <span onClick={() => setOpenForgotPasswordModal(true)} style={{ color: "var(--dark-yellow)", cursor: "pointer" }}>
                          &nbsp; Forgot password.
                        </span>
                      </p>
                    </div>
                    <p style={{ marginTop: "1rem", marginBottom: "1.2rem" }}>
                      Don't have an account?
                      <span onClick={() => setIsSignUp(true)} style={{ color: "var(--dark-yellow)", cursor: "pointer" }}>
                        &nbsp; Sign up.
                      </span>
                    </p>

                    {isLoading ? <Loader active inline="centered" /> : <StandardButton text={"Sign in"} color={"darkyellow"} clickFunction={() => handleLogin()} />}
                    {signinWarning && <Message error>
                      <p style={{ fontSize: "0.9rem" }}>
                        <strong>{signinWarning}</strong> Please try again.
                      </p>
                    </Message>}
                  </div>
                </>
              ) : (
                <>
                  <div className="signUp" >
                    <p style={{ textAlign: "center", marginTop: "1.05rem" }}>Already have an account? <span onClick={() => setIsSignUp(false)} style={{ color: "var(--dark-yellow)", cursor: "pointer" }}>Sign in.</span></p>
                    <Grid className="form-container">
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <div className="inputLabel">
                            <label htmlFor="firstName">First Name</label>
                            <Input className="darkModeInput" id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                          </div>
                        </Grid.Column>
                        <Grid.Column>
                          <div className="inputLabel">
                            <label htmlFor="lastName">Last Name</label>
                            <Input className="darkModeInput" id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <div className="inputLabel">
                            <label htmlFor="organization">Organization (optional)</label>
                            <Input
                              className="darkModeInput"
                              icon="building"
                              iconPosition="left"
                              id="organization"
                              value={organization}
                              onChange={(e) => setOrganization(e.target.value)}
                              fluid
                            />
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <div className="inputLabel">
                            <label htmlFor="email">Email address</label>
                            <Input
                              className="darkModeInput"
                              icon="mail"
                              iconPosition="left"
                              id="email"
                              value={emailSignUp}
                              onChange={(e) => setEmailSignUp(e.target.value)}
                            />
                          </div>
                          {emailError && <Message error>
                            <p style={{ fontSize: "0.9rem" }}>
                              <strong>Email already exists.</strong> Please sign in or use a different email.

                            </p>
                          </Message>}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <div className="inputLabel">
                            <label htmlFor="password">Password</label>
                            <Input
                              className="darkModeInput"
                              icon="lock"
                              iconPosition="left"
                              type="password"
                              id="password"
                              value={passwordSignUp}
                              onChange={(e) => setPasswordSignUp(e.target.value)}
                            />
                          </div>
                        </Grid.Column>
                        <Grid.Column>
                          <div className="inputLabel">
                            <label htmlFor="repeatPassword">Repeat password</label>
                            <Input
                              className="darkModeInput"
                              icon="lock"
                              iconPosition="left"
                              type="password"
                              id="repeatPassword"
                              value={repeatPassword}
                              onChange={(e) => setRepeatPassword(e.target.value)}
                            />
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                      {passwordSignUp && <Grid.Row>

                        <Grid.Column>
                          <Popup position="bottom center" content="Password strength" trigger={<Progress size="tiny" style={{ marginBottom: 0 }} percent={passwordStrengthSignUp == "Too weak" ? 25 : passwordStrengthSignUp == "Weak" ? 66 : passwordStrengthSignUp == "Medium" || passwordStrengthSignUp == "Strong" ? 100 : 0} color={passwordStrengthSignUp == "Weak" || passwordStrengthSignUp == "Too weak" ? "red" : passwordStrengthSignUp == "Medium" ? "yellow" : passwordStrengthSignUp == "Strong" ? "green" : "green"} />} />
                        </Grid.Column>
                      </Grid.Row>}
                      <Grid.Row>
                        <Grid.Column>
                          <div className="inputLabel">
                            <label htmlFor="beta-code">Beta Access Code <span><Popup className="darkPopup" content="Request a beta access code by emailing our support team at integral.earth.team@gmail.com." trigger={<Icon name="info circle" color="grey" style={{ cursor: "pointer", display: "inline-block" }} />} />
                            </span></label>

                            <Input
                              className="darkModeInput"
                              icon="lock open"
                              iconPosition="left"
                              id="beta-code"
                              value={betaCode}
                              onChange={(e) => setBetaCode(e.target.value)}
                            />
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                      {passwordSignUp && repeatPassword && passwordSignUp !== repeatPassword && <Grid.Row>
                        <Grid.Column>
                          <Message error >
                            Passwords do not match
                          </Message>
                        </Grid.Column>
                      </Grid.Row>}
                      <Grid.Row style={{ marginTop: "0 !important", paddingTop: "0 !important" }}>
                        <Grid.Column style={{ display: "flex", justifyContent: "center" }}>
                          {validateFail ? <Popup content="Please correct errors in the form" trigger={<StandardButton text={"Sign up"} color={"darkyellow"} clickFunction={async () => {
                            if (!validateForm()) {
                              setIsLoadingSignup(true);
                              try {
                                await handleSignUp();
                                setIsLoadingSignup(false);
                              } catch (error) {
                                console.log(error);
                              }
                            } else {
                              setErrors([...errors, "Please correct errors in the form"]);
                            }
                          }}
                          // disabled={validateForm()}
                          />} /> :
                            <>
                              <StandardButton text={"Sign up"} color={"darkyellow"} clickFunction={async () => {
                                if (!validateForm()) {
                                  setIsLoadingSignup(true);
                                  try {
                                    await handleSignUp();
                                    setIsLoadingSignup(false);
                                  } catch (error) {
                                    console.log(error);
                                  }
                                } else {
                                  setErrors([...errors, "Please correct errors in the form"]);
                                }
                              }}
                                // Currently not checking password strength
                                disabled={!firstName || !lastName || !emailSignUp || !passwordSignUp || !repeatPassword || passwordSignUp !== repeatPassword || isLoadingSignup || betaCode.length < 6 || emailError}
                              />
                            </>
                          }
                        </Grid.Column>
                      </Grid.Row>
                      {betaCodeError && <Grid.Row>
                        <Grid.Column>
                          <Message error >
                            Beta code is incorrect. <strong>Please try again.</strong>
                          </Message>
                        </Grid.Column>
                      </Grid.Row>}


                      <Loader active={isLoadingSignup} inline="centered" />
                    </Grid>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/*
      
        {/* Modal for handling forgot password */}
        <Modal
          onClose={() => setOpenForgotPasswordModal(false)}
          onOpen={() => setOpenForgotPasswordModal(true)}
          open={openForgotPasswordModal}
          size="tiny"
        >
          <Modal.Header>Forgot Password</Modal.Header>
          {!otpSendSuccess ?
            <>

              <Modal.Content>
                <Modal.Description>
                  <p>
                    Enter your email address to receive a one-time password (OTP) to reset your password.
                  </p>
                  <Input
                    className="darkModeInput wideInput"
                    icon="mail"
                    iconPosition="left"
                    placeholder="Email address"
                    value={emailLogin}
                    onChange={(e: any) => setEmailLogin(e.target.value)}
                  />
                  {otpErrors && <Message error>
                    <p style={{ fontSize: "0.9rem" }}>
                      <strong>{otpErrors}</strong>
                    </p>
                  </Message>}
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button color="black" onClick={() => setOpenForgotPasswordModal(false)}>
                  Cancel
                </Button>
                <Button
                  content="Send OTP"
                  labelPosition="right"
                  icon="checkmark"
                  onClick={() => handleForgotPassword()}
                  positive
                  disabled={!emailLogin || !isEmail(emailLogin) || isLoading}
                  loading={isLoading}
                />
              </Modal.Actions>
            </> :
            (<Modal.Content>
              <p>
                An email has been sent to {emailLogin} with a one-time password (OTP) to reset your password. Please check your email.
              </p>
            </Modal.Content>)
          }
        </Modal>

      </div>
    </div>
  );
};

export default SignInUp;
