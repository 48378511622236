import React from "react";
import "./AboutPage.css";
import Footer from "../Footer/Footer";
import { Button } from "semantic-ui-react";

const AboutPage = () => {
  return (
    <div className="flexContainer">
      <div className="pageWrapper aboutPage" style={{ paddingLeft: "25vw", paddingRight: "25vw" }}>
        <h1 style={{ color: "var(--dark-yellow)" }}>About</h1>
        <p style={{ width: "70%" }}>
          Integral Earth is a <strong>user-friendly web interface for quantifying methane emissions</strong> with satellite data. It can be applied to regions of interest ranging
          in scale from small urban areas to countries or continents, and global applications are also supported.{" "}
          <strong>
            <span>It is currently available for free while we prototype the system and develop a funding model.</span>
          </strong>
        </p>
        <h2 style={{ color: "var(--dark-yellow)" }}>Methodology (The IMI)</h2>
        <p style={{ width: "70%" }}>
          Integral Earth is built on the open-source Integrated Methane Inversion (IMI) facility. The IMI infers methane emissions from users’ regions of interest at up to 25-km
          resolution by inverse analysis (“inversions”) of satellite observations from the TROPOspheric Monitoring Instrument (TROPOMI).
        </p>
        <div className="centerHorizontally" style={{ marginBottom: "2.5rem" }}>
          <a href="https://integratedmethaneinversion.github.io/" target="#">
            <Button style={{ backgroundColor: "var(--dark-yellow)", color: "white" }}>Learn More About the IMI</Button>
          </a>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img id="aboutPagePlot" src="/pngs/model_transparent.png" alt="IMI model" style={{ width: "70%", margin: "1.5rem" }} />
        </div>
        <h2 style={{ color: "var(--dark-yellow)" }}>Requesting an Inversion</h2>
        <p>
          Users can submit an inversion request by filling out a form <strong>detailing their region and period of interest</strong>. The form requires just an outline of the
          region (submitted via an interactive portal or by shapefile upload), a start day, and an end day.{" "}
          <strong>Advanced settings are available for access to all of the features</strong> of the research-grade IMI software, including continuous near-real-time emission
          monitoring.
        </p>
        <h2 style={{ color: "var(--dark-yellow)" }}>Form Submission</h2>
        <p>
          Submitting the form sends a job to our Amazon Web Services (AWS) backend for evaluation by our team. We assess whether the requested inversion is likely to succeed, based
          on the <strong>available satellite observations and best prior emission estimates</strong>, and then share our evaluation with the user. Some adjustments may be needed
          for the job to be successful, in which case we work with the user to identify a useful configuration.{" "}
          <strong>Once the job is confirmed, we run the IMI and return the results to the user’s Integral Earth dashboard</strong>. All data analysis happens behind the scenes on
          the AWS cloud.
        </p>
      </div>
    </div>
  );
};

export default AboutPage;
