import React, { useState, useEffect } from "react";
import "./StandardButton.css";

function StandardButton({ text, color, clickFunction, disabled = false }) {
  const [buttonColor, setButtonColor] = useState("var(--dark-yellow)");

  useEffect(() => {
    if (color === "darkyellow") {
      setButtonColor("var(--dark-yellow)");
    } else if (color === "backgroundgrey") {
      setButtonColor("var(--background-grey)");
    } else if (color === "white") {
      setButtonColor("var(--white)");
    } else if (color === "none") {
      setButtonColor("transparent");
    } else {
      setButtonColor(color);
    }
  }, [color, buttonColor]);

  return (
    <button
      onClick={clickFunction}
      disabled={disabled}
      className="StandardButton"
      style={{ backgroundColor: disabled ? "grey" : buttonColor, border: buttonColor === "none" ? "solid white 1px" : "solid 1px grey" }}
    >
      <span>{text}</span>
    </button>
  );
}

export default StandardButton;
