import React, { useEffect, useState } from "react";
import { Button, Modal, Icon, Popup, Grid, GridRow, GridColumn, Dropdown, Input, Slider } from "semantic-ui-react";
import "./AdvancedSettingsModal.css";
import CheckboxInteractive from "./FormComponents/Checkbox.tsx";
import InputField from "./FormComponents/InputField.tsx";
import * as turf from "@turf/turf";

const AdvancedSettingsModal = ({ triggerButton, formState, setFormState, estPixels, setEstPixels, geojsonArea, handleInputChange }) => {
  const [open, setOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("Inversion Parameters");
  const [clusteringProportion, setClusteringProportion] = useState(0.8);
  const [geojson, setGeojson] = useState(null);

  let pixelArea = formState.Res === "0.25x0.3125" ? 25 * 30 : 55 * 60;

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (geojsonArea) {
      setEstPixels(Math.floor(geojsonArea / pixelArea));
    }
  }, [formState.Res]);

  // Update the number of elements in the state vector when the clustering proportion changes
  useEffect(() => {
    setFormState((prevState) => ({
      ...prevState,
      NumberOfElements: Math.ceil(estPixels * clusteringProportion),
    }));
  }, [formState.ReducedDimensionStateVector, clusteringProportion]);

  const renderContent = () => {
    switch (activeItem) {
      case "Kalman Filter Options":
        return (
          <div>
            <label>
              Use Kalman Filter
              <span>
                <Popup
                  className="darkPopup"
                  position="top center"
                  content={
                    <span>
                      Continuous monitoring approach that updates methane emission estimates at regular intervals (e.g., weekly or monthly) by using the{" "}
                      <strong>posterior emissions</strong> from one period as the prior for the next, allowing for real-time tracking of temporal changes in emissions.
                    </span>
                  }
                  trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                />
              </span>
            </label>
            <CheckboxInteractive name="KalmanMode" onChange={handleInputChange} checked={formState.KalmanMode} />
            <br />
            <br />
            <label>
              Update Frequency (days)
              <span>
                <Popup
                  className="darkPopup"
                  position="top center"
                  content="Time period for each Kalman filter update. For example, if set to 7, the model will update the emissions estimates every 7 days."
                  trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                />
              </span>
            </label>
            <input
              type="number"
              name="UpdateFreqDays"
              className="darkFluidInput ultraThin"
              disabled={!formState.KalmanMode}
              placeholder="Enter..."
              value={formState.UpdateFreqDays}
              onChange={handleInputChange}
            />
            <br />
            <br />
            <label>
              Nudge Factor
              <span>
                <Popup
                  className="darkPopup"
                  position="top center"
                  content="Proportion of the prior emissions that are nudged towards the posterior emissions. A value of 0 means no nudging, while a value of 1 means the prior emissions are completely replaced by the posterior emissions."
                  trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                />
              </span>
            </label>
            <input
              type="number"
              name="NudgeFactor"
              className="darkFluidInput ultraThin"
              disabled={!formState.KalmanMode}
              placeholder="Enter..."
              value={formState.NudgeFactor}
              onChange={handleInputChange}
              step={0.1}
            />
          </div>
        );
      case "State Vector / Point Sources":
        return (
          <Grid columns={3}>
            <GridRow columns={1} style={{ marginLeft: "30px" }}>
              <GridColumn width={16}>
                <label htmlFor="">
                  Point Sources
                  <span>
                    <Popup
                      className="darkPopup"
                      position="top center"
                      content="Optional list of public datasets to use for visualization of point sources to be included in state vector clustering. Only available option is SRON, but more datasets will be added in the future."
                      trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                    />
                  </span>
                </label>
                <Dropdown
                  className="darkDropdown thin"
                  name="PointSourceDatasets"
                  fluid
                  selection
                  multiple
                  disabled
                  options={[
                    { key: "SRON", text: "SRON", value: "SRON" },
                    { key: "IMEO", text: "IMEO", value: "IMEO" },
                    { key: "CarbonMapper", text: "CarbonMapper", value: "CarbonMapper" },
                  ]}
                  defaultValue="SRON"
                  onChange={(e, data) => handleInputChange(e, data)}
                  style={{ marginBottom: "10px" }}
                />
                <div style={{ display: "flex" }}>
                  <CheckboxInteractive name={"OptimizeBCs"} onChange={handleInputChange} checked={formState.OptimizeBCs} />
                  <label htmlFor="OptimizeBC">
                    Optimize Boundary Conditions
                    <span>
                      <Popup
                        className="darkPopup"
                        position="top center"
                        content="Reduces biases associated with methane flowing into the domain by optimizing the boundary conditions as part of the inversion."
                        trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                      />
                    </span>
                  </label>
                </div>
                <div style={{ display: "flex" }}>
                  <CheckboxInteractive name={"OptimizeOH"} onChange={handleInputChange} checked={formState.OptimizeOH} />
                  <label htmlFor="OptimizeOH">
                    Optimize OH
                    <span>
                      <Popup
                        className="darkPopup"
                        position="top center"
                        content="Boolean to optimize OH during the inversion. Must also include PerturbValueOH and PriorErrorOH. Default value is false."
                        trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                      />
                    </span>
                  </label>
                </div>
                {/* <div style={{ display: "flex" }}>
                  <CheckboxInteractive
                    name={"CreateAutomaticRectilinearStateVectorFile"}
                    onChange={handleInputChange}
                    checked={formState.CreateAutomaticRectilinearStateVectorFile}
                  />
                  <label htmlFor="CreateAutomaticRectilinearStateVectorFile">
                    Create Automatic Rectilinear State Vector File
                    <span>
                      <Popup
                      className = "darkPopup"
                        position="top center"
                        content="The initial simulation period used to allow the model’s atmospheric conditions to stabilize and accurately represent the baseline state before the main inversion and analysis of methane emissions begins."
                        trigger={<Icon className="infoIconForm" name="info" color="red" circular inverted />}
                      />
                    </span>
                  </label>
                </div> */}
              </GridColumn>
            </GridRow>
          </Grid>
        );
      case "Clustering Options":
        return (
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column width={10}>
                {formState.KalmanMode && (
                  <div style={{ display: "flex" }}>
                    <CheckboxInteractive name="DynamicKFClustering" checked={formState.DynamicKFClustering} onChange={handleInputChange} />
                    <label htmlFor="DynamicKFClustering">
                      Dynamic KF clustering
                      <span>
                        <Popup
                          className="darkPopup"
                          position="top center"
                          content="Update the state vector clustering with each Kalman Filter update."
                          trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                        />
                      </span>
                    </label>
                  </div>
                )}
                <div style={{ display: "flex" }}>
                  <CheckboxInteractive name="ReducedDimensionStateVector" checked={formState.ReducedDimensionStateVector} onChange={handleInputChange} />
                  <label htmlFor="ReducedDimensionStateVector">
                    Reduce state vector dimension
                    <span>
                      <Popup
                        className="darkPopup"
                        position="top center"
                        content="Reduce the dimension of the state vector from the native resolution version by clustering elements. If false the native state vector is used with no dimension reduction."
                        trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                      />
                    </span>
                  </label>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label htmlFor="ClusteringMethod">
                    Clustering method
                    <span>
                      <Popup
                        className="darkPopup"
                        position="top center"
                        content="Clustering method to use for state vector reduction."
                        trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                      />
                    </span>
                  </label>
                  <Dropdown
                    className="darkDropdown thin"
                    name="ClusteringMethod"
                    selection
                    options={[
                      { key: "kmeans", text: "kmeans", value: "kmeans" },
                      { key: "mini-batch-kmeans", text: "mini-batch-kmeans", value: "mini-batch-kmeans" },
                    ]}
                    defaultValue="kmeans"
                    onChange={(e, data) => handleInputChange(e, data)}
                    disabled={!formState.ReducedDimensionStateVector}
                  />
                </div>
              </Grid.Column>
              <Grid.Column>
                <label htmlFor="NumberOfElements">
                  Clustering Proportion
                  <span>
                    <Popup
                      className="darkPopup"
                      position="top center"
                      content="Proportion of elements (relative to full resolution) to be used in the reduced dimension state vector."
                      trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                    />
                  </span>
                </label>
                {/* <InputField
                  className="thin"
                  type="number"
                  height={2.5}
                  width={50}
                  name={"NumberOfElements"}
                  value={formState.NumberOfElements}
                  onChange={handleInputChange}
                  disabled={!formState.ReducedDimensionStateVector}
                /> */}
                {/* <input
                  type="number"
                  className="darkFluidInput thin"
                  name="NumberOfElements"
                  placeholder="Enter num elements..."
                  value={formState.NumberOfElements}
                  onChange={handleInputChange}
                  disabled={!formState.ReducedDimensionStateVector}
                /> */}
                <input
                  type="range"
                  value={clusteringProportion}
                  onChange={(e) => setClusteringProportion(parseFloat(e.target.value))}
                  min={0.1}
                  max={0.9}
                  step={0.05}
                  style={{ accentColor: "var(--dark-yellow)" }}
                  disabled={!formState.ReducedDimensionStateVector}
                />
                {formState.ReducedDimensionStateVector && (
                  <>
                    <p style={{ display: "inline-block", marginLeft: "2rem", transform: "translateY(-3px)" }}>{Math.round(clusteringProportion * 100)}%</p>
                    <p>
                      (Est. <span style={{ color: "var(--dark-yellow)" }}>{Math.ceil(estPixels * clusteringProportion)}</span>{" "}
                      {formState.Res === "0.25x0.3125" ? "25km x 30km" : "55km x 60km"} grid cells)
                    </p>
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        );
      case "Inversion Parameters":
        return (
          <Grid columns={3}>
            <Grid.Row>
              <Grid.Column width={8}>
                <label htmlFor="Res">
                  Resolution
                  <span>
                    <Popup
                      className="darkPopup"
                      position="top center"
                      content="Resolution for inversion in degrees lat/long. Default is 0.25x0.3125 which equates to around 25km pixels"
                      trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                    />
                  </span>
                </label>
                <Dropdown
                  className="darkDropdown ultraThin2"
                  name="Res"
                  selection
                  options={[
                    { key: "0.25x0.3125", text: '0.25"x0.3125"', value: "0.25x0.3125" },
                    { key: "0.5x0.625", text: '0.5"x0.625"', value: "0.5x0.625" },
                    { key: "2.0x2.5", text: '2.0x2.5" - Global Inversions Only', value: "2.0x2.5" },
                  ]}
                  defaultValue="0.25x0.3125"
                  value={formState.Res}
                  onChange={(e, data) => handleInputChange(e, data)}
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <label htmlFor="Res">
                  Use Blended Product
                  <span>
                    <Popup
                      className="darkPopup"
                      position="top center"
                      content="Boolean for if the Blended TROPOMI+GOSAT data should be used."
                      trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                    />
                  </span>
                </label>
                <CheckboxInteractive name="BlendedTROPOMI" onChange={handleInputChange} checked={formState.BlendedTROPOMI} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <label htmlFor="ObsError">
                  Obs. Error
                  <span>
                    <Popup
                      className="darkPopup"
                      position="top center"
                      content="Observational Error. (1-sigma; absolute; ppb). Default value is 15 ppb error."
                      trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                    />
                  </span>
                </label>
                <input type="number" className="darkFluidInput thin" name="ObsError" placeholder="Enter obs error..." value={formState.ObsError} onChange={handleInputChange} />
              </Grid.Column>
              <Grid.Column>
                <label htmlFor="Gamma">
                  Gamma
                  <span>
                    <Popup
                      className="darkPopup"
                      position="top center"
                      content="Regularization parameter; typically between 0 and 1. Default value is 1.0."
                      trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                    />
                  </span>
                </label>
                <input type="number" className="darkFluidInput thin" name="Gamma" placeholder="Enter gamma..." value={formState.Gamma} onChange={handleInputChange} step={0.1} />
                {/* <InputField className={"thin"} height={2.5} width={50} name={"Gamma"} value={formState.Gamma} type={"number"} onChange={handleInputChange} /> */}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <label htmlFor="PriorError">
                  Prior Error
                  <span>
                    <Popup
                      className="darkPopup"
                      position="top center"
                      content="Error in the prior estimates (1-sigma; relative). Default is 0.5 (50%) error."
                      trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                    />
                  </span>
                </label>
                <input
                  type="number"
                  className="darkFluidInput thin"
                  name="PriorError"
                  placeholder="Enter prior error..."
                  value={formState.PriorError}
                  onChange={handleInputChange}
                  step={0.1}
                />
                {/* <InputField className={"thin"} height={2.5} width={10} name={"PriorError"} value={formState.PriorError} type={"number"} onChange={handleInputChange} /> */}
              </Grid.Column>
              <Grid.Column>
                <label htmlFor="PriorErrorOH">
                  Prior Error OH
                  <span>
                    <Popup
                      className="darkPopup"
                      position="top center"
                      content="Error in the prior estimates (relative percent). Default is 0.5 (50%) error."
                      trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                    />
                  </span>
                </label>
                <input
                  type="number"
                  className="darkFluidInput thin"
                  name="PriorErrorOH"
                  placeholder="Enter prior error OH..."
                  value={formState.PriorErrorOH}
                  onChange={handleInputChange}
                  step={0.1}
                />

                {/* <InputField className={"thin"} height={2.5} width={50} name={"PriorErrorOH"} value={formState.PriorErrorOH} type={"number"} onChange={handleInputChange} /> */}
              </Grid.Column>
              <Grid.Column>
                <label htmlFor="PriorError">
                  Prior Error BCs
                  <span>
                    <Popup
                      className="darkPopup"
                      position="top center"
                      content="Error in the prior estimates (using ppb). Default is 10 ppb error."
                      trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                    />
                  </span>
                </label>
                <input
                  type="number"
                  className="darkFluidInput thin"
                  name="PriorErrorBCs"
                  placeholder="Enter prior error BCs..."
                  value={formState.PriorErrorBCs}
                  onChange={handleInputChange}
                  step={0.1}
                />

                {/* <InputField className={"thin"} height={2.5} width={50} name={"PriorErrorBCs"} value={formState.PriorErrorBCs} type={"number"} onChange={handleInputChange} /> */}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        );
      case "Advanced GEOS-Chem Configuration":
        return (
          <Grid columns={3}>
            <GridRow>
              <GridColumn>
                <label htmlFor="PerturbValue">
                  Perturb Value
                  <span>
                    <Popup
                      className="darkPopup"
                      position="top center"
                      content="Target perturbation amount on the emissions in each sensitivity simulation. Default value is 1. Corresponding to a 1e-8 kg/m2/s perturbation."
                      trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                    />
                  </span>
                </label>
                <input
                  type="number"
                  className="darkFluidInput thin"
                  name="PerturbValue"
                  placeholder="Enter perturb value..."
                  value={formState.PerturbValue}
                  onChange={handleInputChange}
                  step={0.1}
                />
                {/* <InputField className="thin" height={2.5} width={50} name={"PerturbValue"} value={formState.PerturbValue} type={"number"} onChange={handleInputChange} /> */}
              </GridColumn>
              <GridColumn>
                <label htmlFor="PerturbValueOH">
                  Perturb (OH)
                  <span>
                    <Popup
                      className="darkPopup"
                      position="top center"
                      content="Value to perturb OH by if using OptimizeOH. Default value is 1.5."
                      trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                    />
                  </span>
                </label>
                <input
                  type="number"
                  className="darkFluidInput thin"
                  name="PerturbValueOH"
                  placeholder="Enter prior error OH..."
                  value={formState.PerturbValueOH}
                  onChange={handleInputChange}
                  step={0.1}
                />
                {/* <InputField className="thin" height={2.5} width={50} name={"PerturbValueOH"} value={formState.PerturbValueOH} type={"number"} onChange={handleInputChange} /> */}
              </GridColumn>
              <GridColumn>
                <label htmlFor="PerturbValueBCs">
                  Perturb (BCs)
                  <span>
                    <Popup
                      className="darkPopup"
                      position="top center"
                      content="Number of ppb to perturb emissions by for domain edges (North, South, East, West) if using OptimizeBCs. Default value is 10.0 ppb."
                      trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                    />
                  </span>
                </label>
                <input
                  type="number"
                  className="darkFluidInput thin"
                  name="PerturbValueBCs"
                  placeholder="Enter perterb value BCs..."
                  value={formState.PerturbValueBCs}
                  onChange={handleInputChange}
                  step={0.1}
                />
                {/* <InputField className="thin" height={2.5} width={50} name={"PerturbValueBCs"} value={formState.PerturbValueBCs} type={"number"} onChange={handleInputChange} /> */}
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn>
                <label>
                  Hourly CH4
                  <span>
                    <Popup
                      className="darkPopup"
                      position="top center"
                      content="Boolean to save out hourly diagnostics from GEOS-Chem. This output is used in satellite operators via post-processing. Default value is true."
                      trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                    />
                  </span>
                </label>
                <CheckboxInteractive name={"HourlyCH4"} onChange={handleInputChange} checked={formState.HourlyCH4} />
              </GridColumn>
              <GridColumn>
                <label>
                  Planeflight
                  <span>
                    <Popup
                      className="darkPopup"
                      position="top center"
                      hoverable
                      content={
                        <p>
                          Boolean to save out the planeflight diagnostic in GEOS-Chem. This output may be used to compare GEOS-Chem against planeflight data. The path to those data
                          must be specified in input.geos. See the{" "}
                          <a href="https://geos-chem.readthedocs.io/en/latest/gcclassic-user-guide/planeflight.html#planeflight-diagnostic" target="#">
                            planeflight diagnostic documentation
                          </a>{" "}
                          for details. Default value is false.
                        </p>
                      }
                      trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                    />
                  </span>
                </label>
                <CheckboxInteractive name={"PLANEFLIGHT"} onChange={handleInputChange} checked={formState.PLANEFLIGHT} />
              </GridColumn>
              <GridColumn>
                <label>
                  GOSAT
                  <span>
                    <Popup
                      className="darkPopup"
                      position="top center"
                      content="Boolean to turn on the GOSAT observation operator in GEOS-Chem. This will save out text files comparing GEOS-Chem to observations, but has to be manually incorporated into the IMI. Default value is false."
                      trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                    />
                  </span>
                </label>
                <CheckboxInteractive name={"GOSAT"} onChange={handleInputChange} checked={formState.GOSAT} />
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn>
                <label>
                  TCCON
                  <span>
                    <Popup
                      className="darkPopup"
                      position="top center"
                      content="Boolean to turn on the TCCON observation operator in GEOS-Chem. This will save out text files comparing GEOS-Chem to observations, but has to be manually incorporated into the IMI. Default value is false."
                      trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                    />
                  </span>
                </label>
                <CheckboxInteractive name={"TCCON"} onChange={handleInputChange} checked={formState.TCCON} />
              </GridColumn>
              <GridColumn>
                <label>
                  AIRS
                  <span>
                    <Popup
                      className="darkPopup"
                      position="top center"
                      content="Boolean to turn on the AIRS observation operator in GEOS-Chem. This will save out text files comparing GEOS-Chem to observations, but has to be manually incorporated into the IMI. Default value is false."
                      trigger={<Icon className="infoIconForm" name="info" color="grey" circular inverted />}
                    />
                  </span>
                </label>
                <CheckboxInteractive name={"AIRS"} onChange={handleInputChange} checked={formState.AIRS} />
              </GridColumn>
            </GridRow>
          </Grid>
        );
      default:
        return <div>Default</div>;
    }
  };

  return (
    <>
      {React.cloneElement(triggerButton, { onClick: () => setOpen(true) })}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Modal.Header>
          Advanced Inversion Settings
          <span>
            <Icon name="setting" style={{ marginLeft: "5px" }} />
          </span>
        </Modal.Header>
        <Modal.Content className="advancedModalContent">
          <div style={{ display: "flex", justifyContent: "center", height: "400px" }}>
            <div className="advancedModalLeft">
              <ul>
                <li className={activeItem === "Inversion Parameters" ? "active" : ""} onClick={(e) => setActiveItem(e.target.textContent)}>
                  <Icon name="redo" color="white" />
                  Inversion Parameters
                </li>
                <li className={activeItem === "Kalman Filter Options" ? "active" : ""} onClick={(e) => setActiveItem(e.target.textContent)}>
                  <Icon name="chart line" color="white" />
                  Kalman Filter Options
                </li>
                <li className={activeItem === "State Vector / Point Sources" ? "active" : ""} onClick={(e) => setActiveItem(e.target.textContent)}>
                  <Icon name="grid layout" color="white" />
                  State Vector / Point Sources
                </li>
                <li className={activeItem === "Clustering Options" ? "active" : ""} onClick={(e) => setActiveItem(e.target.textContent)}>
                  <Icon name="object group outline" color="white" />
                  Clustering Options
                </li>
                <li className={activeItem === "Advanced GEOS-Chem Configuration" ? "active" : ""} onClick={(e) => setActiveItem(e.target.textContent)}>
                  <Icon name="plus" color="white" />
                  Advanced GEOS-Chem Configuration
                </li>
              </ul>
            </div>
            <div className="advancedModalRight">{renderContent()}</div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal}>Close</Button>
          <Button onClick={closeModal} style={{ backgroundColor: "var(--dark-yellow)", color: "white" }}>
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default AdvancedSettingsModal;
