import React from "react";
import { Container, Header, Segment, Grid, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";

const PricingPage = () => {
  return (
    <div className="pageWrapper" style={{ textAlign: "center" }}>
      <h1>Current Pricing</h1>
      <hr style={{ width: "50%" }} />
      <div style={{ textAlign: "center", width: "50%", marginLeft: "25%" }}>
        <div>
          <h3>
            Integral Earth is available for free during closed beta testing. 
          </h3>
          <br />
          <br />
          <h3>Future pricing will depend on configuration parameters and requested region size.</h3>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
