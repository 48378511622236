import React, { useEffect, useState } from "react";
import { Modal, Button, Message, Loader, Icon } from "semantic-ui-react";
import { MapContainer, TileLayer, GeoJSON, useMap } from "react-leaflet";
import "semantic-ui-css/semantic.min.css";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { set } from "date-fns";

const InversionReviewModal = ({ open, onClose, formState, run_type, geojson, geojson_area, submit_form, imiRegion, outOfRegion, check_job_count }) => {
  function formatDate(dateStr) {
    // Convert the string to a Date object
    const year = dateStr.slice(0, 4);
    const month = dateStr.slice(4, 6) - 1; // Subtract 1 because months are 0-indexed
    const day = dateStr.slice(6, 8);

    const date = new Date(year, month, day);

    // Format the date into M, D, YYYY
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  const [isLoading, setIsLoading] = useState(false);
  const [exceededLimit, setExceededLimit] = useState(false);
  // const [outOfRegion, setOutOfRegion] = useState(false);

  const FitBounds = ({ geojsonSubmit }) => {
    const map = useMap();

    useEffect(() => {
      if (geojsonSubmit) {
        const bounds = L.geoJSON(geojsonSubmit).getBounds();
        map.fitBounds(bounds);
      }
    }, [geojsonSubmit, map]);

    return null;
  };

  // Check if the roi is outside regions
  // useEffect(() => {
  //   fetch(`/api/return_imi_regions_route/?resolution=${formState.Res}`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //     },
  //     body: JSON.stringify(geojson),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log("Regions:", data);
  //       if (data.regions.length === 0) {
  //         setOutOfRegion(true);
  //       } else {
  //         setImiRegion(data.regions);

  //         setOutOfRegion(false);
  //       }
  //     });
  // }, [geojson, formState]);

  const handleSubmit = async () => {
    setIsLoading(true);
    // if (!check_job_count()) {
    //   setIsLoading(false);
    //   setExceededLimit(true);
    //   return;
    // }
    try {
      await submit_form(); // Ensure this is awaited
    } catch (error) {
      console.error("Error during form submission:", error);
    } finally {
      setIsLoading(false); // This will only run after the form submission is complete
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>Review Job Submission</Modal.Header>
      <Modal.Content>
        <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center" }}>
          <div style={{ width: "48%", marginTop: "2.5rem", paddingLeft: "1.5rem" }}>
            <p>
              Run Name // <strong>{formState.RunName}</strong>
            </p>
            <p>Time Range //</p>
            <p style={{ marginLeft: "1.5rem" }}>
              Start Date: <strong>{formatDate(formState.StartDate)}</strong>
            </p>
            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="100" viewBox="0 0 28 237" style={{ marginLeft: "20%" }}>
              <defs>
                <clipPath id="clip-Artboard_1">
                  <rect width="28" height="237" />
                </clipPath>
              </defs>
              <g id="Artboard_1" data-name="Artboard – 1" clipPath="url(#clip-Artboard_1)">
                <g id="Group_78" data-name="Group 78" transform="translate(74 -60) rotate(90)">
                  <line id="Line_40" data-name="Line 40" x2="202.645" transform="translate(73.622 59.623)" fill="none" stroke="#fff" strokeWidth="3" />
                  <circle id="Ellipse_37" data-name="Ellipse 37" cx="10.5" cy="10.5" r="10.5" transform="translate(63 49)" fill="var(--dark-yellow)" />
                  <circle id="Ellipse_38" data-name="Ellipse 38" cx="10.5" cy="10.5" r="10.5" transform="translate(269 49)" fill="var(--dark-yellow)" />
                </g>
              </g>
            </svg>
            <p style={{ marginLeft: "1.5rem" }}>
              End Date: <strong>{formatDate(formState.EndDate)}</strong>
            </p>
            <p>
              Run Type // <strong>{run_type === "preview" ? "Preview" : "Full Run"}</strong>
            </p>
            <p>
              Requested Domain Area // <strong>{geojson_area.toFixed(2)} km2</strong>
            </p>
          </div>
          <div style={{ width: "48%" }}>
            <MapContainer style={{ height: "400px", width: "100%", borderRadius: "10px", border: "solid white 2px" }} center={[51.505, -0.09]} zoom={13}>
              <TileLayer
                url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=e06c3b94-e393-4722-bd75-8257a760dcfb"
                attribution='&copy; <a href="https://www.stadiamaps.com/">Stadia Maps</a> contributors &copy; <a href="https://www.stadiamaps.com/">Stadia Maps</a> contributors &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> contributors &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <GeoJSON
                data={geojson}
                style={{
                  color: "var(--dark-yellow)", // Polygon border color
                  fillColor: "var(--dark-yellow)", // Polygon fill color
                  fillOpacity: 0.3, // Fill opacity
                  weight: 3, // Border thickness
                }}
              />
              <FitBounds geojsonSubmit={geojson} />
            </MapContainer>
          </div>
        </div>
        <Message warning hidden={!exceededLimit}>
          <Message.Header>Warning</Message.Header>
          <p>Exceeded maximum number of jobs allowed. Please message the development team to increase your limit.</p>
        </Message>
        <Message warning hidden={!outOfRegion}>
          <div className="centerHorizontally" style={{ alignItems: "center", gap: "1.5rem" }}>
            <Icon name="warning sign" size="big" color="yellow" />
            <div>
              <Message.Header>Warning</Message.Header>
              <p>
                Selected ROI is out of defined nested IMI meteorological regions at selected resolution of {formState.Res}. Global fields will be used, which will significantly
                reduce computational efficiency.
              </p>
            </div>
          </div>
        </Message>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Return and Edit</Button>
        <Button
          color="yellow"
          onClick={() => {
            handleSubmit();
          }}
          disabled={isLoading}
        >
          Submit for Approval
        </Button>
        {isLoading && <Loader active inline />}
      </Modal.Actions>
    </Modal>
  );
};

export default InversionReviewModal;
