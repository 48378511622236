import React from "react";
import { Input } from "semantic-ui-react";
import styled from "styled-components";

const CustomInput = styled(Input)`
  &&& {
    input {
      background-color: rgba(100, 100, 100, 0.8) !important;
      border: solid var(--dark-yellow) 1px;
      border-radius: 4px;
      padding: 10px;
      width: ${(props) => (props.width ? props.width + "%" : "100%")} !important;
      height: ${(props) => (props.height ? props.height + "rem" : "2.5rem")} !important;
      font-size: 1rem;
      color: white;
      opacity: ${(props) => (props.disabled ? 0.5 : 1)};
      transition: all 300ms ease-in-out;
    }
  }
`;

interface InputFieldProps {
  className?: string;
  disabled?: boolean;
  placeholder?: string | number;
  name: string;
  width?: number;
  height?: number;
  icon?: string;
  value: any;
  type?: string;
  step?: number;
  onChange: (e: any) => void;
}

const InputField: React.FC<InputFieldProps> = ({ className, disabled, placeholder, name, value, width, height, icon, type = "string", step = 0.1, onChange }) => {
  return (
    <CustomInput
      className={className}
      disabled={disabled}
      placeholder={placeholder}
      name={name}
      width={width}
      height={height}
      icon={icon}
      value={value}
      type={type}
      step={step}
      onChange={(e: any) => onChange(e)}
    />
  );
};

export default InputField;
