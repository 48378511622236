import React, { useState, useEffect, useRef, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext.js";
import { redirect, useParams, useNavigate } from 'react-router-dom';
import "./previewDash.css";
import { Grid, GridColumn, GridRow, Image, Button, Modal, Loader, Icon, Popup } from "semantic-ui-react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// @ts-ignore
import PreviewDashInfoCard from '../../Cards/PreviewDashInfoCard/PreviewDashInfoCard.tsx';

interface User {
  id: number;
  email: string;
  username: string;
  name?: string;
  is_admin: string;
}

interface FormatDate {
  (date: string): string;
}

interface AuthContextInterface {
  user: User;
  formatDate: FormatDate;
}

function PreviewDash() {

  const navigate = useNavigate();

  const { runID } = useParams<{ runID: string }>();
  const { user, formatDate } = useContext(AuthContext) as AuthContextInterface;

  const [runName, setRunName] = useState<string>("");

  const [imageURL, setImageURL] = useState<string[]>([]);
  const [imageName, setImageName] = useState<string[]>([]);
  const [diagnosticInfo, setDiagnosticInfo] = useState<{}>({});
  const [configObj, setConfigObj] = useState<{ [key: string]: any }>({});
  const [jobObj, setJobObj] = useState<{ [key: string]: any }>({});
  const [isSliding, setIsSliding] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const downloadRef = useRef<HTMLDivElement>(null);
  const [submitFullModalOpen, setSubmitFullModalOpen] = useState<boolean>(false);
  const [runSubmittedSuccessfully, setRunSubmittedSuccessfully] = useState<boolean>(false);
  const [runSubmitError, setRunSubmitError] = useState<boolean>(false);

  const placeholderImage = "https://react.semantic-ui.com/images/wireframe/image.png";

  // TODO: Change to make dynamic
  useEffect(() => {
    setIsLoading(true);

    // check user access
    fetch(`/api/check_user_access/?user_id=${user.id}&job_id=${runID}`, {
      method: "GET",
    }).then((response) => response.json())
      .then((data) => {
        console.log(data)

        if (data.error) {
          console.log("User does not have access to this job")
          setIsLoading(false);
          navigate('/runs')
        } else {
          console.log("User has access to this job")
        }
        setIsLoading(false);

      }).catch((error) => {
        console.error('Error:', error);
      })

    fetch(
      `/api/preview_fetch/?jobID=${runID}`,
      {
        method: "GET",
      }).then((response) => response.json())
      .then((data) => {

        let url_array: string[] = []
        let name_array: string[] = []

        for (const img of data['image_data_list']) {
          const url = `data:image/png;base64,${img.base64_image}`;
          url_array.push(url)
          name_array.push(img.file_name)

        }

        setImageURL(url_array);
        setImageName(name_array);
        setRunName(data['run_name']);
        setDiagnosticInfo(data['diagnostics'])
        setConfigObj(data['config_obj'])
        setJobObj(data['job_obj'])
        setIsLoading(false);


      }).catch((error) => {

        console.error('Error:', error);
      })
  }, []);

  const handleDownload = () => {
    const activeImage = document.querySelector('.swiper-slide-active') as HTMLImageElement;
    const activeImageName = activeImage.getAttribute('data-name') as string;
    const activeImageURL = activeImage.getAttribute('data-src') as string;
    // url:string, image_name:string
    const link = document.createElement('a');
    link.href = activeImageURL;

    // // Specify the file name
    link.download = activeImageName;

    link.click();
  };

  const handleImageClick = (url: string) => {
    setSelectedImage(url);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  // Submit for full inversion
  const handleSubmitFull = () => {

    setIsLoading(true);

    // Update job status to submitted, this sends the job to the admin dashboard for second approval
    fetch(`/api/update/?table=job&id=${runID}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify({
        run_stage: "FULL",
        status: "SUBMITTED"
      }),
    }).then((response) => response.json())
      .then((data) => {
        console.log(data)
        if (data.message) {
          console.log("Run submitted successfully");
          setRunSubmittedSuccessfully(true);
          setSubmitFullModalOpen(false);
          setIsLoading(false);
          window.location.reload();
        } else {
          console.log("Run submit error!");
          setRunSubmittedSuccessfully(false);
          setRunSubmitError(true);
          setIsLoading(false);
        }
      }).catch((error) => {
        console.error('Error:', error);
        setRunSubmitError(true);
        setIsLoading(false);
      })

  }

  return (
    <div className="pageWrapper" style={{ marginTop: "-20px" }}>
      {Object.keys(diagnosticInfo).length != 0 ? (
        <>
          <h3 style={{ textAlign: "center", fontFamily: "AvenirLight" }}>&#123;&nbsp;{runName}&nbsp;&#125;</h3>
          <div className="centerHorizontally">
            <div className='centerHorizontally' style={{ gap: "-10px" }}>
              <p style={{ paddingTop: "5px" }}>{Object.keys(configObj).length > 0 ? formatDate(configObj.StartDate) : null}</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="200" height="30" viewBox="0 0 242 30" style={{ transform: "scale(0.5)" }}>
                <defs>
                  <clipPath id="clip-Artboard_1">
                    <rect width="242" height="30" />
                  </clipPath>
                </defs>
                <g id="Artboard_1" data-name="Artboard – 1" clipPath="url(#clip-Artboard_1)">
                  <g id="Group_78" data-name="Group 78" transform="translate(297 74) rotate(180)">
                    <line id="Line_40" data-name="Line 40" x2="202.645" transform="translate(73.622 59.623)" fill="none" stroke="#fff" strokeWidth="3" />
                    <circle id="Ellipse_37" data-name="Ellipse 37" cx="10.5" cy="10.5" r="10.5" transform="translate(63 49)" fill="var(--dark-yellow)" />
                    <circle id="Ellipse_38" data-name="Ellipse 38" cx="10.5" cy="10.5" r="10.5" transform="translate(269 49)" fill="var(--dark-yellow)" />
                  </g>
                </g>
              </svg>
              <p style={{ paddingTop: "5px" }}>{Object.keys(configObj).length > 0 ? formatDate(configObj.EndDate) : null}</p>
            </div>
          </div>
          {jobObj.run_stage !== "FULL" ? <div className="centerHorizontally" style={{ marginLeft: "2rem" }}>
            <Popup className="darkPopup" content="Submit for full inversion" trigger={<Icon name="check" color="green" size="large" circular inverted onClick={() => setSubmitFullModalOpen(!submitFullModalOpen)} />} />
          </div>
            : jobObj.run_stage === "FULL" && jobObj.status === "SUBMITTED" ?
              <div className="centerHorizontally" style={{ marginLeft: "2rem" }}>
                <Popup className="darkPopup" content="Full inversion submitted for validation. Check job status page for updates." trigger={<Icon name="hourglass outline" color="yellow" size="large" circular inverted />} />
              </div>
              : jobObj.run_stage === "FULL" && jobObj.status === "RUNNING" ?
                <div className="centerHorizontally" style={{ marginLeft: "2rem" }}>
                  <Popup className="darkPopup" content="Full inversion running. Check job status page for updates." trigger={<a href="/runs"><Icon name="sync alternate" color="green" size="large" style={{ animation: "spin 3s infinite" }} circular inverted /></a>} />
                </div>
                : null

          }
          <hr style={{ width: '30%' }} />
          <Grid>
            <GridRow columns={4} style={{ height: "400px" }} className="centerVertical">
              {Object.keys(diagnosticInfo).length !== 0 && Object.keys(diagnosticInfo).map((key, index) => (
                <GridColumn key={index}>
                  <PreviewDashInfoCard title={key} content={diagnosticInfo[key]} />
                </GridColumn>
              ))}
            </GridRow>
            <GridRow columns={1} style={{ height: "450px" }}>
              <GridColumn style={{ height: "500px" }}>
                <Swiper
                  className="custom-swiper"
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={50}
                  slidesPerView={3}
                  centeredSlides={true}
                  navigation
                  initialSlide={2}
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                  onSlideChangeTransitionStart={() => setIsSliding(true)}
                  onSlideChangeTransitionEnd={() => setIsSliding(false)}
                  style={{ paddingBottom: '20px', paddingTop: '30px' }}
                >
                  {imageURL.length === 0 ?
                    Array.from({ length: 4 }).map((_, index) => (
                      <SwiperSlide key={index}>
                        <Image
                          src={placeholderImage}
                          style={{ borderRadius: '5px' }}
                        />
                      </SwiperSlide>
                    ))
                    :
                    <>
                      {imageURL.map((url, index) => (
                        <SwiperSlide data-src={url} data-name={imageName[index]} key={index}>
                          <Image src={url} data-name={imageName[index]} style={{ borderRadius: '15px', marginBottom: '20px', cursor: "pointer" }} alt={imageName[index]} onClick={() => handleImageClick(url)} />
                        </SwiperSlide>
                      ))}
                    </>
                  }
                  {(isSliding || imageURL.length === 0) ? null : <Popup className="darkPopup" content="Download plot as .png" trigger={<Icon name="download" id="downloadButtonPreview" circular inverted color="grey" onClick={handleDownload} />} />}
                </Swiper>
              </GridColumn>
            </GridRow>
          </Grid>

          {/* Image modal */}
          <Modal open={isModalOpen} onClose={handleCloseModal} size='large'>
            <Modal.Content>
              <Image src={selectedImage} style={{ width: '100%', height: 'auto', borderRadius: '15px' }} />
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={handleCloseModal}>Close</Button>
            </Modal.Actions>
          </Modal>
          {/* Submit full run modal */}
          <Modal open={submitFullModalOpen} onClose={handleCloseModal} size='large'>
            <Modal.Header>Submit for Full Inversion</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <p>Are you sure you want to submit this run for full inversion?</p>
                <Button onClick={() => setSubmitFullModalOpen(false)}>No</Button>
                <Button onClick={() => handleSubmitFull()} positive disabled={isLoading}>Yes</Button>
                {isLoading && <Loader active={isLoading} inline />}
              </Modal.Description>
            </Modal.Content>
            {/* <Modal.Actions>
            </Modal.Actions> */}
          </Modal>
        </>
      ) : (
        <Loader active size='huge'>Loading preview data...</Loader>
      )}
    </div>
  );
}

export default PreviewDash;