import "./App.css";

// Actual Pages
import NavBar from "./components/Nav/navBar.tsx";
import Footer from "./components/Footer/Footer.jsx";
import AdminPortal from "./components/AdminPortal/AdminPortal.jsx";
import HomePage from "./components/HomePage/HomePage.tsx";
import ResourcesPage from "./components/ResourcesPage/ResourcesPage.jsx";
import PricingPage from "./components/PricingPage/PricingPage.jsx";
import FullInversionDash from "./components/Dashboard/Full/fullInversionDash.tsx";
import RunDisplay from "./components/RunDisplay/RunDisplay.tsx";
import AccountSettings from "./components/AccountSettings/accountSettings.jsx";
import SignInUp from "./components/SignIn-SignUp/SignIn-Up.tsx";
import InversionForm from "./components/Form/InversionForm.tsx";
import AboutPage from "./components/AboutPage/AboutPage.jsx";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword.jsx";

import NotFound from "./components/NotFound.jsx";
import MobileCheck from "./components/MobileCheck.jsx";

// Unused Pages
import TestingPage from "./components/TestingPage/testingPage";
import ParallaxPlot from "./components/parallax_testing.jsx";
import MyMap from "./components/UnusedComponents/Sandbox.tsx";
import InteractiveBounds from "./components/interactiveBounds.jsx";
import BlurredCircles from "./components/blurredCircles.jsx";
import PreviewDash from "./components/Dashboard/Preview/previewDash.tsx";

import BoundsModal from "./components/BoundsModal.jsx";
import OverlapTest from "./components/UnusedComponents/testingOverlap.jsx";

import ProtectedRoute from "./components/ProtectedRoute.jsx";
import AuthRedirect from "./components/AuthRedirect.jsx";

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";

function App() {
  const [loggedIn, setLoggedIn] = useState(true);
  const [activePage, setActivePage] = useState("");

  const toggleSignIn = () => {
    setLoggedIn(!loggedIn);
  };

  const colors = [
    "#F7B70B", //dark yellow
    "#CB846E", //dusty rose
    "#92A9B7", //slate blue
    "#5E7F70", //sage green
    "#735E82", //mild valender
    "#848482", //mid grey
  ];

  let circleOpacity = 0.3;

  return (
    <>
      <MobileCheck>
        <Router>
          <div className="App">
            {/* toggleSignIn function used for testing, prop drilled to standard Buttons */}
            <NavBar loggedIn={loggedIn} toggleSignIn={toggleSignIn} activePage={activePage} />
            <Routes>
              <Route path="/" element={<HomePage onMount={() => setActivePage("Home")} />} />
              <Route
                path="/dashboardPreview/:runID"
                element={
                  <ProtectedRoute>
                    <PreviewDash onMount={() => setActivePage("My Data")} />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/dashboardFull/:runID"
                element={
                  <ProtectedRoute>
                    <FullInversionDash />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/runs"
                element={
                  <ProtectedRoute>
                    <RunDisplay onMount={() => setActivePage("My Data")} />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/accountSettings"
                element={
                  <ProtectedRoute>
                    <AccountSettings />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/admin"
                element={
                  <ProtectedRoute requireAdmin={true}>
                    <AdminPortal onMount={() => setActivePage("View User Runs")} />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/submitinversion"
                element={
                  <ProtectedRoute>
                    <InversionForm />
                  </ProtectedRoute>
                }
              />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/resources" element={<ResourcesPage />} />
              <Route path="/about" element={<AboutPage />} />

              {/* Prevent user from accessing sign in page if they're already signed in / redirect to home  */}
              <Route
                path="/signin"
                element={
                  <AuthRedirect>
                    <SignInUp />
                  </AuthRedirect>
                }
              />

              <Route path="/forgotpassword/:userEmail" element={<ForgotPassword />} />

              {/* Unused routes */}
              {/* <Route path="/testing" element={<BoundsModal />} />
              <Route path="/overlap" element={<OverlapTest />} />
              <Route path="/add" element={<TestingPage />} />
              <Route path="/bounds" element={<InteractiveBounds />} />
              <Route path="/sandbox" element={<MyMap />} />
              <Route path="/parallax" element={<ParallaxPlot />} /> */}

              {/* Catch-all route for undefined paths */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <Footer />
        </Router>
        <svg className="background-svg" width="800" height="800" viewBox="0 0 800 800" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <filter id="blur" x="-50%" y="-50%" width="200%" height="200%">
              <feGaussianBlur in="SourceGraphic" stdDeviation="25" />
            </filter>
          </defs>

          {/* dark yellow */}
          <circle cx="20vw" cy="50vh" r="300" fill={colors[0]} filter="url(#blur)" opacity={circleOpacity} />

          {/* dusty rose */}
          <circle cx="30vw" cy="150" r="250" fill={colors[1]} filter="url(#blur)" opacity={circleOpacity} />

          {/* slate blue */}
          <circle cx="-10vw" cy="30vh" r="180" fill={colors[2]} filter="url(#blur)" opacity={circleOpacity} />

          {/* sage green */}
          <circle cx="-5vw" cy="65vh" r="190" fill={colors[3]} filter="url(#blur)" opacity={circleOpacity} />

          {/* mid lavendar */}
          <circle cx="40vw" cy="60vh" r="210" fill={colors[4]} filter="url(#blur)" opacity={circleOpacity} />

          {/* mid grey */}
          <circle cx="100" cy="0" r="190" fill={colors[5]} filter="url(#blur)" opacity={circleOpacity} />
        </svg>
      </MobileCheck>
    </>
  );
}

export default App;
