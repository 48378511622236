import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { AuthContext } from "../../contexts/AuthContext";
import { Icon, Input, Button, Popup } from "semantic-ui-react";

const AccountSettings = () => {
  const { register, user, login, logout } = useContext(AuthContext);

  const [changePassword, setChangePassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [response, setResponse] = useState("");

  const handlePasswordChange = async () => {
    try {
      fetch(`/api/update/?table=user&id=${user.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          current_password: currentPassword,
          password: newPassword1,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          if (data.hasOwnProperty("success")) {
            setResponse("success");
            setChangePassword(false);
          } else {
            setResponse("error");
          }
        })
        .catch((error) => {
          setResponse("error");
          console.error("Error updating password:", error);
        });
    } catch (error) {
      console.error("Error updating password:", error);
    }
  };

  const requestEmailChange = async () => {
    // TODO: Implement email change request
  };

  return (
    <div className="pageWrapper">
      <label htmlFor="email">Current Email</label>
      <Input type="text" placeholder={user.email} disabled width={16} />
      <Popup
        content="Request email change"
        trigger={<Icon name="pencil alternate" circular inverted color="grey" style={{ marginLeft: "1.5rem", cursor: "pointer" }} />}
        onClick={() => {
          requestEmailChange();
        }}
      />
      <br />
      <br />
      {!changePassword ? (
        <Button onClick={() => setChangePassword(true)}>Change Password</Button>
      ) : (
        <>
          <label style={{ marginTop: "1.5rem" }} htmlFor="password">
            Current Password
          </label>
          <input className = "darkFluidInput" onChange={(e) => setCurrentPassword(e.target.value)} placeholder="Current Password" icon="lock" />
          <label style={{ marginTop: "1.5rem" }} htmlFor="password">
            New Password
          </label>
          <input className = "darkFluidInput" onChange={(e) => setNewPassword1(e.target.value)} placeholder="New Password" icon="lock" />
          <br />
          <label style={{ marginTop: "1.5rem" }} htmlFor="confirmPassword">
            Confirm New Password
          </label>
          <input className = "darkFluidInput" type="password" onChange={(e) => setNewPassword2(e.target.value)} placeholder="Confirm New Password" icon="lock" />
          <br />
          <Button
            style={{ marginTop: "1.5rem" }}
            color="yellow"
            disabled={newPassword1 != newPassword2 || [currentPassword, newPassword1, newPassword2].includes("")}
            onClick={handlePasswordChange}
          >
            Submit
          </Button>
        </>
      )}
      {response === "success" && <p style={{ color: "rgb(38, 193, 38)", marginTop: "1.2rem" }}><span><Icon name = "check"/></span>Password updated successfully!</p>}
      {response === "error" && <p style={{ color: "red", marginTop: "1.2rem" }}><span><Icon name = "close" color="red"/></span>There was an error updating your password. Please try again.</p>}
    </div>
  );
};

export default AccountSettings;
