import React, { useState, useRef, useEffect } from "react";
import { MapContainer, TileLayer, FeatureGroup, useMap, GeoJSON } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import L from "leaflet";
import { Button, Message, ButtonContent, Icon, Popup } from "semantic-ui-react";
import * as turf from "@turf/turf"; // Import turf.js for geometry calculations

const InteractiveBounds = ({ backButton, submitButton, setArea, setEstPixels, setGeo, globalGeojson, resolution }) => {
  const [geojson, setGeojson] = useState(null);
  const [globalGeojsonAdded, setGlobalGeojsonAdded] = useState(false);

  const [warning, setWarning] = useState(false);
  const [oceansWarning, setOceansWarning] = useState(false);
  const [oceansError, setOceansError] = useState(false);
  const [areaLocal, setAreaLocal] = useState(0.0);
  const [imiRegions, setImiRegions] = useState(null);
  const [filteredRegions, setFilteredRegions] = useState(null);

  const [viewIMIRegions, setViewIMIRegions] = useState(false);
  const mapRef = useRef(null);
  const featureGroupRef = useRef(null);

  let pixelArea = resolution === "0.25x0.3125" ? 25 * 30 : 55 * 60;

  const FitBounds = ({ globalGeojson }) => {
    const map = useMap();

    useEffect(() => {
      if (globalGeojson && featureGroupRef.current && !globalGeojsonAdded) {
        // Remove any existing layers to prevent duplication
        featureGroupRef.current.clearLayers();

        // Create the geoJSON layer and add it to the feature group
        const geoJsonLayer = L.geoJSON(globalGeojson);

        // Add each feature from geoJsonLayer to FeatureGroup, converting them to layers managed by EditControl
        geoJsonLayer.eachLayer((layer) => {
          const latLngs = layer.getLatLngs();
          const newLayer = L.polygon(latLngs, {
            color: "var(--dark-yellow)", // Polygon border color
            fillColor: "var(--dark-yellow)", // Polygon fill color
            fillOpacity: 0.3, // Fill opacity
            weight: 3, // Border thickness
          });

          // Add each converted layer to FeatureGroup
          featureGroupRef.current.addLayer(newLayer);
        });

        // Calculate the area of the globalGeojson using turf.js
        const globalGeojsonArea = turf.area(globalGeojson); // Area in square meters
        const areaInSquareKilometers = globalGeojsonArea / 1e6; // Convert to square kilometers
        setAreaLocal(areaInSquareKilometers); // Update the areaLocal state

        // Fit the bounds of the map to the new geoJSON layer
        const bounds = geoJsonLayer.getBounds();
        if (bounds.isValid()) {
          map.fitBounds(bounds);
        }

        setGlobalGeojsonAdded(true); // Set globalGeojsonAdded to true
        setGeojson(globalGeojson); // Update state with the new globalGeojson
      }
    }, [globalGeojson, map]);

    return null;
  };

  // Fetch IMI regions
  useEffect(() => {
    fetch("/api/return_imi_regions_geo/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setImiRegions(data);

        // Filter the regions based on selected resolution
        const filtered = {
          ...data,
          features: data.features.filter((feature) => feature.properties.Resolution.replace(/ /g, "") === resolution),
        };
        setFilteredRegions(filtered); // Store filtered regions in state
      })
      .catch((error) => {
        console.error("Error fetching IMI regions:", error);
      });
  }, [resolution]); // Re-run when selectedResolution changes

  const handleCreatedShape = (e) => {
    if (featureGroupRef.current.getLayers().length > 1) {
      setWarning(true);
      // Remove the newly created layer if another shape already exists
      featureGroupRef.current.removeLayer(e.layer);
    } else {
      const layer = e.layer;
      const latLngs = layer.getLatLngs()[0];
      const coordinates = latLngs.map((latLng) => [latLng.lng, latLng.lat]);

      // Ensure the polygon is closed by repeating the first coordinate at the end
      if (coordinates.length > 0 && coordinates[0] !== coordinates[coordinates.length - 1]) {
        coordinates.push(coordinates[0]);
      }

      const geojson = layer.toGeoJSON();

      // Use turf.js to calculate the area in square meters
      const areaInSquareMeters = turf.area(geojson);

      // Convert square meters to square kilometers
      const areaInSquareKilometers = areaInSquareMeters / 1e6;

      setArea(areaInSquareKilometers);
      setAreaLocal(areaInSquareKilometers);

      const geojsonObj = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [coordinates],
        },
        properties: {},
      };

      setGeojson(geojsonObj); // Update the geojson state
    }
  };

  // Handle shape edits
  const handleEdit = () => {
    let totalArea = 0; // Track the total area after edits

    if (featureGroupRef.current) {
      featureGroupRef.current.eachLayer((layer) => {
        if (layer && layer.toGeoJSON) {
          const latLngs = layer.getLatLngs()[0];
          const coordinates = latLngs.map((latLng) => [latLng.lng, latLng.lat]);

          // Ensure the polygon is closed by repeating the first coordinate at the end
          if (coordinates.length > 0 && coordinates[0] !== coordinates[coordinates.length - 1]) {
            coordinates.push(coordinates[0]);
          }

          const geojson = layer.toGeoJSON();

          // Use turf.js to calculate the area in square meters
          const areaInSquareMeters = turf.area(geojson);

          // Convert square meters to square kilometers
          const areaInSquareKilometers = areaInSquareMeters / 1e6;

          totalArea += areaInSquareKilometers;

          const geojsonObj = {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [coordinates],
            },
            properties: {},
          };

          setGeojson(geojsonObj); // Update the geojson state with the latest edited feature
        }
      });

      setArea(totalArea); // Update area in parent component
      setAreaLocal(totalArea); // Update local area state
    } else {
      console.error("FeatureGroup ref is not available.");
    }
  };

  const handleDeletedShape = () => {
    setGeojson(null);
    setWarning(false);
    setOceansWarning(false);
    setAreaLocal(0.0);
  };

  const handleSubmit = () => {
    setArea(areaLocal);
    setGeo(geojson);
    setEstPixels(Math.floor(areaLocal / pixelArea));
  };

  // Function to handle each GeoJSON feature and bind a popup with the Resolution property
  const onEachFeature = (feature, layer) => {
    if (feature.properties && feature.properties.Resolution) {
      const popupContent = `
        <strong>Region:</strong> ${feature.properties.regionName}<br/>
        <strong>Resolution:</strong> ${feature.properties.Resolution}
      `;
      layer.bindPopup(popupContent);
    }
  };

  return (
    <>
      <MapContainer style={{ width: "90%", height: "425px", marginLeft: "5%", borderRadius: "10px", border: "solid white 2px", position: "relative" }} center={[43, -88]} zoom={3}>
        <TileLayer
          url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=e06c3b94-e393-4722-bd75-8257a760dcfb"
          attribution='&copy; <a href="https://www.stadiamaps.com/">Stadia Maps</a> contributors &copy; <a href="https://www.stadiamaps.com/">Stadia Maps</a> contributors &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> contributors &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <FeatureGroup ref={featureGroupRef} style={{ zIndex: 100 }}>
          <EditControl
            position="topright"
            onCreated={handleCreatedShape}
            onDeleted={handleDeletedShape}
            onEdited={handleEdit}
            draw={{
              polygon: {
                allowIntersection: false,
                showArea: true,
                metric: ["km"],
                shapeOptions: {
                  color: "var(--dark-yellow)", // Polygon border color
                  fillColor: "var(--dark-yellow)", // Polygon fill color
                  fillOpacity: 0.3, // Fill opacity
                  weight: 3, // Border thickness
                },
              },
              rectangle: {
                showArea: true,
                metric: ["km"],
                shapeOptions: {
                  color: "var(--dark-yellow)", // Polygon border color
                  fillColor: "var(--dark-yellow)", // Polygon fill color
                  fillOpacity: 0.3, // Fill opacity
                  weight: 3, // Border thickness
                },
              },
              polyline: false,
              circle: false,
              marker: false,
              circlemarker: false,
            }}
          />
        </FeatureGroup>

        {globalGeojson && <FitBounds globalGeojson={globalGeojson} />}
        {filteredRegions && viewIMIRegions && (
          <GeoJSON
            data={filteredRegions}
            style={{
              color: "var(--dark-yellow)", // Polygon border color
              fillColor: "var(--dark-yellow)", // Polygon fill color
              fillOpacity: 0.05, // Fill opacity
              weight: 1, // Border thickness
              zIndex: 1,
            }}
            onEachFeature={onEachFeature}
          />
        )}
      </MapContainer>

      {warning && (
        <Message warning>
          <Message.Header>Only one shape is allowed</Message.Header>
          <p>You can only draw one shape on the map. Please delete the existing one before drawing a new shape.</p>
        </Message>
      )}
      {oceansError && (
        <Message warning>
          <Message.Header>Warning</Message.Header>
          <p>More than 50% of ROI is in the ocean. Please include more land in ROI.</p>
        </Message>
      )}
      {areaLocal > 0 && (
        <div className="centerHorizontally" style={{ margin: "20px", marginLeft: "40px", justifyContent: "left", gap: "30px" }}>
          <div>
            <strong>Area</strong>
            <p style={{ color: "var(--dark-yellow)" }}>{areaLocal.toFixed(2)} km²</p>
          </div>
          <div>
            <strong>Approx. # of {resolution === "0.25x0.3125" ? "25km²" : "55km²"} Emission Grid Cells in Inversion</strong>
            <p style={{ color: "var(--dark-yellow)" }}>{Math.floor(areaLocal / pixelArea)}</p>
          </div>
        </div>
      )}
      {areaLocal < 10000 && areaLocal !== 0.0 && (
        <Message warning>
          <Message.Header>Warning</Message.Header>
          <p>Area is less than 10,000 km². Please ensure that the area is large enough for inversion.</p>
        </Message>
      )}
      {areaLocal > 7000000 && areaLocal !== 0 && (
        <Message warning style={{ float: "right" }}>
          <Message.Header>Warning</Message.Header>
          <p>Area is more than 7,000,000 km². Please decrease region of interest size.</p>
        </Message>
      )}

      <Button animated onClick={backButton} style={{ margin: "15px" }}>
        <ButtonContent visible>Back</ButtonContent>
        <ButtonContent hidden>
          <Icon name="arrow left" />
        </ButtonContent>
      </Button>
      <Button disabled={!geojson || areaLocal < 10000 || oceansWarning || areaLocal > 7000000} onClick={handleSubmit} style={{ margin: "15px" }} color="blue">
        Submit
      </Button>
      <Popup
        content="View IMI meteorological regions. ROIs submitted outside of these regions will use global meteological fields, which will decrease run efficiency."
        className="darkPopup"
        trigger={
          <Icon
            id="viewRegions"
            style={{ transform: "scale(1.5)", marginLeft: "1.5rem", backgroundColor: viewIMIRegions ? "var(--dark-yellow) !important" : "var(--neutral-grey) !important" }}
            name="map"
            circular
            inverted
            color="grey"
            onClick={() => setViewIMIRegions(!viewIMIRegions)}
          />
        }
      />
    </>
  );
};

export default InteractiveBounds;
