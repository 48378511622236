import React, { useState, useEffect, useContext, useRef, RefObject } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext.js';
import type { SwiperRef } from 'swiper/react';
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Icon, Button, Grid, GridRow, GridColumn, Image, Modal, Loader, Dropdown, Popup as PopupSemantic, Message } from "semantic-ui-react";
import { MapContainer, TileLayer, Rectangle, useMap, GeoJSON, Popup } from "react-leaflet";
import L, { latLng, LatLngBounds, LatLngBoundsExpression, Map as LeafletMap } from "leaflet"; // Import Leaflet directly for utility functions
import "leaflet/dist/leaflet.css";
import './fullInversionDash.css';
// ts type for GeoJsonObject
import { GeoJsonObject } from 'geojson';
import Papa from 'papaparse';
import Plot from 'react-plotly.js';


// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { set } from 'date-fns';
import { booleanClockwise } from '@turf/turf';

interface User {
    id: number;
    email: string;
    username: string;
    name?: string;
    is_admin: string;
}

interface FormatDate {
    (date: string): string;
}

interface AuthContextInterface {
    user: User;
    formatDate: FormatDate;
    checkToken: () => boolean;
}

interface EmissionData {
    PriorEmissions: number;
    PosteriorEmissions: number;
    DOFS: number;
    BiasPrior: number;
    BiasPosterior: number;
    RMSEPrior: number;
    RMSEPosterior: number;
    NumSuperObservations: number;
}

const FullInversionDash = () => {

    const { runID } = useParams<{ runID: string }>();
    const isSampleCase = runID === "PermianBasin";
    const { user, formatDate, checkToken } = useContext(AuthContext) as AuthContextInterface;

    const navigate = useNavigate();

    const [jobData, setJobData] = useState<{}>({});
    const [configData, setConfigData] = useState<{} | any>({});
    const [plots, setPlots] = useState<string[]>([]);
    const [shapefileGeoJSON, setShapefileGeoJSON] = useState<GeoJsonObject | null>(null);
    const [vizData, setVizData] = useState<EmissionData | null>(null);
    const [priorEmissions, setPriorEmissions] = useState<number[]>([]);
    const [posteriorEmissions, setPosteriorEmissions] = useState<number[]>([]);

    const mapRef = useRef<LeafletMap>(null);
    const swiperRef = useRef<SwiperRef>(null);
    const resetBoundsRef = useRef<HTMLDivElement>(null);
    const downloadRef = useRef<HTMLDivElement>(null);

    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    const [plotNames, setPlotNames] = useState<string[]>([]);
    const [isSliding, setIsSliding] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [isConfigModalOpen, setIsConfigModalOpen] = useState<boolean>(false);

    const placeholderImage = "https://react.semantic-ui.com/images/wireframe/image.png";

    const [data, setData] = useState<EmissionData | null>(null)

    const [isKalmanMode, setIsKalmanMode] = useState<boolean>(false);
    const [periodData, setPeriodData] = useState<[] | any>([]);
    const [periodCount, setPeriodCount] = useState<number>(4);
    const [selectedPeriod, setSelectedPeriod] = useState<number>(1);
    const [priorEmissionsTimeline, setPriorEmissionsTimeline] = useState<number[]>([]);
    const [posteriorEmissionsTimeline, setPosteriorEmissionsTimeline] = useState<number[]>([]);

    const [notFound, setNotFound] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [loadingText, setLoadingText] = useState<string>("Loading inversion data, please wait...");


    // MAIN DATA FETCH
    useEffect(() => {

        let startTime = Date.now();
        const longTextThreshold = 10000; // 10 seconds
        const fetchDurationText = "Loading inversion dashboard...";
        const longFetchText = "Still loading...";

        // Set initial loading text and start timer
        setIsLoading(true);
        let elapsedTime = 0;
        let intervalId = setInterval(() => {
            elapsedTime = Date.now() - startTime;
            if (elapsedTime > longTextThreshold) {
                setLoadingText(longFetchText); // Change text if fetch takes longer than 10 seconds
            }
        }, 1000); // Check every second

        // Clean up function to clear the interval
        const stopTrackingTime = () => {
            clearInterval(intervalId);
            setIsLoading(false);
        };
        // check user access if not example run
        if (!isSampleCase) {
            fetch(`/api/check_user_access/?user_id=${user.id}&job_id=${runID}`, {
                method: "GET",
            }).then((response) => response.json())
                .then((data) => {
                    console.log(data)

                    if (data.error) {
                        setIsLoading(false);
                        navigate('/runs')
                    }

                }).catch((error) => {
                    console.error('Error:', error);
                })
        }


        // don't rerun fetch if there's data
        if (periodData.length > 0 || data) return

        const fetch_string = (!isSampleCase) ? `/api/full_inversion_fetch/?jobID=${runID}` : `/api/sample_case_fetch/`

        fetch(`${fetch_string}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
        }).then((response) => {
            if (!response.ok) {
                // Check for the 404 status code
                if (response.status === 404 || response.status === 500) {

                    setNotFound(true);

                }
                // You can handle other status codes here if needed
                throw new Error(`Error: ${response.status}`);
            }
            // If the response is ok, return the parsed JSON
            return response.json();
        })
            .then((data) => {

                // console.log(data)

                // KalmanMode case
                if (data.period_output) {
                    console.log("Hey, I'm running Kalman Mode case for sample!")
                    console.log(data.period_output)

                    setIsKalmanMode(true);

                    setPeriodData(data);

                    setPeriodCount(data.period_output.length);


                    setShapefileGeoJSON(!isSampleCase ? JSON.parse(data['shapefile_geo']) : data['shapefile_geo']);

                    if (!isSampleCase) {

                        setJobData(data['job_info']);
                        setConfigData(data['config_info']);
                    }

                    console.log("Period Images")
                    console.log(data.period_output[0].images)

                    // set first period data
                    setVizData(data.period_output[0].statistics[0]);

                    setPlots(data.period_output[0].images.map((img: any) => `data:image/png;base64,${img.base64_image}`));
                    setPlotNames(data.period_output[0].images.map((img: any) => img.file_name.split('/').pop()));

                    setPriorEmissionsTimeline(data.period_output.map((period: any) => parseFloat(period.statistics[0].PriorEmissions) || 0));
                    setPosteriorEmissionsTimeline(data.period_output.map((period: any) => parseFloat(period.statistics[0].PosteriorEmissions) || 0));
                    resetMapBounds();
                    stopTrackingTime()

                    // Delay the click action to ensure the ref is initialized
                    setTimeout(() => {
                        if (resetBoundsRef.current) {
                            console.log("Clicking reset bounds");
                            resetBoundsRef.current.click();
                        }
                    }, 0);
                    return

                } else {

                    let url_array: string[] = []
                    let name_array: string[] = []

                    for (const img of data['image_data_list']) {
                        const url = `data:image/png;base64,${img.base64_image}`;
                        url_array.push(url)
                        name_array.push(img.file_name)
                    }

                    setPlots(url_array);
                    setPlotNames(name_array);
                    setShapefileGeoJSON(JSON.parse(data['shapefile_geo']));
                    setVizData(data['viz_data'][0]);
                    setJobData(data['job_info']);
                    setConfigData(data['config_info']);

                    stopTrackingTime()

                    // Delay the click action to ensure the ref is initialized
                    setTimeout(() => {
                        if (resetBoundsRef.current) {
                            console.log("Clicking reset bounds");
                            resetBoundsRef.current.click();
                        }
                    }, 0);

                    return
                }

            }).catch((error) => {
                console.error('Error:', error);
            })
    }, [])

    const handleDownload = () => {
        const activeImage = document.querySelector('.swiper-slide-active') as HTMLImageElement;
        const activeImageName = activeImage.getAttribute('data-name') as string;
        const activeImageURL = activeImage.getAttribute('data-src') as string;
        // url:string, image_name:string
        const link = document.createElement('a');
        link.href = activeImageURL;

        // // Specify the file name
        link.download = activeImageName;

        link.click();
    };

    // // Update the position of the download button relative to the active slide
    // useEffect(() => {
    //     console.log("Active slide index", activeSlideIndex);
    //     const activeSlide = document.querySelector('.swiper-slide-active');
    //     if (activeSlide && downloadRef.current) {
    //         console.log("Appending download button to active slide");
    //         activeSlide.appendChild(downloadRef.current); // Move download button to active slide
    //     }
    // }, [activeSlideIndex]); // Run this effect every time the active slide changes

    // Helper function to add days to a date
    function addDays(date: Date, days: number): Date {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    // Function to calculate date chunks
    function calculateDateChunks(startDate: string, endDate: string, interval: number) {
        startDate = `${startDate.slice(0, 4)}-${Number(startDate.slice(4, 6)) - 1}-${startDate.slice(6, 8)}`;
        endDate = `${endDate.slice(0, 4)}-${Number(endDate.slice(4, 6)) - 1}-${endDate.slice(6, 8)}`;

        const chunks: { start: string, end: string }[] = [];
        let currentStartDate = new Date(startDate);
        const finalEndDate = new Date(endDate);

        while (currentStartDate < finalEndDate) {
            let currentEndDate = addDays(currentStartDate, interval - 1);

            // Ensure we don't go beyond the final end date
            if (currentEndDate > finalEndDate) {
                currentEndDate = finalEndDate;
            }

            chunks.push({
                start: currentStartDate.toISOString().split('T')[0],
                end: currentEndDate.toISOString().split('T')[0],
            });

            // Move to the next chunk
            currentStartDate = addDays(currentEndDate, 1);
        }

        return chunks;
    }

    const dateChonks = () => {
        if (configData) {
            const chonks = calculateDateChunks(configData.StartDate, configData.EndDate, 31)
            console.log("CHONKS", chonks)
        }
    }


    useEffect(() => {
        if (periodData && periodData.period_output) {
            // console.log("SELECTED PERIOD", selectedPeriod)
            setPlots(periodData.period_output[selectedPeriod - 1].images.map((img: any) => `data:image/png;base64,${img.base64_image}`))
            setPlotNames(periodData.period_output[selectedPeriod - 1].images.map((img: any) => img.file_name.split('/')[img.file_name.split('/').length - 1]))
            setVizData(periodData.period_output[selectedPeriod - 1].statistics[0])
        }
    }, [selectedPeriod])

    const handleImageClick = (url: string) => {
        setSelectedImage(url);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedImage(null);
    };

    // Fit Bounds of the map to the shapefile
    useEffect(() => {
        if (mapRef.current && shapefileGeoJSON) {
            const map = mapRef.current;
            const bounds = L.geoJSON(shapefileGeoJSON).getBounds();
            map.fitBounds(bounds);
        }
    }, [shapefileGeoJSON, mapRef]);

    // Reset map bounds to initial view
    const resetMapBounds = () => {
        if (mapRef.current && shapefileGeoJSON) {
            const map = mapRef.current;
            const bounds = L.geoJSON(shapefileGeoJSON).getBounds();
            map.fitBounds(bounds);
        }
    };

    // Function to download stats as a .txt file
    const downloadStats = () => {
        if (data) {
            // Format the stats as a .txt content
            const content = `
Prior Emissions: ${Number(data.PriorEmissions).toFixed(2)}
Posterior Emissions: ${Number(data.PosteriorEmissions).toFixed(2)}
DOFS: ${Number(data.DOFS).toFixed(2)}
Bias Prior: ${Number(data.BiasPrior).toFixed(2)}
Bias Posterior: ${Number(data.BiasPosterior).toFixed(2)}
RMSE Prior: ${Number(data.RMSEPrior).toFixed(2)}
RMSE Posterior: ${Number(data.RMSEPosterior).toFixed(2)}
      `;

            // Create a blob from the content and trigger download
            const blob = new Blob([content], { type: 'text/plain' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'output_statistics.txt';
            link.click();
        }

        if (isKalmanMode && vizData) {
            const content = `
Prior Emissions: ${Number(vizData.PriorEmissions).toFixed(2)}
Posterior Emissions: ${Number(vizData.PosteriorEmissions).toFixed(2)}
DOFS: ${Number(vizData.DOFS).toFixed(2)}
Bias Prior: ${Number(vizData.BiasPrior).toFixed(2)}
Bias Posterior: ${Number(vizData.BiasPosterior).toFixed(2)}
RMSE Prior: ${Number(vizData.RMSEPrior).toFixed(2)}
RMSE Posterior: ${Number(vizData.RMSEPosterior).toFixed(2)}
`
            const blob = new Blob([content], { type: 'text/plain' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'output_statistics.txt';
            link.click();
        }
    };

    const categories = [
        'BiomassBurn', 'Wastewater', 'OtherAnth', 'Reservoirs', 'Termites',
        'Coal', 'Seeps', 'Oil', 'Wetlands', 'Landfills', 'Livestock', 'Gas'
    ];

    useEffect(() => {
        if (vizData) {  // Ensure data is not null before accessing properties
            const priorEmissions = categories.map(category => parseFloat(vizData[`${category}Prior`]) || 0);  // Fallback to 0 if parsing fails
            const posteriorEmissions = categories.map(category => parseFloat(vizData[`${category}Posterior`]) || 0);

            setPriorEmissions(priorEmissions);
            setPosteriorEmissions(posteriorEmissions);
        }
    }, [vizData]);

    return (
        <div className="pageWrapper">
            {/* change this to jobData */}
            {!isLoading ?

                <>

                    {configData ? <h3 style={{ textAlign: "center", fontFamily: "AvenirLight", marginBottom: "5px" }}>&#123;&nbsp;{!isSampleCase ? configData.RunName : "Permian Basin"}&nbsp;&#125;</h3>
                        : <h3 style={{ textAlign: "center", fontFamily: "AvenirLight" }}> &#123;&nbsp;Full Inversion Dashboard&nbsp;&#125;</h3>}
                    <div className="centerHorizontally">
                        <div className='centerHorizontally' style={{ gap: "-10px" }}>
                            <p style={{ paddingTop: "5px" }}>{!isSampleCase ? formatDate(configData.StartDate) : "Sept. 1, 2023"}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="200" height="30" viewBox="0 0 242 30" style={{ transform: "scale(0.5)" }}>
                                <defs>
                                    <clipPath id="clip-Artboard_1">
                                        <rect width="242" height="30" />
                                    </clipPath>
                                </defs>
                                <g id="Artboard_1" data-name="Artboard – 1" clip-path="url(#clip-Artboard_1)">
                                    <g id="Group_78" data-name="Group 78" transform="translate(297 74) rotate(180)">
                                        <line id="Line_40" data-name="Line 40" x2="202.645" transform="translate(73.622 59.623)" fill="none" stroke="#fff" strokeWidth="3" />
                                        <circle id="Ellipse_37" data-name="Ellipse 37" cx="10.5" cy="10.5" r="10.5" transform="translate(63 49)" fill="var(--dark-yellow)" />
                                        <circle id="Ellipse_38" data-name="Ellipse 38" cx="10.5" cy="10.5" r="10.5" transform="translate(269 49)" fill="var(--dark-yellow)" />
                                    </g>
                                </g>
                            </svg>
                            <p style={{ paddingTop: "5px" }}>{!isSampleCase ? formatDate(configData.EndDate) : "Dec. 21, 2023"}</p>
                        </div>
                    </div>
                    <hr style={{ width: '30%', marginBottom: "1.5rem" }} />
                    <div className="centerHorizontally iconContainer" style={{ gap: "30px", alignItems: "center", marginBottom: "1.5rem" }}>
                        {!isSampleCase && <PopupSemantic content="View configuration file" trigger={<Icon className="tileShadow" name="tasks" circular inverted color="grey" />} position="top center" style={{ backgroundColor: 'grey', color: 'white' }} onClick={() => setIsConfigModalOpen(true)} />}
                        {/* WORK ON MAKING THIS A THING */}
                        {/* {!isSampleCase && <PopupSemantic content="Edit configuration file and request new run." trigger={<Icon className="tileShadow" name="pencil alternate" circular inverted color="grey" />} position="top center" style={{ backgroundColor: 'grey', color: 'white' }} />} */}
                        {/* <Icon className="tileShadow" name="download" circular inverted color="grey" onClick={() => resetBoundsRef.current?.click()} /> */}
                        {(configData.KalmanMode || isSampleCase) && <Dropdown className="darkDropdown tileShadow" placeholder="Select Period" fluid selection options={Array.from({ length: periodCount }, (_, index) => {
                            const periodNumber = index + 1;
                            const periodText = `Period ${periodNumber}`;
                            return {
                                key: periodText,
                                text: periodText,
                                value: periodNumber
                            };
                        })} style={{ width: "13%", zIndex: "2000 !important", position: "relative" }} defaultValue={1} onChange={(e, data: any) => setSelectedPeriod(data.value)} />
                        }
                        {/* {configData.KalmanMode && <Dropdown className="darkDropdown" placeholder="Select Period" fluid selection options={samplePeriods} style={{ width: "13%", zIndex: "2000 !important", position: "relative" }} />} */}
                    </div>

                    {/* <Button onClick={() => console.log("hello")}>Full fetch</Button> */}

                    <Grid>
                        <GridRow columns={2} style={{ height: "350px" }}>
                            {/* At a glance stats */}
                            <GridColumn width={5}>
                                <div className="glanceStatistics">
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <h2 style={{ margin: "5px auto 5px 6%", fontFamily: "AvenirLight" }}>At a glance</h2>
                                        <PopupSemantic className="darkPopup" content='Download statistics as txt file' trigger={<Icon name='download' style={{ transform: "scale(1.3)", cursor: "pointer" }} onClick={downloadStats} />} style={{ cursor: "pointer" }} />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

                                        {/* <div className="singleInfoBlock" style={{ backgroundColor: '#CB846E' }}>
                                            <p>Mean Bias</p>
                                            <p>-3.14</p>
                                        </div> */}
                                        <div style={{ display: "flex", justifyContent: "center", height: "auto", width: "100%" }}>
                                            <div className="doubleInfoBlock left" style={{ backgroundColor: "#CB846E" }}>
                                                <p>Prior Emiss. (Tg/yr)</p>
                                                <p>{vizData ? Number(vizData.PriorEmissions).toFixed(2) : <Loader active />}</p>
                                            </div>
                                            <div className="doubleInfoBlock right" style={{ backgroundColor: "#9d4f37" }}>
                                                <p>Posterior Emiss. (Tg/yr)</p>
                                                <p>{vizData ? Number(vizData.PosteriorEmissions).toFixed(2) : <Loader active />}</p>
                                            </div>
                                        </div>
                                        <div className="singleInfoBlock" style={{ backgroundColor: '#92A9B7' }}>
                                            <p style={{ fontSize: "0.9rem" }}>DOFS</p>
                                            <p style={{ fontSize: "0.9rem" }}>{vizData ? Number(vizData.DOFS).toFixed(2) : null}</p>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "center", height: "auto", width: "100%" }}>
                                            <div className="doubleInfoBlock left" style={{ backgroundColor: "#5E7F70" }}>
                                                <p>Bias Prior</p>
                                                <p>{vizData ? vizData.BiasPrior : <Loader active />}</p>
                                            </div>
                                            <div className="doubleInfoBlock right" style={{ backgroundColor: "#384E44" }}>
                                                <p>Bias Posterior</p>
                                                <p>{vizData ? vizData.BiasPosterior : <Loader active />}</p>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "center", height: "auto", width: "100%" }}>
                                            <div className="doubleInfoBlock left" style={{ backgroundColor: "#ab99b8" }}>
                                                <p>RMSE Prior</p>
                                                <p>{vizData ? vizData.RMSEPrior : <Loader active />}</p>
                                            </div>
                                            <div className="doubleInfoBlock right" style={{ backgroundColor: "#735E82" }}>
                                                <p>RMSE Posterior</p>
                                                <p>{vizData ? vizData.RMSEPosterior : <Loader active />}</p>
                                            </div>
                                        </div>
                                        <div className="singleInfoBlock" style={{ backgroundColor: '#d5a772' }}>
                                            <p style={{ fontSize: "0.9rem" }}># Super-observations</p>
                                            <p style={{ fontSize: "0.9rem" }}>{vizData ? vizData.NumSuperObservations : <Loader active />}</p>
                                        </div>
                                    </div>

                                </div>
                            </GridColumn>
                            {/* Map container */}
                            <GridColumn width={8} floated="right">
                                <div id="mapContainer" style={{ height: "100%", width: "100%", position: "relative" }}>
                                    <div ref={resetBoundsRef} onClick={resetMapBounds}>
                                        <Icon name="home" className="homeIcon" circular inverted color="grey" />
                                    </div>
                                    <MapContainer
                                        center={[41.8719, 12.5674]}
                                        zoom={6}
                                        scrollWheelZoom={true}
                                        style={{ height: "100%", width: "100%", borderRadius: "15px" }}
                                        ref={mapRef}
                                        className="tileShadow"
                                        whenReady={() => resetMapBounds()}
                                    >
                                        <TileLayer
                                            url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=e06c3b94-e393-4722-bd75-8257a760dcfb"
                                            attribution='&copy; <a href="https://www.stadiamaps.com/">Stadia Maps</a> contributors &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> contributors &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        />
                                        {shapefileGeoJSON && <GeoJSON data={shapefileGeoJSON} />}
                                    </MapContainer>
                                </div>
                            </GridColumn>
                        </GridRow>
                        <GridRow columns={1}>
                            <GridColumn>
                                <Swiper
                                    className="custom-swiper"
                                    ref={swiperRef}
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                    spaceBetween={50}
                                    slidesPerView={3}
                                    centeredSlides={true}
                                    navigation
                                    initialSlide={7}
                                    pagination={{ clickable: true }}
                                    scrollbar={{ draggable: true }}
                                    onSlideChange={(swiper) => setActiveSlideIndex(swiper.activeIndex)} // Track active slide
                                    onSlideChangeTransitionStart={() => setIsSliding(true)}
                                    onSlideChangeTransitionEnd={() => setIsSliding(false)}
                                    style={{ paddingBottom: '20px', paddingTop: '30px' }}
                                >
                                    {plots.length === 0 ?
                                        Array.from({ length: 4 }).map((_, index) => (
                                            <SwiperSlide key={index}>
                                                <Image
                                                    src={placeholderImage}
                                                    style={{ borderRadius: '5px' }}
                                                />
                                            </SwiperSlide>
                                        ))
                                        :
                                        <>
                                            {plots.map((url, index) => (
                                                <>

                                                    <SwiperSlide data-src={url} data-name={plotNames[index]} key={index}>
                                                        <Image src={url} data-name={plotNames[index]} id="swiper-image" alt={plotNames[index]} onClick={() => handleImageClick(url)} style={{
                                                            borderRadius: '15px',
                                                            marginBottom: '20px',
                                                            cursor: "pointer",
                                                            maxHeight: "300px", // Constrain max height
                                                            maxWidth: "100%",  // Ensure width adapts to parent
                                                            objectFit: "contain"  // Maintain aspect ratio
                                                        }} />
                                                    </SwiperSlide>
                                                </>
                                            ))}
                                        </>
                                    }
                                    {(isSliding || plots.length === 0) ? null : <PopupSemantic className="darkPopup" content="Download plot as .png" trigger={<Icon name="download" id="downloadButtonFull" circular inverted color="grey" onClick={handleDownload} />} />}
                                </Swiper>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "30px", marginTop: "30px" }}>
                        {vizData ? (
                            <>
                                <Plot

                                    style={{ backgroundColor: 'rgba(44, 44, 44, 0.5)', color: 'white', borderRadius: "10px", width: "700px", padding: "15px" }}
                                    data={[
                                        {
                                            y: categories,  // Sectoral categories as Y-axis (because it's horizontal)
                                            x: priorEmissions,  // Prior emissions on X-axis
                                            name: isKalmanMode ? 'KF Prior Emissions' : "Prior Emissions",
                                            type: 'bar',
                                            orientation: 'h',
                                            marker: {
                                                color: "rgb(124, 124, 124)",  // Fixed gradient color for each bar
                                            },
                                            hovertemplate: 'Category: %{y}<br>Prior Emissions: %{x:.2f} Tg/yr<extra></extra>',  // Custom hover tag

                                        },
                                        {
                                            y: categories,  // Sectoral categories as Y-axis
                                            x: posteriorEmissions,  // Posterior emissions on X-axis
                                            name: 'Posterior Emissions',
                                            type: 'bar',
                                            orientation: 'h',
                                            marker: {
                                                color: "rgb(206, 159, 30)",  // Fixed gradient color for each bar
                                            },
                                            hovertemplate: 'Category: %{y}<br>Posterior Emissions: %{x:.2f} Tg/yr<extra></extra>',  // Custom hover tag

                                        }
                                    ]}
                                    layout={{
                                        title: isKalmanMode ? `Sectoral Emissions (Period ${selectedPeriod})` : `Sectoral Emissions`,
                                        barmode: 'group',  // Bars will be grouped
                                        yaxis: { title: 'Categories' },  // Y-axis is now the categories
                                        xaxis: { title: 'Emissions (Tg/yr)' },  // X-axis is the emission values
                                        paper_bgcolor: 'rgba(0, 0, 0, 0)',  // Transparent background
                                        plot_bgcolor: 'rgba(0, 0, 0, 0)',  // Transparent plot area
                                        font: { color: 'white' },  // White text color
                                        width: 700,
                                        height: 500,
                                        margin: { t: 100, b: 100, l: 100, r: 150 },
                                        legend: {
                                            x: 0.05,  // Move to the left
                                            y: 1.2,  // Align to the top
                                            xanchor: 'center',  // Anchor to the left of the legend box
                                            yanchor: 'top',  // Anchor to the top of the legend box
                                            orientation: 'v'  // Horizontal legend
                                        }
                                    }}
                                />
                                {isKalmanMode && (

                                    <Plot
                                        style={{ backgroundColor: 'rgba(44, 44, 44, 0.5)', color: 'white', borderRadius: "10px", width: "700px", padding: "15px" }}
                                        data={[
                                            {
                                                x: priorEmissionsTimeline.map((_, index) => `Period ${index + 1}`),  // Periods on X-axis
                                                y: priorEmissionsTimeline,  // Prior emissions on Y-axis
                                                name: 'KF Prior Emissions',
                                                type: 'scatter',
                                                mode: 'lines+markers',  // Line plot with markers
                                                line: { color: "rgb(124, 124, 124)", shape: "spline", smoothing: 0.5 },  // Line color
                                                hovertemplate: '%{x}<br>Prior Emissions: %{y:.2f} Tg/yr<extra></extra>',  // Custom hover tag
                                            },
                                            {
                                                x: posteriorEmissionsTimeline.map((_, index) => `Period ${index + 1}`),  // Periods on X-axis
                                                y: posteriorEmissionsTimeline,  // Posterior emissions on Y-axis
                                                name: 'Posterior Emissions',
                                                type: 'scatter',
                                                mode: 'lines+markers',  // Line plot with markers
                                                line: { color: "rgb(206, 159, 30)", shape: "spline", smoothing: 0.5 },  // Line color
                                                hovertemplate: '%{x}<br>Posterior Emissions: %{y:.2f} Tg/yr<extra></extra>',  // Custom hover tag
                                            }
                                        ]}
                                        layout={{
                                            title: 'Emissions Timeline',
                                            xaxis: { title: 'Period #' },  // X-axis is Period number
                                            yaxis: { title: 'Emissions (Tg/yr)' },  // Y-axis is emissions values
                                            paper_bgcolor: 'rgba(0, 0, 0, 0)',  // Transparent background
                                            plot_bgcolor: 'rgba(0, 0, 0, 0)',  // Transparent plot area
                                            font: { color: 'white' },  // White text color
                                            width: 700,
                                            height: 500,
                                            margin: { t: 100, b: 100, l: 50, r: 50 },
                                            legend: {
                                                x: 0.1,  // Move to the left
                                                y: 1.20,  // Align to the top
                                                xanchor: 'center',  // Anchor to the left of the legend box
                                                yanchor: 'top',  // Anchor to the top of the legend box
                                                orientation: 'v'  // Horizontal legend
                                            }
                                        }}
                                    />
                                )}
                            </>
                        )
                            :
                            <h1>{loadingText}</h1>
                        }
                    </div>
                    {/* Image preview modal */}
                    <Modal open={isModalOpen} onClose={handleCloseModal} size='large'>
                        <Modal.Content>
                            <Image src={selectedImage} style={{ width: '100%', height: 'auto', borderRadius: '15px' }} />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={handleCloseModal}>Close</Button>
                        </Modal.Actions>
                    </Modal>

                    {/* Config review modal */}
                    <Modal open={isConfigModalOpen} onClose={() => setIsConfigModalOpen(false)} size="small">
                        <Modal.Header>Configuration Details</Modal.Header>
                        <Modal.Content scrolling>
                            {configData ? (
                                <div>
                                    {Object.entries(configData).map(([key, value]) => (
                                        <p key={key}>
                                            <strong>{key}:</strong>{" "}
                                            <span style={{ color: "var(--dark-yellow)" }}>
                                                {typeof value === "boolean" ? value.toString() : (value as string | number)}
                                            </span>
                                        </p>
                                    ))}
                                </div>
                            ) : (
                                <p>Loading...</p>
                            )}
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => setIsConfigModalOpen(false)}>Close</Button>
                        </Modal.Actions>
                    </Modal>

                    {/* <Button onClick={
                        () => fetch(`/api/full_inversion_fetch/?jobID=${runID}`, {

                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                            },
                        }).then((response) => response.json())
                            .then((data) => {
                                console.log(data)
                            })}>
                        Fetch Full Testing {runID}
                    </Button> */}

                </>
                :
                <>
                    {!notFound ? <Loader active size='huge'>{loadingText}</Loader> :

                        <div className="centerHorizontally">
                            <Message negative size="big" style={{ width: "40%", textAlign: "center", marginTop: "10%" }}><Icon name="warning sign" /> Hmm, job #{runID} not found...please contact <a href="/resources" style={{ color: "inherit", textDecoration: "underline" }}>user support.</a></Message>
                        </div>

                    }
                </>

            }
        </div>
    );
};

export default FullInversionDash;