import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useParams, useNavigate } from "react-router-dom";
import { Input, Message, Label, Modal, Button, Icon, Link } from "semantic-ui-react";
import "./ForgotPassword.css";
import { set } from "date-fns";

const ForgotPassword = () => {
  const { user } = useContext(AuthContext);
  const { userEmail } = useParams();

  const [otp, setOtp] = useState("");
  const [otpSuccess, setOtpSuccess] = useState(false);
  const [otpFailure, setOtpFailure] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const verifyOTP = () => {
    if (otp === sessionStorage.getItem("otp")) {
      setOtpSuccess(true);
      setOtpFailure(false);
    } else {
      setOtpFailure(true);
    }
  };

  const handlePasswordChange = async () => {
    setIsLoading(true);
    try {
      fetch(`/api/reset_password/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: userEmail,
          password: newPassword,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            setResetSuccess(false);
            setIsLoading(false);
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          if (data.hasOwnProperty("message")) {
            setResponse("success");
            setResetSuccess(true);
            sessionStorage.removeItem("otp");
          } else {
            setResponse("error");
            setResetSuccess(false);
          }
        })
        .catch((error) => {
          setResponse("error");
          console.error("Error updating password:", error);
        });
    } catch (error) {
      console.error("Error updating password:", error);
    }
    setIsLoading(false);
  };

  return (
    <div className="pageWrapper">
      <div className="forgotPasswordWrapper tileShadow">
        {!resetSuccess ? (
          <>
            <h1>Forgot Password</h1>
            <p style={{ color: "var(--dark-yellow)" }}>[{userEmail}]</p>
            <p style={{ marginBottom: "0.2rem" }}>Enter OTP:</p>
            <Input className="darkModeInput wideInput" placeholder="Enter one-time-password" onChange={(e) => setOtp(e.target.value)} />
            <Button color={otpSuccess ? "green" : "yellow"} onClick={verifyOTP} style={{ margin: "15px" }} disabled={otpSuccess}>
              Verify
            </Button>
            {otpFailure && (
              <Message negative>
                Invalid OTP. Try again or request a new OTP from{" "}
                <a href="/signin" style={{ color: "inherit", textDecoration: "underline" }}>
                  sign in page
                </a>
                .
              </Message>
            )}
            {otpSuccess && (
              <div className="centerVertically">
                <Input className="darkModeInput wideInput" placeholder="New Password" onChange={(e) => setNewPassword(e.target.value)} type="password" />
                <br />
                <Input className="darkModeInput wideInput" placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)} type="password" />
                <br />
                <Button color="yellow" onClick={handlePasswordChange} disabled={newPassword !== confirmPassword || isLoading} loading={isLoading}>
                  Reset Password
                </Button>
              </div>
            )}
          </>
        ) : (
          <>
            <h1>
              Password Reset Successful <Icon name="check" />
            </h1>
            <p>Your password has been successfully reset. You can now sign in with your new password.</p>
            <a href="/signin">
              <Button color="yellow">Sign In</Button>
            </a>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
